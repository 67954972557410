import clsx from "clsx"

interface AlertProps {
  error?: boolean
  warn?: boolean
  className?: string
  style?: React.CSSProperties
}

export const Alert: React.FC<AlertProps> = ({
  children,
  warn = false,
  error = true,
  className,
  style,
}) => {
  return (
    <div
      role="alert"
      style={style}
      className={clsx(
        className,
        "ba br1 dark-gray lh-copy",
        warn
          ? "b--gold bg-washed-yellow"
          : error
          ? "b--light-red bg-washed-red"
          : "b--dark-blue bg-washed-blue"
      )}
    >
      {children}
    </div>
  )
}

export const Info: React.FC<{ className?: string }> = ({
  children,
  className,
}) => {
  return (
    <Alert error={false} className={clsx(className, "pa3")}>
      {children}
    </Alert>
  )
}
