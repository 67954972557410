import React from "react"
import { ascending, unique } from "lib"
import { clamp, indexById, toggle } from "../../../utils"

interface ListGridState {
  selection: ListGridSelection
  keyboardPosition: ListGridPosition
  shouldFocusDomElement?: boolean
}

export const initialState: ListGridState = {
  selection: { ids: [] },
  keyboardPosition: { rowIndex: 0, colIndex: 0 },
}

export interface ListGridSelection {
  ids: string[]
  anchorId?: string
}

interface ListGridPosition {
  rowIndex: number
  colIndex: number
}

export interface ListGridData {
  id: string
}

export function getListGridReducer<T extends ListGridData>(
  items: T[],
  colCount: number
) {
  return function (state: ListGridState, action: Action): ListGridState {
    switch (action.type) {
      case "move":
        return {
          ...state,
          keyboardPosition: getNextKeyboardPosition(
            state.keyboardPosition,
            action.key,
            items.length,
            colCount
          ),
          shouldFocusDomElement: true,
        }
      case "select":
        return {
          ...state,
          selection: getNextSelection(
            state.selection,
            items,
            action.rowIndex,
            action.ctrlKey,
            action.shiftKey
          ),
        }
      case "set-selection":
        return { ...state, selection: { ids: action.ids } }
      case "did-focus-dom-element":
        return { ...state, shouldFocusDomElement: false }
      default:
        return state
    }
  }
}

function getNextKeyboardPosition(
  pos: ListGridPosition,
  key: ArrowKey,
  rowCount: number,
  colCount: number
): ListGridPosition {
  const { rowIndex, colIndex } = pos,
    clampRow = (index: number) => clamp(index, 0, rowCount - 1),
    clampCol = (index: number) => clamp(index, 0, colCount - 1)
  switch (key) {
    case "ArrowUp":
      return { colIndex, rowIndex: clampRow(rowIndex - 1) }
    case "ArrowDown":
      return { colIndex, rowIndex: clampRow(rowIndex + 1) }
    case "ArrowLeft":
      return { rowIndex, colIndex: clampCol(colIndex - 1) }
    case "ArrowRight":
      return { rowIndex, colIndex: clampCol(colIndex + 1) }
    default:
      return pos
  }
}

// ctrl: toggles id, always sets anchor
// shift: adds everything between anchor and rowIndex
// neither: sets selection to that item
function getNextSelection<T extends ListGridData>(
  selection: ListGridSelection,
  items: T[],
  rowIndex: number,
  ctrlKey = false,
  shiftKey = false
): ListGridSelection {
  const { ids, anchorId } = selection,
    rowId = items[rowIndex].id
  if (!ctrlKey && !shiftKey) {
    return { ids: [rowId], anchorId: rowId }
  } else if (shiftKey) {
    const anchorIndex = items.findIndex((item) => item.id == anchorId),
      range = [rowIndex, anchorIndex].sort(ascending),
      addIds = items.slice(range[0], range[1] + 1).map((item) => item.id)
    return { ids: unique(ids.concat(addIds)), anchorId }
  } else if (ctrlKey) {
    return { ids: toggle(ids, rowId), anchorId: rowId }
  } else {
    return selection
  }
}

export type ListGridDispatch = React.Dispatch<Action>

export type Action =
  | {
      type: "select"
      rowIndex: number
      ctrlKey?: boolean
      shiftKey?: boolean
    }
  | {
      type: "set-selection"
      ids: string[]
    }
  | {
      type: "move"
      key: ArrowKey
    }
  | {
      type: "did-focus-dom-element"
    }

export type ArrowKey = "ArrowUp" | "ArrowDown" | "ArrowLeft" | "ArrowRight"

export function useSelectionValidation<T extends ListGridData>(
  items: T[],
  selection: ListGridSelection,
  dispatch: ListGridDispatch
) {
  React.useEffect(() => {
    const itemsById = indexById(items),
      { ids, anchorId } = selection

    let invalid = false

    const validSelection = ids.filter((id) => itemsById.has(id))
    if (validSelection.length !== ids.length) invalid = true
    if (anchorId && !itemsById.has(anchorId)) invalid = true
    if (invalid) {
      dispatch({ type: "set-selection", ids: validSelection })
    }
  }, [items, selection, dispatch])
}
