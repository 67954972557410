import { TabDataType, TabWidget } from "../utils"
import { P, TabLink } from "./base"

export const SidebarAnalysis: React.FC = () => {
  return (
    <div className="flex-grow-1 overflow-auto ph3">
      <AnalysisTool
        name="Explore Coded Text"
        id={TabWidget.ExploreCodedText}
        description="Find coded text across different codes and documents."
      />
      <AnalysisTool
        name="Relationship Matrix"
        id={TabWidget.RelationshipMatrix}
        description="Visualize connections between codes, documents, and tags."
      />
    </div>
  )
}

interface ToolProps {
  name: string
  id: string
  description: string
}

const AnalysisTool: React.FC<ToolProps> = ({ name, id, description }) => {
  return (
    <div className="mt3 mb4">
      <TabLink id={id} dataType={TabDataType.Analysis} className="db f5 mb2">
        {name}
      </TabLink>
      <P className="f6" style={{ marginTop: 0 }}>
        {description}
      </P>
    </div>
  )
}
