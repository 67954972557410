import { useQuery } from "@apollo/client"
import {
  PageTitle,
  Spinner,
  ContentPageContainer,
  FullPageDataError,
} from "./base"
import { Metadata } from "./Metadata"
import { GetCode, GetCodeVariables, GET_CODE, SidebarTab } from "../utils"
import { CodeMenu } from "./CodeMenu"
import { CodeTabs } from "./CodeTabs"
import { TagFilterButtons } from "./TagPills"

export const Code: React.FC<{ id: string }> = ({ id }) => {
  const { data, error } = useQuery<GetCode, GetCodeVariables>(GET_CODE, {
    variables: { id },
  })

  if (error) return <FullPageDataError />
  if (!data) return <Spinner />

  const code = data.getCode

  return (
    <>
      <PageTitle>{code.name}</PageTitle>
      <ContentPageContainer>
        <Metadata
          name={code.name}
          description={data.getCode.description}
          updatedAt={data.getCode.updatedAt}
          menuComponent={
            <CodeMenu id={id} name={data.getCode.name} canOpen={false} />
          }
          tagsComponent={
            <TagFilterButtons tags={data.getCode.tags} tab={SidebarTab.Code} />
          }
          className="ph2 ph0-ns"
        />
        <CodeTabs codeId={id} />
      </ContentPageContainer>
    </>
  )
}
