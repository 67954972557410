import React from "react"
import {
  bold,
  em,
  heading1,
  heading2,
  heading3,
  wrapInBulletList,
  wrapInOrderedList,
  liftListItem,
  sinkListItem,
} from "editor"
import * as Icons from "../base/Icons"
import { ProseMirrorButton } from "./utils"

export const EditingControls: React.FC = () => {
  return (
    <>
      <ProseMirrorButton
        execute={bold.toggle}
        isActive={bold.active}
        icon={<Icons.Bold />}
        label="Bold (Ctrl+B)"
      />
      <ProseMirrorButton
        execute={em.toggle}
        isActive={em.active}
        icon={<Icons.Italic />}
        label="Italic (Ctrl+I)"
        className="mr3"
      />

      <ProseMirrorButton
        execute={heading1.toggle}
        isActive={heading1.active}
        icon={<Icons.Heading1 />}
        label="Heading 1"
      />
      <ProseMirrorButton
        execute={heading2.toggle}
        isActive={heading2.active}
        icon={<Icons.Heading2 />}
        label="Heading 2"
      />
      <ProseMirrorButton
        execute={heading3.toggle}
        isActive={heading3.active}
        icon={<Icons.Heading3 />}
        label="Heading 3"
        className="mr3"
      />

      <ProseMirrorButton
        execute={wrapInBulletList}
        icon={<Icons.Ul />}
        label="Bulleted list"
      />
      <ProseMirrorButton
        execute={wrapInOrderedList}
        icon={<Icons.Ol />}
        label="Numbered list"
      />
      <ProseMirrorButton
        execute={liftListItem}
        icon={<Icons.IndentLeft />}
        label="Indent list item left"
      />
      <ProseMirrorButton
        execute={sinkListItem}
        icon={<Icons.IndentRight />}
        label="Indent list item right"
        className="mr3"
      />
    </>
  )
}
