import React from "react"
import clsx from "clsx"
import { SidebarNavButtons, SidebarPanel } from "./Sidebar"
import { panels } from "./SidebarPanels"
import { useDocs, useProjectState } from "../../utils"
import { Resizable } from "re-resizable"
import { Tabs } from "./Tabs"
import { TabRenderer } from "./TabRenderer"
import { Moon, Spinner, Sun, Sunrise } from "../base"

export const ProjectContainer: React.FC = () => {
  const {
      sidebar: { tab: tab, open: open },
      tabs,
      activeTabIndex,
    } = useProjectState(),
    activeTab = tabs[activeTabIndex],
    panel = panels.find((panel) => panel.tab === tab),
    storedWidth = parseFloat(localStorage.getItem("sb-width") || ""),
    [width, setWidth] = React.useState(storedWidth || 300),
    { loading } = useDocs()

  React.useEffect(() => {
    localStorage.setItem("sb-width", width.toString())
  }, [width])

  if (loading) {
    return (
      <div className="bg-moon-gray vh-100 w-100 pa5">
        <Spinner />
      </div>
    )
  }
  return (
    <div
      // entire project container
      className="vh-100 w-100 flex items-stretch relative"
    >
      <div
        // sidebar container
        className={clsx(
          "flex items-stretch br b--black-20 shadow-1 flex-shrink-0 z-2"
        )}
      >
        <div
          // vertical sidebar nav buttons
          className="flex flex-column flex-shrink-0"
        >
          <SidebarNavButtons />
        </div>
        {panel && (
          <Resizable
            // sidebar panel content
            size={{ width, height: "100%" }}
            style={open ? {} : { display: "none" }}
            onResizeStop={(_, __, ___, size) => {
              const newWidth = width + size.width
              setWidth(newWidth)
            }}
            maxWidth={400}
            minWidth={250}
            className={clsx(
              "flex flex-column bl b--black-20 flex-grow-1 overflow-hidden",
              !open && "dn"
            )}
          >
            <SidebarPanel {...panel} />
          </Resizable>
        )}
      </div>

      <div className="flex-grow-1 flex flex-column overflow-hidden">
        <Tabs />
        {activeTab ? <TabRenderer {...activeTab} /> : <Placeholder />}
      </div>
    </div>
  )
}

const periods = ["Morning", "Afternoon", "Evening"]
const icons = [Sunrise, Sun, Moon]

// TODO add tutorial
const Placeholder = () => {
  const hours = new Date().getHours(),
    index = hours < 12 ? 0 : hours < 17 ? 1 : 2,
    Icon = icons[index]

  return (
    <div className="mid-gray flex-grow-1 pa4 vh-50 flex items-center justify-center bg-moon-gray">
      <h1 className="f1 fw2 mt5 flex items-center">
        <Icon className="mr2" />
        Good {periods[index]}!
      </h1>
    </div>
  )
}
