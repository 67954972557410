import React from "react"
import * as yup from "yup"
import { useExec, cl, inviteCollaborator } from "../../utils"
import { DialogForm, Field } from "../base"

export const ProjectShare: React.FC<{ id: string }> = ({ id: projectId }) => {
  const exec = useExec()
  return (
    <DialogForm
      label="Invite Collaborator"
      values={{ email: "" }}
      schema={yup.object({ email: yup.string().email().required() })}
      submitText="Invite"
      onSubmit={async ({ email }) =>
        exec(inviteCollaborator, { projectId, email })
      }
      classNames={{ button: cl.btn }}
    >
      <Field
        name="email"
        label="Collaborator Email"
        hideErrors
        placeholder="friend@email.com"
      />
    </DialogForm>
  )
}
