import { gql, useQuery } from "@apollo/client"
import React from "react"
import {
  cl,
  formatSecondsAsTimeStamp,
  GetTranscriptionRate,
  useDialog,
  useMediaUploader,
} from "../utils"
import { Button } from "./base"

const GET_RATE = gql`
  query GetTranscriptionRate {
    getTranscriptionRate
  }
`

interface UploadMediaDialogProps {
  file: File
  getUploadSpec: (file: File) => Promise<{ id: string; url: string }>
}

export const TranscriptionStarter: React.FC<UploadMediaDialogProps> = ({
  file,
  getUploadSpec,
}) => {
  const { data: rateData } = useQuery<GetTranscriptionRate>(GET_RATE),
    url = React.useMemo(() => URL.createObjectURL(file), [file]),
    [duration, setDuration] = React.useState<number>(0),
    minutes = duration / 60,
    cost = rateData && minutes ? rateData.getTranscriptionRate * minutes : 0,
    { startUpload } = useMediaUploader(),
    { hide } = useDialog(),
    [loading, setLoading] = React.useState(false)

  React.useEffect(() => () => URL.revokeObjectURL(url), [url])

  return (
    <>
      <audio
        src={url}
        onLoadedMetadata={(e) => {
          setDuration(e.currentTarget.duration)
        }}
      />
      <div className="flex flex-column flex-row-ns justify-between-ns">
        <div className="flex flex-column">
          <div className="mb2">
            Length: <b>{formatSecondsAsTimeStamp(duration)}</b>
          </div>
          <div>
            Estimated transcription cost: <b>{formatMoney(cost)}</b>
          </div>
        </div>
        <div className="mt3 self-end">
          <Button
            className={cl.btn}
            disabled={!duration} // comment out if you want to test bad files
            label="Start Transcription"
            loading={loading}
            onClick={async () => {
              try {
                setLoading(true)
                const { id, url } = await getUploadSpec(file)
                startUpload({ id, url, file })
                hide()
              } catch (err) {
                setLoading(false)
              }
            }}
          />
        </div>
      </div>
    </>
  )
}

function formatMoney(dollars: number) {
  const cents = Math.round(dollars * 100),
    wholeDollars = Math.floor(cents / 100),
    remainder = cents - wholeDollars * 100

  return `$${wholeDollars}.${remainder.toString().padStart(2, "0")} USD`
}
