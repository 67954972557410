import clsx from "clsx"
import { P, Paragraphs } from "./base"
import { relativeDate, cl } from "../utils"

export interface MetadataProps {
  name: string
  description: string
  updatedAt: string
  menuComponent?: React.ReactElement
  tagsComponent?: React.ReactElement
  className?: string
}

export const Metadata: React.FC<MetadataProps> = ({
  name,
  description,
  updatedAt,
  menuComponent = null,
  tagsComponent = null,
  className,
}) => {
  return (
    <div className={className}>
      <div className="flex items-baseline justify-between">
        <h1 className={clsx(cl.h1.replace("mb3", "mb0"), "flex-grow-1")}>
          {name}
        </h1>
        <div className="flex-shrink-0">{menuComponent}</div>
      </div>

      {description ? <Paragraphs text={description} /> : <P>No description.</P>}

      {tagsComponent}

      <div className={clsx(cl.help, "mv3")}>
        Last changed {relativeDate(updatedAt)}
      </div>
    </div>
  )
}
