import React from "react"
import { randomId } from "../../utils"

interface RovingTabIndexContextValue {
  dataRoveContextId: string
  activeChildId: string | null
  setActiveChildId: (idOrNull: string | null) => void
  nextChild: () => void
  previousChild: () => void
}

const DATA_ROVE_ID = "data-roveid"
const DATA_ROVE_CTX = "data-rovecontext"

export const RovingTabIndexContext =
  React.createContext<RovingTabIndexContextValue>(
    //@ts-expect-error null ctx
    null
  )

export const RovingTabIndexProvider: React.FC = ({ children }) => {
  const [dataRoveContextId] = React.useState(randomId)

  const [activeChildId, setActiveChildId] = React.useState<string | null>(null)

  const getItems = React.useCallback(() => {
    const selector = `[${DATA_ROVE_CTX}="${dataRoveContextId}"][${DATA_ROVE_ID}]`,
      elements = [...document.querySelectorAll(selector)] as HTMLElement[]

    return {
      elements,
      ids: elements.map((item) => item.dataset.roveid as string),
    }
  }, [dataRoveContextId])

  const nextChild = React.useCallback(() => {
    if (!activeChildId) return
    const { ids, elements } = getItems(),
      index = ids.indexOf(activeChildId)

    if (index < elements.length - 1) {
      setActiveChildId(ids[index + 1])
      elements[index + 1].focus()
    }
  }, [getItems, activeChildId])

  const previousChild = React.useCallback(() => {
    if (!activeChildId) return
    const { ids, elements } = getItems(),
      index = ids.indexOf(activeChildId)

    if (index > 0) {
      setActiveChildId(ids[index - 1])
      elements[index - 1].focus()
    }
  }, [getItems, activeChildId])

  const value = React.useMemo(
    () => ({
      dataRoveContextId,
      activeChildId,
      setActiveChildId,
      nextChild,
      previousChild,
    }),
    [dataRoveContextId, activeChildId, nextChild, previousChild]
  )

  React.useEffect(() => {
    const { ids } = getItems()
    if (!ids.length) return
    if (!activeChildId || ids.indexOf(activeChildId) < 0) {
      setActiveChildId(ids[0])
    }
  }, [getItems, activeChildId, children])

  return (
    <RovingTabIndexContext.Provider value={value}>
      {children}
    </RovingTabIndexContext.Provider>
  )
}

export function useRovingTabIndexChildProps() {
  const {
      dataRoveContextId,
      setActiveChildId,
      activeChildId,
      nextChild,
      previousChild,
    } = React.useContext(RovingTabIndexContext),
    [dataRoveId] = React.useState(randomId),
    props = React.useMemo(
      () => ({
        tabIndex: dataRoveId === activeChildId ? 0 : -1,
        [DATA_ROVE_ID]: dataRoveId,
        [DATA_ROVE_CTX]: dataRoveContextId,
        onClick() {
          setActiveChildId(dataRoveId)
        },
        onKeyDown(e: React.KeyboardEvent) {
          if (e.key === "ArrowRight") {
            e.stopPropagation()
            e.preventDefault()
            nextChild()
          }
          if (e.key === "ArrowLeft") {
            e.stopPropagation()
            e.preventDefault()
            previousChild()
          }
        },
      }),
      [
        dataRoveId,
        dataRoveContextId,
        activeChildId,
        setActiveChildId,
        nextChild,
        previousChild,
      ]
    )

  return props
}
