import React from "react"

export interface MediaContextValue {
  playMedia: (seconds?: number) => void
  mediaAvailable: boolean
}

//@ts-expect-error null ctx
export const MediaContext = React.createContext<MediaContextValue>(null)

export function useMediaContext() {
  return React.useContext(MediaContext)
}
