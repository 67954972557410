import React from "react"
import { TabDataType, useDialog, useOpenTab } from "../utils"
import { action, DIVIDER } from "./base"
import { useCodePalette } from "./CodePalette"
import { StripeClickHandlerProps } from "./CodeStripes"
import { CodeUpdate } from "./dialogs"
import { EditorState } from "./Editor"

function useStripeMenuItems(docId: string, includeGoToDoc?: boolean) {
  const { show } = useDialog(),
    { onRemoveCoding } = useCodePalette(),
    openTab = useOpenTab()
  return React.useCallback(
    ({ from, to, codeId }: StripeClickHandlerProps) => [
      action("Unassign Code", () => onRemoveCoding(codeId)),
      includeGoToDoc && DIVIDER,
      includeGoToDoc &&
        action("Go to Document", () => {
          openTab<EditorState>(
            docId,
            TabDataType.Doc,
            {
              scrollToRange: { from, to },
            },
            -1 // explicitly tell TabRenderer to not scroll
          )
        }),
      DIVIDER,
      action("Edit Code Details", () => show(CodeUpdate, { id: codeId })),
      action("Open Code in Tab", () => openTab(codeId, TabDataType.Code)),
    ],
    [onRemoveCoding, show, openTab, docId, includeGoToDoc]
  )
}

export function useDocStripeMenuItems(docId: string) {
  return useStripeMenuItems(docId, false)
}

export function useReportStripeMenuItems(docId: string) {
  return useStripeMenuItems(docId, true)
}
