import React from "react"
import { ActionMenuItem } from "../../components/base"

export interface ContextMenuState {
  x: number
  y: number
  items: ActionMenuItem[]
}

type ContextMenuContextValue = (state: ContextMenuState) => void

export const ContextMenuContext =
  // @ts-expect-error null ctx
  React.createContext<ContextMenuContextValue>(null)

export function useContextMenu() {
  const setContextMenuItems = React.useContext(ContextMenuContext)
  return React.useCallback(
    (e: React.MouseEvent, items: ActionMenuItem[]) => {
      e.preventDefault()
      e.stopPropagation()
      setContextMenuItems({ x: e.clientX, y: e.clientY, items })
    },
    [setContextMenuItems]
  )
}
