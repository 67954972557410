import React from "react"

export interface MediaUploadContextValue {
  startUpload: (props: UploadSpec) => void
  cancelUpload: (id: string) => void
  uploads: Upload[]
}

export interface UploadSpec {
  id: string
  file: File
  url: string
}

export interface Upload extends UploadSpec {
  progress: number
  error?: string
}

export const MediaUploadContext =
  //@ts-expect-error null ctx
  React.createContext<MediaUploadContextValue>(null)

export function useMediaUploader() {
  return React.useContext(MediaUploadContext)
}

export const CANCEL_UPLOAD_EVENT = "cancelupload"
