import React from "react"
import { Resizable } from "re-resizable"
import clsx from "clsx"
import { Maximize } from "../base"

interface Size {
  height: number
  width: number
}

const isMobile = window.innerWidth < 500,
  idealMinWidth = isMobile ? window.innerWidth : 375,
  idealMaxWidth = 550,
  idealMaxHeight = isMobile
    ? window.innerHeight * 0.4
    : window.innerHeight * 0.7,
  minWidth = Math.min(idealMinWidth, window.innerWidth),
  maxWidth = Math.min(idealMaxWidth, window.innerWidth),
  minHeight = 42,
  maxHeight = Math.min(idealMaxHeight, window.innerHeight),
  spaceForText = 600,
  LABEL = "Assign Codes to Text",
  BTN_CLASS = "bg-transparent white pa1 bn flex items-center pointer hover-gray"

export const CodePaletteWindow = React.memo<
  React.PropsWithChildren<{ className?: string; style?: React.CSSProperties }>
>(({ children, className, style }) => {
  const [size, setSize] = React.useState(getSavedOrDefaultSize()),
    saveSize = (size: Size) => {
      setSize(size)
      persistSize(size)
    }
  return (
    <div
      className={clsx("fixed bottom-0 right-0 right-1-ns z-2", className)}
      style={style}
    >
      <Resizable
        className="br2 overflow-hidden br--top bg-near-white ba b--black-20 flex flex-column shadow-1"
        aria-label={LABEL}
        minHeight={minHeight}
        maxHeight={maxHeight}
        minWidth={minWidth}
        maxWidth={maxWidth}
        onResizeStop={(_, __, ___, d) => {
          const width = size.width + d.width,
            height = size.height + d.height
          saveSize({ width, height })
        }}
        size={size}
      >
        <div className="bg-near-black white pv2 ph3 f5 fw6 relative">
          <div className="absolute top-0 left-0 ma1 b--silver bw1 bl bt pa1"></div>
          <div className="flex justify-between">
            <div className="flex items-center">{LABEL}</div>
            <div className="flex items-center">
              <button
                aria-label="Minimize"
                onClick={() => saveSize({ width: minWidth, height: minHeight })}
                className={clsx(BTN_CLASS, "mr2")}
              >
                —
              </button>
              <button
                aria-label="Maximize"
                onClick={() => saveSize({ width: maxWidth, height: maxHeight })}
                className={BTN_CLASS}
              >
                <Maximize />
              </button>
            </div>
          </div>
        </div>
        <div className="flex-grow-1 overflow-hidden flex flex-column">
          {children}
        </div>
      </Resizable>
    </div>
  )
})

function persistSize({ height, width }: Size) {
  localStorage.setItem("cw-height", height.toString())
  localStorage.setItem("cw-width", width.toString())
}

function getSavedOrDefaultSize(): Size {
  const defaultSize = getDefaultSize()

  return {
    height:
      parseInt(localStorage.getItem("cw-height") || "") || defaultSize.height,
    width:
      parseInt(localStorage.getItem("cw-width") || "") || defaultSize.width,
  }
}

function getDefaultSize(): Size {
  const freeHorizontalSpace = window.innerWidth - spaceForText,
    idealVerticalFreeSpace = freeHorizontalSpace <= minWidth * 2 ? 400 : 200
  return {
    width: within(freeHorizontalSpace / 2, [minWidth, maxWidth]),
    height: within(window.innerHeight - idealVerticalFreeSpace, [
      minHeight,
      maxHeight,
    ]),
  }
}

function within(value: number, range: [number, number]) {
  return Math.max(Math.min(value, range[1]), range[0])
}
