import React from "react"
import { ApolloProvider as RealApolloProvider } from "@apollo/client"
import { getApolloClient, resetApolloClient } from "../utils"

export const ApolloProvider: React.FC = ({ children }) => {
  const [client] = React.useState(getApolloClient)

  React.useEffect(() => resetApolloClient, [])

  return <RealApolloProvider client={client}>{children}</RealApolloProvider>
}
