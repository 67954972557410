import { AccountUpdate, AccountDelete } from "../../components/dialogs"
import { action, ActionMenuItem, DIVIDER } from "../../components/base"
import { useDialog } from "./dialog-context"
import { signOut, useExec } from "../commands"

export function useAccountMenu(): ActionMenuItem[] {
  const { show } = useDialog(),
    exec = useExec()

  return [
    action("Sign Out", () => exec(signOut, undefined)),
    action("Update Profile", () => show(AccountUpdate, {})),
    DIVIDER,
    action("Delete Account", () => show(AccountDelete, {})),
  ]
}
