import { gql, useQuery } from "@apollo/client"
import {
  ListInvites,
  InviteForUser,
  fragments,
  cl,
  acceptInvite,
  rejectInvite,
} from "../utils"
import clsx from "clsx"
import { CommandButton, Alert, Footer } from "./base"

const GET_INVITES = gql`
  query ListInvites {
    listInvites {
      ...InviteForUser
    }
  }

  ${fragments.inviteForUser}
`

export const UserInvites = () => {
  const { data } = useQuery<ListInvites>(GET_INVITES, {
    fetchPolicy: "cache-and-network",
  })

  if (data) {
    return (
      <>
        {data.listInvites.map((invite) => (
          <UserInvite key={invite.id} {...invite} />
        ))}
      </>
    )
  } else {
    return null
  }
}

const UserInvite: React.FC<InviteForUser> = ({ id, project }) => (
  <Alert warn className="pa3 mv4 mw7-ns br1 shadow-1">
    <div>
      <div className="lh-copy mb3 mr3">
        <b>{project.owner.name}</b>&nbsp;({project.owner.email})&nbsp;has
        invited you to join their project&nbsp;
        <b>{project.name}</b>.
      </div>
      <Footer>
        <CommandButton
          label="Ignore"
          className={clsx(cl.btnLight, "mr2")}
          command={rejectInvite}
          args={{ id }}
        />
        <CommandButton
          label="Accept"
          className={cl.btn}
          command={acceptInvite}
          args={{ id }}
        />
      </Footer>
    </div>
  </Alert>
)
