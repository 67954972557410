import { gql } from "@apollo/client"

export const DOC_CHANGES = gql`
  subscription DocChanges($docId: ID!) {
    subscribeToDocChanges(id: $docId) {
      id
      version
    }
  }
`

export const GET_TEXT = gql`
  query GetText($docId: ID!) {
    connectToDoc(docId: $docId) {
      id
      version
      text
    }
  }
`

export const SYNC_DOC = gql`
  mutation SyncDoc($docId: ID!, $version: Int!, $steps: [JSONObject!]) {
    syncDoc(docId: $docId, version: $version, steps: $steps) {
      steps
      clientIds
      status
    }
  }
`
