import { TranscriptError } from "./TranscriptError"
import { TranscriptPreview } from "./TranscriptPreview"
import { TranscriptLoading } from "./TranscriptLoading"
import { gql, useQuery } from "@apollo/client"
import { GetFullTranscript, GetFullTranscriptVariables } from "../../utils"
import { DataLoadingError, Spinner } from "../base"
import { MediaProvider } from "../MediaPlayer"

interface TranscriptViewerProps {
  docId: string
  name: string
  error?: string | null
  length?: number | null
}

export const TranscriptViewer: React.FC<TranscriptViewerProps> = ({
  docId,
  length,
  error,
  name,
}) => {
  return (
    <div className="ph2 ph0-ns">
      {length ? (
        <TranscriptLoader docId={docId} />
      ) : error ? (
        <TranscriptError docId={docId} error={error} />
      ) : (
        <TranscriptLoading docId={docId} name={name} />
      )}
    </div>
  )
}

const GET_FULL_TRANSCRIPT = gql`
  query GetFullTranscript($id: ID!) {
    getDoc(id: $id) {
      id
      transcript {
        id
        length
        error
        data
      }
      media {
        id
        available
      }
    }
  }
`

const TranscriptLoader: React.FC<{ docId: string }> = ({ docId }) => {
  const { data, error } = useQuery<
    GetFullTranscript,
    GetFullTranscriptVariables
  >(GET_FULL_TRANSCRIPT, { variables: { id: docId } })

  if (data && data.getDoc.transcript) {
    return (
      <MediaProvider id={docId} available={data.getDoc.media?.available}>
        <TranscriptPreview docId={docId} data={data.getDoc.transcript.data} />
      </MediaProvider>
    )
  } else if (error) {
    return <DataLoadingError />
  } else {
    return <Spinner />
  }
}
