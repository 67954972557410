import React from "react"
import { useExec, createDocFromAudio } from "../../utils"
import { TranscriptionStarter } from "../TranscriptionStarter"

export const PreviewMedia: React.FC<{ file: File }> = ({ file }) => {
  const exec = useExec()

  return (
    <TranscriptionStarter
      file={file}
      getUploadSpec={async (file) => {
        const data = await exec(createDocFromAudio, {
          filename: file.name,
        })

        if (!data) throw new Error("could not import audio")

        return {
          id: data.createDocFromAudio.doc.id,
          url: data.createDocFromAudio.uploadUrl,
        }
      }}
    />
  )
}
