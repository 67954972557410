import { ClientRoute } from "lib"
import { cl, useExec, requestPasswordReset } from "../utils"
import {
  CenteredPage,
  Field,
  Form,
  H1PageTitle,
  P,
  yup,
  RouterLink,
} from "./base"

export const ForgotPassword: React.FC = () => {
  const exec = useExec()
  return (
    <CenteredPage>
      <H1PageTitle>Forgot your password?</H1PageTitle>
      <P>We'll send you an email you can use to reset it.</P>
      <Form
        values={{ email: "" }}
        schema={yup.object({
          email: yup.string().email().required("missing email"),
        })}
        submitText="Send Email"
        classNames={{ button: cl.btn }}
        onSubmit={async (values) => exec(requestPasswordReset, values)}
        resetBlank
      >
        <Field name="email" label="Email Address" type="email" />
      </Form>
      <div className="tc mv3">
        <RouterLink to={ClientRoute.SignIn} className="f5">
          Back to Sign-in
        </RouterLink>
      </div>
    </CenteredPage>
  )
}
