import React from "react"
import {
  ChartContainer,
  ChartDataLabel,
  DataLoadingError,
  Info,
  Spinner,
} from "./base"
import {
  DEFAULT_CODE_COLOR,
  useDocs,
  useCodes,
  useOpenTab,
  TabDataType,
} from "../utils"
import { descending } from "lib"
import { useEdges } from "./EdgeProvider"
import { NetworkStatus } from "@apollo/client"
import { CodeTabState } from "./CodeTabs"

export const CodeOverlapChart: React.FC<{
  codeId: string
}> = ({ codeId: focusCodeId }) => {
  const { overlapEdges, refetch, ...edgeInfo } = useEdges(),
    projectInfo = useDocs(),
    { codes } = useCodes(),
    { sizes, overlap } = React.useMemo(() => {
      const sizes: Map<string, number> = new Map(),
        overlap: Map<string, number> = new Map()
      overlapEdges.forEach((edge) => {
        edge.codeIds.forEach((codeId) => {
          sizes.set(codeId, (sizes.get(codeId) || 0) + edge.size)
          if (codeId !== focusCodeId && edge.codeIds.includes(focusCodeId)) {
            overlap.set(codeId, (overlap.get(codeId) || 0) + edge.size)
          }
        })
      })
      return { sizes, overlap }
    }, [overlapEdges, focusCodeId])

  // ensure fresh data
  React.useEffect(() => {
    refetch()
  }, [refetch])

  const barData = Array.from(overlap.entries())
      .map(([codeId, overlapSize]) => {
        const code = codes.find((code) => code.id === codeId)
        return {
          code: {
            id: codeId,
            name: code?.name || "Unknown Code",
            color: code?.color || DEFAULT_CODE_COLOR,
            size: sizes.get(codeId) || 0,
          },
          overlapSize,
        }
      })
      .sort((a, b) => descending(a.overlapSize, b.overlapSize)),
    extentRight = Math.max(
      0,
      ...barData.map((d) => d.code.size - d.overlapSize)
    ),
    focusCode = codes.find((code) => code.id === focusCodeId),
    focusCodeSize = sizes.get(focusCodeId) || 0,
    refetching = edgeInfo.networkStatus === NetworkStatus.refetch

  if (!focusCode) {
    return null
  } else if ((projectInfo.loading || edgeInfo.loading) && !refetching) {
    return <Spinner />
  } else if (projectInfo.error || edgeInfo.error) {
    return <DataLoadingError />
  }

  return (
    <>
      {barData.length ? (
        <ChartContainer className="ph4">
          <FocusChart
            code={{ ...focusCode, size: focusCodeSize }}
            extentRight={extentRight}
          />
          {barData.map((props) => (
            <OverlapChart
              key={props.code.id}
              {...props}
              focusCodeName={focusCode.name}
              extentRight={extentRight}
              focusCodeSize={focusCodeSize}
            />
          ))}
        </ChartContainer>
      ) : (
        <Info className="mv4">
          <b>{focusCode.name}</b> doesn't overlap with any other codes
        </Info>
      )}
    </>
  )
}

interface ChartCode {
  id: string
  name: string
  color: string
  size: number
}

interface ChartProps {
  code: ChartCode
  overlapSize: number
  focusCodeName: string
  focusCodeSize: number
  extentRight: number
}

const OverlapChart: React.FC<ChartProps> = ({
  code,
  overlapSize,
  focusCodeName,
  focusCodeSize,
  extentRight,
}) => {
  const totalSize = focusCodeSize + extentRight,
    barLeft = (focusCodeSize - overlapSize) / totalSize,
    openTab = useOpenTab(),
    onClick = () =>
      openTab<CodeTabState>(code.id, TabDataType.Code, { tab: 2 }),
    displayPercent = `${Math.round((overlapSize / focusCodeSize) * 100)}%`,
    tooltip = `${displayPercent} of text coded with ${focusCodeName} is also coded with ${code.name}`
  return (
    <div className="mv4 dim pointer" onClick={onClick} title={tooltip}>
      <ChartDataLabel style={{ marginLeft: toPercent(barLeft) }}>
        {displayPercent}&nbsp;{code.name}
      </ChartDataLabel>
      <div className="flex items-stretch h1">
        <div style={{ width: toPercent(barLeft) }} />
        <div
          className="br1 br--left"
          style={{
            width: toPercent(overlapSize / totalSize),
            backgroundColor: code.color,
          }}
        />
        <div
          className="br1 br--right"
          style={{
            opacity: 0.5,
            width: toPercent((code.size - overlapSize) / totalSize),
            backgroundColor: code.color,
          }}
        />
      </div>
    </div>
  )
}

interface FocusChartProps {
  code: ChartCode
  extentRight: number
}

const FocusChart: React.FC<FocusChartProps> = ({ code, extentRight }) => {
  return (
    <div className="mv4">
      <ChartDataLabel>{code.name}</ChartDataLabel>
      {!code.size ? (
        <div className="mv2 mid-gray f6">No data</div>
      ) : (
        <div
          className="h1 br1"
          style={{
            width: toPercent(code.size / (code.size + extentRight)),
            backgroundColor: code.color || DEFAULT_CODE_COLOR,
          }}
        />
      )}
    </div>
  )
}

function toPercent(val: number) {
  return `${val * 100}%`
}
