import React from "react"
import { useHistory, useRouteMatch } from "react-router-dom"
import { Command, RouteParams } from "./command"
import {
  useDialog,
  useOpenTab,
  useRequestConfirmation,
  useToast,
} from "../hooks"
import { Messages } from "../msg"
import { useApolloClient } from "@apollo/client"
import { useProjectDispatch } from "../hooks"

const noMessages = {} as Partial<Messages>

export function useExec() {
  const history = useHistory(),
    { params } = useRouteMatch<RouteParams>(),
    client = useApolloClient(),
    { addToast } = useToast(),
    projectDispatch = useProjectDispatch(),
    openTab = useOpenTab(),
    ctx = React.useMemo(
      () => ({ route: params, client, projectDispatch, openTab, history }),
      [params, client, projectDispatch, openTab, history]
    ),
    requestConfirmation = useRequestConfirmation(),
    { hide } = useDialog(),
    exec = async function <I, O>(
      cmd: Command<I, O>,
      input: I,
      options: ExecOptions = {}
    ) {
      const msg = cmd.messages ? cmd.messages(ctx, input) : noMessages

      const action = async () => {
        try {
          const output = await cmd.execute(ctx, input)

          if (cmd.sideEffect) cmd.sideEffect(ctx, input, output)
          if (msg.success) addToast({ type: "success", message: msg.success })
          if (options.closeDialog) hide()
          return output
        } catch (err) {
          if (msg.error) {
            const message =
              typeof msg.error === "function" ? msg.error(err) : msg.error
            addToast({ type: "error", message })
          }
          if (cmd.errorRedirect) {
            const errorRedirect = cmd.errorRedirect(ctx, input, err)
            if (errorRedirect) history.replace(errorRedirect)
          }
          throw err
        }
      }

      if (msg.confirm && cmd.confirm) {
        requestConfirmation({ message: msg.confirm, action })
      } else {
        return action()
      }
    }

  return React.useCallback(exec, [
    ctx,
    history,
    addToast,
    requestConfirmation,
    hide,
  ])
}

export interface ExecOptions {
  closeDialog?: boolean
}
