import { getFindState, deactivateFind, activateFind } from "editor"
import { X, Search } from "../base"
import { ProseMirrorButton, useViewOrFail } from "./utils"

export const ToggleFindButton: React.FC = () => {
  const view = useViewOrFail(),
    { active } = getFindState(view.state)
  return active ? (
    <ProseMirrorButton
      execute={deactivateFind}
      icon={<X />}
      label="Close Find (Esc)"
      className=""
    />
  ) : (
    <ProseMirrorButton
      execute={activateFind}
      icon={<Search />}
      label="Find (Ctrl+F)"
      className=""
    />
  )
}
