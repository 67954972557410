import React from "react"
import { useAccessTokenIfExists } from "./auth-context"
import { useProjectDispatch, useProjectState } from "./project-state"
import { TabDataType, TabState } from "./project-state-tabs"
import { useProjectId } from "./use-project-id"

interface TabContextValue<T> {
  tab: TabState
  state: T
  setState: React.Dispatch<React.SetStateAction<T>>
  writeState: (state: T) => void
}

export const TabStateContext = React.createContext<unknown>(null)

interface TabStateProviderProps<T> {
  initialState: T
}

export function withTabStateProvider<P, S>(
  Comp: React.ComponentType<P>,
  initialState: S
) {
  class WrappedComponent extends React.Component<P> {
    displayName = Comp.displayName

    render() {
      return (
        <TabStateProvider<S> initialState={initialState}>
          <Comp {...this.props} />
        </TabStateProvider>
      )
    }
  }

  return WrappedComponent as React.ComponentType<P>
}

export function TabStateProvider<T>({
  children,
  initialState,
}: React.PropsWithChildren<TabStateProviderProps<T>>) {
  const { tabs, activeTabIndex } = useProjectState(),
    tab = tabs[activeTabIndex],
    { read, write } = useTabData(),
    storedState = read<T>(tab.id, tab.dataType, StoredTabData.State),
    [state, setState] = React.useState(storedState || initialState),
    writeState = React.useCallback(
      (state: T) => {
        write(tab.id, tab.dataType, StoredTabData.State, state)
      },
      [write, tab.id, tab.dataType]
    ),
    value = React.useMemo(
      () => ({ tab, state, setState, writeState }),
      [tab, state, setState, writeState]
    )

  React.useEffect(() => {
    writeState(state)
  }, [state, writeState])

  return (
    <TabStateContext.Provider value={value}>
      {children}
    </TabStateContext.Provider>
  )
}

export function useTabState<T>() {
  return React.useContext(TabStateContext as React.Context<TabContextValue<T>>)
}

export function useOpenTab() {
  const dispatch = useProjectDispatch(),
    tabData = useTabData()
  return React.useCallback(
    function <S>(
      id: string,
      dataType: TabDataType,
      state?: S,
      scrollTop?: number
    ) {
      const oldScrollTop = tabData.read<number>(
        id,
        dataType,
        StoredTabData.ScrollTop
      )
      if (state) {
        tabData.clear(id, dataType)
        tabData.write(id, dataType, StoredTabData.State, state)
      }
      const newScrollTop = scrollTop !== undefined ? scrollTop : oldScrollTop
      if (newScrollTop !== undefined) {
        tabData.write(id, dataType, StoredTabData.ScrollTop, newScrollTop)
      }

      dispatch({ type: "open-tab", id, dataType })
    },
    [tabData, dispatch]
  )
}

export enum StoredTabData {
  State = "state",
  ScrollTop = "scrollTop",
  Other = "other",
}

export function useTabData() {
  const projectId = useProjectId(),
    // in case called while not logged in
    token = useAccessTokenIfExists(),
    userId = token ? token.userId : "",
    scope = React.useMemo(
      () => [userId.toString(), projectId] as [string, string],
      [userId, projectId]
    )

  return React.useMemo(
    () => ({
      read<T>(id: string, dataType: TabDataType, nameSpace: StoredTabData) {
        return getStoredData(...scope, id, dataType)[nameSpace] as T | undefined
      },
      write<T>(
        id: string,
        dataType: TabDataType,
        nameSpace: StoredTabData,
        data: T
      ) {
        writeTabData(...scope, id, dataType, nameSpace, data)
      },
      clear(id: string, dataType: TabDataType) {
        localStorage.removeItem(tabKey(...scope, id, dataType))
      },
    }),
    [scope]
  )
}

export function writeTabData<T>(
  userId: string,
  projectId: string,
  id: string,
  dataType: TabDataType,
  nameSpace: StoredTabData,
  data: T
) {
  localStorage.setItem(
    tabKey(userId, projectId, id, dataType),
    JSON.stringify({
      ...getStoredData(userId, projectId, id, dataType),
      [nameSpace]: data,
    })
  )
}

function getStoredData(
  userId: string,
  projectId: string,
  id: string,
  dataType: TabDataType
): Record<string, unknown> {
  const value = localStorage.getItem(tabKey(userId, projectId, id, dataType))
  if (!value) return {}
  try {
    return JSON.parse(value)
  } catch (err) {
    return {}
  }
}

export function tabKey(
  userId: string,
  projectId: string,
  id: string,
  dataType: TabDataType
) {
  return [userId, projectId, id, dataType].join("-")
}
