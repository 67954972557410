import { Redirect, Route, Switch } from "react-router-dom"
import { ClientRoute } from "lib"
import { ForgotPassword } from "./ForgotPassword"
import { SignIn } from "./SignIn"
import { SignUp } from "./SignUp"
import { VerifyEmail } from "./VerifyEmail"
import { ResetPassword } from "./ResetPassword"
import { ApolloProvider } from "./ApolloProvider"
import { DialogProvider } from "./DialogProvider"

export const AppPublic: React.FC = () => {
  return (
    <ApolloProvider>
      <DialogProvider>
        <Switch>
          <Route path={ClientRoute.SignIn} component={SignIn} />
          <Route path={ClientRoute.SignUp} component={SignUp} />
          <Route path={ClientRoute.ForgotPassword} component={ForgotPassword} />
          <Route path={ClientRoute.ResetPassword} component={ResetPassword} />
          <Route path={ClientRoute.VerifyEmail} component={VerifyEmail} />
          <Redirect to={ClientRoute.SignIn} />
        </Switch>
      </DialogProvider>
    </ApolloProvider>
  )
}
