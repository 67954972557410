import * as yup from "yup"
import { gql, useQuery } from "@apollo/client"
import { DialogForm, Field } from "../base"
import {
  DEFAULT_CODE_COLOR,
  extractTags,
  pickManyById,
  UpdateGetCode,
  UpdateGetCodeVariables,
  useCodes,
  useExec,
  updateCodes,
  createCode,
} from "../../utils"
import {
  CommonValues,
  commonSchema,
  getDefaultTagValues,
  NameField,
  DescriptionField,
  TagField,
} from "./common"

interface CodeValues extends CommonValues {
  color: string
}

const codeSchema = yup.object({
  ...commonSchema,
  color: yup.string(),
})

export function CodeCreate() {
  const exec = useExec()

  return (
    <DialogForm<CodeValues>
      label="Create Code"
      values={{
        name: "",
        description: "",
        color: DEFAULT_CODE_COLOR,
        tags: getDefaultTagValues(),
      }}
      schema={codeSchema}
      submitText="Create"
      onSubmit={async (data) => exec(createCode, data)}
    >
      <CodeFields />
    </DialogForm>
  )
}

const GET_CODE = gql`
  query UpdateGetCode($id: ID!) {
    getCode(id: $id) {
      id
      name
      description
      color
      tags {
        id
      }
    }
  }
`

export const CodeUpdate: React.FC<{ id: string }> = ({ id }) => {
  const { data, error } = useQuery<UpdateGetCode, UpdateGetCodeVariables>(
      GET_CODE,
      {
        variables: { id },
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-first",
      }
    ),
    exec = useExec()
  return (
    <DialogForm<CodeValues>
      label="Edit Code"
      error={!!error}
      loading={!data}
      values={{
        name: data?.getCode.name || "",
        description: data?.getCode.description || "",
        color: data?.getCode.color || DEFAULT_CODE_COLOR,
        tags: getDefaultTagValues(data?.getCode.tags),
      }}
      schema={codeSchema}
      submitText="Update"
      onSubmit={(data) => exec(updateCodes, { ids: [id], data })}
    >
      <CodeFields />
    </DialogForm>
  )
}

const CodeFields = () => {
  const { tags } = useCodes()
  return (
    <>
      <div className="flex">
        <NameField className="flex-grow-1 mr3" />
        <ColorField className="w3 h-100" />
      </div>

      <DescriptionField />
      <TagField tags={tags} />
    </>
  )
}

type CodesUpdateValues = Pick<CodeValues, "color" | "tags">

export const CodesUpdate: React.FC<{ ids: string[] }> = ({ ids }) => {
  const { codes, tags } = useCodes(),
    exec = useExec(),
    activeCodes = pickManyById(codes, ids),
    activeTags = extractTags(activeCodes),
    activeColors = [...new Set(codes.map((code) => code.color))].filter(
      Boolean
    ),
    defaultColor = activeColors.length === 1 ? activeColors[0] : "#efefef"

  return (
    <DialogForm<CodesUpdateValues>
      label="Edit Codes"
      values={{ color: defaultColor, tags: getDefaultTagValues(activeTags) }}
      schema={yup.object({
        color: yup.string(),
        tags: commonSchema.tags,
      })}
      submitText="Update"
      onSubmit={({ color, tags }) => {
        const data: Partial<CodesUpdateValues> = { tags }
        if (color !== defaultColor) data.color = color
        return exec(updateCodes, { ids, data })
      }}
    >
      <ColorField className="w4 h2" />
      <TagField tags={tags} />
    </DialogForm>
  )
}

const ColorField: React.FC<{ className: string }> = ({ className }) => (
  <Field name="color" label="Color" type="color" controlClassName={className} />
)
