import { CollabEditorToolbarComponent } from "../CollabEditor"
import { isEditable, getFindState } from "editor"
import {
  FindControls,
  ToggleEditButton,
  EditingControls,
  UndoRedo,
  ToolbarSpacer,
  ToggleFindButton,
} from "../ProseMirrorWidgets"
import { PlayMediaButton } from "../PlayMediaButton"

export const EditorToolbar: CollabEditorToolbarComponent = ({ view }) => {
  const findIsOpen = getFindState(view.state).active,
    isEditing = isEditable(view.state)
  return (
    <>
      {findIsOpen ? (
        <FindControls />
      ) : (
        <>
          <ToggleEditButton />
          {isEditing && <EditingControls />}
          <UndoRedo />
        </>
      )}
      <ToolbarSpacer />
      {!findIsOpen && <PlayMediaButton className="mr3" />}
      <ToggleFindButton />
    </>
  )
}
