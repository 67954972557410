import React from "react"
import {
  useProjectId,
  GetProjectData,
  GetProjectDataVariables,
  ProjectChanges,
  ProjectChangesVariables,
  WATCH_CHANGES,
  GET_PROJECT,
  GET_DOCS,
  GET_DOC_DETAIL,
  GET_CODES,
  GET_CODE,
  removeProjectItems,
  getClientId,
  ProjectContext,
} from "../../utils"
import {
  DocumentNode,
  useApolloClient,
  useQuery,
  useSubscription,
} from "@apollo/client"
import { getEnv, ProjectEntity, ProjectEvent } from "lib"
import { PageTitle } from "../base"

const queryMap = new Map<ProjectEntity, SubscriptionResponseFetchers>()

queryMap.set(ProjectEntity.Doc, {
  refetch: GET_DOCS,
  updateOne: GET_DOC_DETAIL,
})

queryMap.set(ProjectEntity.Code, {
  refetch: GET_CODES,
  updateOne: GET_CODE,
})

export const ProjectDataProvider: React.FC = ({ children }) => {
  const projectId = useProjectId(),
    result = useQuery<GetProjectData, GetProjectDataVariables>(GET_PROJECT, {
      variables: { projectId },
    })

  return (
    <ProjectContext.Provider value={result}>
      <PageTitle>{result.data?.getProject.name || "Project"}</PageTitle>
      <ProjectDataSubscriber />
      {children}
    </ProjectContext.Provider>
  )
}

const ProjectDataSubscriber: React.FC = () => {
  const client = useApolloClient(),
    projectId = useProjectId(),
    { data } = useSubscription<ProjectChanges, ProjectChangesVariables>(
      WATCH_CHANGES,
      { variables: { projectId } }
    )

  React.useEffect(() => {
    const event = data?.subscribeToProjectChanges
    if (!event) return
    const { eventName, entityType, entityIds, clientId: eventClientId } = event,
      queries = queryMap.get(entityType as ProjectEntity)

    if (getClientId() === eventClientId) return

    if (!queries) throw new Error(`no queries entity '${entityType}''`)

    if (eventName === ProjectEvent.EntityAdded) {
      client
        .query({
          query: queries.refetch,
          variables: { projectId },
          fetchPolicy: "network-only",
        })
        .then(console.log)
        .catch(errorHandler)
    } else if (eventName === ProjectEvent.EntityChanged) {
      client
        .query({
          query: queries.updateOne,
          variables: { id: entityIds[0] },
          fetchPolicy: "network-only",
        })
        .catch(errorHandler)
    } else {
      removeProjectItems({
        projectId,
        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        field: (entityType + "s") as any, // so wack
        cache: client.cache,
        ids: entityIds,
      })
    }
  }, [data, client, projectId])

  return null
}

function errorHandler(err: Error) {
  if (getEnv() !== "production") console.error(err)
}

interface SubscriptionResponseFetchers {
  refetch: DocumentNode
  updateOne: DocumentNode
}
