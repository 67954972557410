import React from "react"
import { gql, useQuery } from "@apollo/client"
import {
  useExec,
  GetProjectForDelete,
  GetProjectForDeleteVariables,
  deleteProject,
  useDialog,
} from "../../utils"
import { Dialog, DeleteForm } from "../base"

const GET_PROJECT = gql`
  query GetProjectForDelete($id: ID!) {
    getProject(id: $id) {
      id
      name
    }
  }
`

export const ProjectDelete: React.FC<{ id: string; hideOnDelete?: boolean }> =
  ({ id, hideOnDelete = true }) => {
    const exec = useExec(),
      { data } = useQuery<GetProjectForDelete, GetProjectForDeleteVariables>(
        GET_PROJECT,
        { variables: { id } }
      ),
      { hide } = useDialog()
    return (
      <Dialog>
        <DeleteForm
          deleteNoun="Project"
          confirmNoun="Project Name"
          confirmText={data?.getProject.name || ""}
          warning="All the documents and codes in this project will be permanently destroyed."
          onDelete={async () => {
            await exec(deleteProject, { id })
            if (hideOnDelete) hide()
          }}
        />
      </Dialog>
    )
  }
