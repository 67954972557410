import clsx from "clsx"
import { FieldArray } from "formik"
import { ascending } from "lib"
import { yup, Field, Input, Textarea, Button } from "../base"
import { TagsInput, Named, cl, toggle } from "../../utils"

export interface CommonValues {
  name: string
  description: string
  tags: TagsInput
}

export const commonSchema = {
  name: yup.string().required("missing name"),
  description: yup.string(),
  tags: yup.object({
    ids: yup.array().of(yup.string()),
    tags: yup.array().of(yup.string()),
  }),
}

export const NameField: React.FC<{ className?: string }> = ({ className }) => (
  <Field name="name" label="Name" className={className} />
)

export const DescriptionField = () => (
  <Field name="description" label="Description " component={Textarea} />
)

const TAG_NAMES = "tags.names",
  TAG_IDS = "tags.ids"

export const TagField: React.FC<TagFieldProps> = ({ tags }) => {
  return (
    <div className="mb4">
      <div className={clsx(cl.label, "mb2")}>Tags</div>
      {tags.length ? <TagButtons tags={tags} /> : null}
      <FieldArray
        name={TAG_NAMES}
        render={(helpers) => {
          return (
            <div className="flex">
              <Input
                aria-label="Add tags"
                className="flex-grow-1"
                placeholder="Enter new tags separated by a comma"
                onChange={(e) =>
                  helpers.form.setFieldValue(
                    TAG_NAMES,
                    e.currentTarget.value.split(",").filter(Boolean)
                  )
                }
              />
            </div>
          )
        }}
      />
    </div>
  )
}

interface TagFieldProps {
  tags: Named[]
}

export function getDefaultTagValues(selectedTags: { id: string }[] = []) {
  return {
    ids: selectedTags.map((tag) => tag.id).sort(),
    names: [],
  }
}

const TagButtons: React.FC<TagFieldProps> = ({ tags }) => (
  <FieldArray
    name={TAG_IDS}
    render={(helpers) => (
      <div className="flex flex-wrap">
        {tags
          .slice()
          .sort((a, b) => ascending(a.name.toLowerCase(), b.name.toLowerCase()))
          .map((tag) => {
            const value: string[] = helpers.form.values.tags.ids || [],
              active = value.includes(tag.id)

            return (
              <Button
                key={tag.id}
                testId="tag-toggle"
                label={active ? `Untag ${tag.name}` : `Tag with ${tag.name}`}
                text={tag.name}
                className={clsx(
                  active ? cl.btnLightActive : cl.btnLight,
                  "di mb2 mr2 f5"
                )}
                onClick={() =>
                  helpers.form.setFieldValue(
                    TAG_IDS,
                    toggle(value, tag.id).sort()
                  )
                }
              />
            )
          })}
      </div>
    )}
  />
)
