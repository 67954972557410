import React from "react"
import { SomeObject } from "lib"

interface DialogContextValue {
  hideAll: () => void
  hide: () => void
  show: (component: React.FC, props: SomeObject) => void
}

//@ts-expect-error null ctx
export const DialogContext = React.createContext<DialogContextValue>(null)

export function useDialog() {
  const ctx = React.useContext(DialogContext)

  if (!ctx) throw new Error("called `useDialog` outside of DialogContext")

  const show = React.useCallback(
    function <T extends SomeObject>(component: React.FC<T>, props: T) {
      //@ts-expect-error blah blah
      ctx.show(component, props)
    },
    [ctx]
  )

  return React.useMemo(() => ({ ...ctx, show }), [ctx, show])
}
