import { schema, Node } from "lib"
import { DOMSerializer, DOMOutputSpec } from "editor"
import { cl } from "./classes"

function getDomSerializer(modifier?: SerializerModifier) {
  const domSerializer = DOMSerializer.fromSchema(schema)

  domSerializer.nodes.paragraph = () => domSpec("p", cl.p + " mv3")
  domSerializer.nodes.heading = (node) => {
    return domSpec(
      "h" + node.attrs.level,
      //@ts-expect-error index sig
      cl["h" + node.attrs.level]
    )
  }
  return modifier ? modifier(domSerializer) : domSerializer
}

export type SerializerModifier = (ds: DOMSerializer) => DOMSerializer

export function docToHTML(node: Node, modifier?: SerializerModifier) {
  const domSerializer = getDomSerializer(modifier)
  const temp = document.createElement("div")
  temp.appendChild(domSerializer.serializeFragment(node.content))
  const html = temp.innerHTML
  temp.remove()
  return html
}

function domSpec(tag: string, className: string) {
  return [tag, { class: className }, 0] as DOMOutputSpec
}
