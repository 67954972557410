import React from "react"
import { toTitleCase } from "lib"

function error({ verb, subject, noun }: MessageSpec) {
  const prefix = `There was a problem ${verb.ing} your`
  if (isNotSingle(subject)) {
    return `${prefix} ${pluralize(subject, noun)}.`
  } else {
    return (
      <>
        {prefix} {noun} <b>{subject}</b>.
      </>
    )
  }
}

function success({ verb, subject, noun }: MessageSpec) {
  if (isNotSingle(subject)) {
    return `${toTitleCase(pluralize(subject, noun))} ${verb.past}.`
  } else {
    return (
      <>
        {toTitleCase(verb.past)} {noun} <b>{subject}</b>.
      </>
    )
  }
}

function confirm({ verb, subject, noun }: MessageSpec) {
  const prefix = `You're about to ${verb.present}`
  if (isNotSingle(subject)) {
    return `${prefix} ${subject} ${pluralize(subject, noun)}.`
  } else {
    return (
      <>
        {prefix} your {noun} <b>{subject}</b>.
      </>
    )
  }
}

interface MessageSpec {
  verb: Verb
  subject: Subject
  noun: string
}

interface Verb {
  present: string
  past: string
  ing: string
}

type Subject = number | string

function isNotSingle(subject: Subject): subject is number {
  return typeof subject !== "string"
}

const verbs = {
  create: verb("create", "created", "creating"),
  delete: verb("permanently delete", "deleted", "deleting"),
  remove: verb("remove", "removed", "removing"),
  reset: verb("reset", "reset", "resetting"),
  send: verb("send", "sent", "sending"),
  save: verb("save", "saved", "saving"),
  cancel: verb("cancel", "canceled", "canceling"),
  load: verb("load", "loaded", "loading"),
  import: verb("import", "imported", "importing"),
  start: verb("start", "started", "starting"),
  finish: verb("finish", "finished", "finishing"),
  verify: verb("verify", "verified", "verifying"),
  update: verb("update", "updated", "updating"),
  accept: verb("accept", "accepted", "accepting"),
  reject: verb("reject", "rejected", "rejecting"),
  leave: verb("leave", "left", "leaving"),
}

function verb(present: string, past: string, gerund: string): Verb {
  return { present, past, ing: gerund }
}

type VerbKey = keyof typeof verbs

export const msg = Object.keys(verbs).reduce((msg, key) => {
  const verb = verbs[key as VerbKey]

  return {
    ...msg,
    [key]: (noun: string, subject: Subject = 1) => ({
      success: success({ verb, subject, noun }),
      error: error({ verb, subject, noun }),
      confirm: confirm({ verb, subject, noun }),
    }),
  }
}, {} as MSG)

type MSG = Record<VerbKey, (noun: string, subject?: Subject) => Messages>

export interface Messages {
  success?: React.ReactChild
  error?: ((err: Error) => React.ReactChild) | React.ReactChild
  confirm?: React.ReactChild
}

export function pluralize(count: number, thing: string, plural = thing + "s") {
  return count <= 1 ? thing : plural
}

export enum Noun {
  Doc = "document",
  Code = "code",
  Tag = "tag",
  Project = "project",
  Invite = "invite",
  Transcription = "transcription",
  Collaborator = "collaborator",
  Account = "account",
  Upload = "upload",
  File = "file",
  Media = "audio file",
}
