import React from "react"
import { isEditable, insertTimestamp } from "editor"
import clsx from "clsx"
import { useProsemirror, Button, Stopwatch, SkipBackward, Help } from "../base"
import { cl, useDialog } from "../../utils"
import { MediaHelpDialog } from "./MediaHelpDialog"

const className = clsx("ml2", cl.btnGhost)

export const MediaPlayerButtons: React.FC = () => {
  const { view } = useProsemirror(),
    { show } = useDialog(),
    insertTimestampDisabled = view ? !isEditable(view.state) : true

  if (!view) return null

  return (
    <>
      <Button
        label="Back 5s"
        className={className}
        icon={<SkipBackward />}
        text={null}
        tooltip
        onClick={() => {
          const audio = document.querySelector("audio")
          if (audio && view) {
            audio.currentTime = Math.max(audio.currentTime - 5, 0)
          }
        }}
      />
      <Button
        label={
          insertTimestampDisabled
            ? "Enable editing to insert a timestamp"
            : "Insert Timestamp"
        }
        className={className}
        icon={<Stopwatch />}
        text={null}
        tooltip
        disabled={insertTimestampDisabled}
        onClick={() => {
          const audio = document.querySelector("audio")
          if (audio && view) {
            insertTimestamp(audio.currentTime)(view.state, view.dispatch)
          }
        }}
      />
      <Button
        label="Audio Help"
        className={className}
        icon={<Help />}
        text={null}
        tooltip
        onClick={() => show(MediaHelpDialog, {})}
      />
    </>
  )
}
