import React from "react"
import {
  createEditorView,
  collab,
  EditorView,
  Plugin,
  createTimeStampNodeNodeView,
} from "editor"
import { getDocumentFromJSON, SomeObject } from "lib"
import {
  cl,
  getClientId,
  getReadableTimestamp,
  useMediaContext,
} from "../../utils"
import { ProseMirrorEditor, UpdateProseMirrorView } from "../base"

export interface ProseMirrorProviderProps {
  version: number
  text: SomeObject
  onLoad?: (view: EditorView) => void
  plugins?: Plugin[]
}

export const ProseMirrorProvider: React.FC<ProseMirrorProviderProps> = ({
  text,
  version,
  children,
  onLoad,
  plugins = [],
}) => {
  const { playMedia } = useMediaContext(),
    createView = React.useRef(
      (div: HTMLDivElement, updateView: UpdateProseMirrorView) => {
        const view = createEditorView(getDocumentFromJSON(text), div, {
          plugins: [collab.collab({ version, clientID: getClientId() })].concat(
            plugins
          ),
          viewOptions: {
            dispatchTransaction(tr) {
              updateView(tr)
            },
            attributes: {
              "data-testid": "editor",
              class: "ph2 ph4-ns pb5 pt3",
            },
            scrollMargin: 100, // due to sticky header,
            nodeViews: {
              timestamp: createTimeStampNodeNodeView((span, seconds) => {
                const ts = getReadableTimestamp(seconds.toString())
                span.innerHTML = `${ts}`
                span.addEventListener("click", () => playMedia(seconds))
                span.className = cl.a + " bg-moon-gray br2 pa1"
                return span
              }),
            },
          },
          nodeClassNames: {
            heading: (node) => getHeadingClassName(node.attrs.level),
            paragraph: () => cl.p,
          },
        })

        if (onLoad) setTimeout(() => onLoad(view))

        return view
      }
    )

  return (
    <ProseMirrorEditor createView={createView.current}>
      {children}
    </ProseMirrorEditor>
  )
}

function getHeadingClassName(level: number) {
  const className = level === 1 ? cl.h1 : level === 2 ? cl.h2 : cl.h3
  return className + ` f${level}`
}
