import React from "react"
import { FetchPolicy, useQuery } from "@apollo/client"
import { GetText, GetTextVariables } from "../../utils"
import { ActionMenuItem, DataLoadingError, Spinner } from "../base"
import { GET_TEXT } from "./graphql"
import { ProseMirrorProvider } from "./ProseMirrorProvider"
import { CodePaletteProvider } from "./CodePaletteProvider"
import { DocSyncer, getLastSyncTime } from "./DocSyncer"
import { CodeRangeMapper, View, CollabEditorToolbarComponent } from "./View"
import { EditorView, Plugin } from "editor"
import { MediaProvider } from "../MediaPlayer"
import { StripeClickHandlerProps } from "../CodeStripes"

export { CollabEditorToolbarComponent }

export interface CollabEditorProps {
  docId: string
  hasMedia?: boolean
  onLoad?: (view: EditorView) => void
  plugins?: Plugin[]
  mapRanges?: CodeRangeMapper
  ToolbarComponent: CollabEditorToolbarComponent
  useStripeMenuItems: (
    docId: string
  ) => (props: StripeClickHandlerProps) => ActionMenuItem[]
}

export const CollabEditor: React.FC<CollabEditorProps> = ({
  docId,
  hasMedia,
  onLoad,
  plugins,
  mapRanges,
  ToolbarComponent,
  children,
  useStripeMenuItems,
}) => {
  const fetchPolicy = getFetchPolicy(docId),
    { data, error, loading, refetch } = useQuery<GetText, GetTextVariables>(
      GET_TEXT,
      {
        variables: { docId },
        notifyOnNetworkStatusChange: true,
        fetchPolicy,
      }
    ),
    refetchDoc = React.useCallback(() => refetch(), [refetch])

  if (error) {
    return <DataLoadingError className="mv3" />
  } else if (!data || loading) {
    return <Spinner style={{ minHeight: "100vh" }} />
  } else {
    return (
      <MediaProvider id={docId} available={hasMedia}>
        <ProseMirrorProvider
          {...data.connectToDoc}
          onLoad={onLoad}
          plugins={plugins}
        >
          <CodePaletteProvider>
            <DocSyncer docId={docId} onReload={refetchDoc} />
            <View
              docId={docId}
              mapRanges={mapRanges}
              ToolbarComponent={ToolbarComponent}
              useStripeMenuItems={useStripeMenuItems}
            />
            {children}
          </CodePaletteProvider>
        </ProseMirrorProvider>
      </MediaProvider>
    )
  }
}

// server expires instances after 120min of inactivity
const MINUTES_TO_WAIT = 100

function getFetchPolicy(docId: string): FetchPolicy {
  const lastSync = getLastSyncTime(docId),
    beenAWhile = xMinutesHaveElapsed(MINUTES_TO_WAIT, lastSync)
  return beenAWhile ? "network-only" : "cache-first"
}

function xMinutesHaveElapsed(x: number, since: number) {
  const diff = Date.now() - since,
    minutes = diff / (1000 * 60)
  return minutes > x
}
