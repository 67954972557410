import clsx from "clsx"
import { EditorState, Command } from "editor"
import { cl } from "../../utils"
import { useProsemirror, ToolbarButton } from "../base"

export function useViewOrFail() {
  const { view } = useProsemirror()

  if (!view) throw new Error("called useViewOrFail, but view is falsy")

  return view
}

export const ToolbarSpacer: React.FC = () => {
  return <div className="flex-grow-1"></div>
}

//eslint-disable-next-line @typescript-eslint/no-explicit-any
type StateFunction = (state: EditorState) => any

interface ToolbarButtonProps {
  label: string
  icon?: React.ReactElement
  execute: Command
  isActive?: StateFunction
  className?: string
}

export const ProseMirrorButton: React.FC<ToolbarButtonProps> = ({
  label,
  icon,
  execute,
  isActive = () => false,
  className = "mr1",
}) => {
  const view = useViewOrFail(),
    onClick = () => execute(view.state, view.dispatch),
    active = isActive(view.state),
    enabled = execute(view.state)

  return (
    <ToolbarButton
      label={label}
      tooltip={label}
      icon={icon}
      text={null}
      className={clsx(
        "flex-shrink-0",
        className,
        active ? cl.btnLightActive : cl.btnLight
      )}
      // Buttons should remain focusable, so `disabled` prop is not used
      // ProseMirror commands that are "disabled" won't do anything anyway.
      aria-disabled={!enabled}
      onClick={onClick}
    />
  )
}
