import React from "react"
import { CodeRange, getEnv } from "lib"
import { drawClipPath } from "./styles"
import clsx from "clsx"
import { ActionMenu, ActionMenuItem } from "../base"

export interface MaybeClippedCodeRange extends CodeRange {
  clipFrom?: boolean
  clipTo?: boolean
}

export interface CodeStripeProps extends MaybeClippedCodeRange {
  top: number
  height: number
  colIndex: number
  stickyIndex: number
  stickyHeight: number
  labelTop: number
  codeName: string
  codeColor: string
  onStripeClick: (props: StripeClickHandlerProps) => void
  getMenuItems: (props: StripeClickHandlerProps) => ActionMenuItem[]
  clipFrom?: boolean
  clipTo?: boolean
  active: boolean
}

export interface StripeClickHandlerProps {
  codeId: string
  codeName: string
  from: number
  to: number
}

const COL_WIDTH = 10
const COL_GUTTER = COL_WIDTH / 2
const BORDER_WIDTH = 4
export const APPROX_LABEL_HEIGHT = 32
export const STRIPE_COLUMN_SIZE = COL_WIDTH + COL_GUTTER

export const LABEL_NUDGE_Y = 8

const MENU_NUDGE = 5

export const CodeStripe = React.memo<CodeStripeProps>(
  ({
    colIndex,
    codeId,
    top,
    height,
    labelTop,
    codeName,
    codeColor,
    stickyIndex,
    stickyHeight,
    onStripeClick,
    from,
    to,
    clipFrom = false,
    clipTo = false,
    getMenuItems,
    active,
  }) => {
    const left = STRIPE_COLUMN_SIZE * colIndex
    if (getEnv() !== "test" && height <= 0) return null // don't show zero height stripes
    const clickProps = { codeId, from, to, codeName }

    const labelBelowStripe = labelTop + APPROX_LABEL_HEIGHT > top + height,
      activeProps = getActiveProps(active)
    return (
      <>
        <div
          style={{
            left,
            top,
            height,
            width: COL_WIDTH,
            backgroundColor: codeColor,
            clipPath: drawClipPath(clipFrom, clipTo),
            zIndex: Math.min(-colIndex) - 1,
            pointerEvents: "none",
            ...activeProps,
          }}
          className="absolute br2 pointer"
        />
        <div
          className="absolute"
          style={{
            top: labelTop + LABEL_NUDGE_Y,
            left: left + COL_WIDTH - (labelBelowStripe ? BORDER_WIDTH : 0),
            height: stickyHeight,
            pointerEvents: "none",
            zIndex: -colIndex,
            ...activeProps,
          }}
        >
          <ActionMenu
            label={codeName}
            items={getMenuItems(clickProps)}
            content={codeName}
            menuProps={{
              offsetX: MENU_NUDGE,
              offsetY: MENU_NUDGE,
              portal: true,
              viewScroll: "close",
            }}
            buttonProps={{
              //@ts-expect-error data-testid is unknown prop
              ["data-testid"]: "code-stripe",
              className: clsx(
                "mid-gray fw6 f6 ph2 pv0 ba br2 bg-white pointer",
                !labelBelowStripe && "br--right"
              ),
              onClick: () => {
                if (onStripeClick) onStripeClick(clickProps)
              },
              styles: {
                position: "sticky",
                whiteSpace: "nowrap",
                top: stickyIndex * APPROX_LABEL_HEIGHT + LABEL_NUDGE_Y,
                borderColor: codeColor,
                borderWidth: BORDER_WIDTH,
                borderLeftWidth: labelBelowStripe ? BORDER_WIDTH : 0,
                paddingTop: 1,
                paddingBottom: 1,
                pointerEvents: "all",
              },
            }}
          />
        </div>
      </>
    )
  }
)

function getActiveProps(active: boolean) {
  return {
    opacity: active ? 1 : 0.15,
    transition: "opacity 0.2s",
  }
}
