import React from "react"
import clsx from "clsx"
import { randomId } from "../../../utils"
import { ActionMenuItem } from "../ActionMenu"
import { ListGridRow } from "./ListGridRow"
import {
  getListGridReducer,
  ListGridData,
  initialState,
  useSelectionValidation,
  ArrowKey,
} from "./ListGridState"

export { ListGridSelection } from "./ListGridState"

interface ListGridProps<T extends ListGridData> {
  items: T[]
  columns: ListGridColumn<T>[]
  className?: string
  rowClassName?: string
  cellClassName?: string
  selectable: boolean
  menus?: {
    single: (item: T) => ActionMenuItem[]
    multiple: (items: T[]) => ActionMenuItem[]
  }
}

export function ListGrid<T extends ListGridData>({
  items,
  columns,
  className,
  rowClassName,
  cellClassName,
  selectable,
  menus,
}: ListGridProps<T>) {
  const [state, dispatch] = React.useReducer(
    getListGridReducer(items, columns.length),
    initialState
  )

  const onKeyDown = React.useCallback((e: React.KeyboardEvent) => {
      if (e.key.match(/arrow/i)) {
        e.preventDefault()
        e.stopPropagation()
        dispatch({ type: "move", key: e.key as ArrowKey })
      }
    }, []),
    gridId = React.useRef(randomId())

  const menuMulti = React.useMemo(() => {
    if (!menus) return []
    return menus.multiple(
      items.filter((item) => {
        return state.selection.ids.includes(item.id)
      })
    )
  }, [state.selection.ids, menus, items])

  React.useEffect(() => {
    if (state.shouldFocusDomElement) {
      const item: HTMLElement | null = document.querySelector(
        `#${gridId.current}.list-grid [tabIndex="0"]`
      )
      if (item) {
        item.focus()
        dispatch({ type: "did-focus-dom-element" })
      }
    }
  }, [state.shouldFocusDomElement])

  useSelectionValidation(items, state.selection, dispatch)

  return (
    <div
      id={gridId.current}
      className={clsx("list-grid", className)}
      style={{ userSelect: "none" }}
    >
      {items.map((item, rowIndex) => {
        const rowIsFocused = state.keyboardPosition.rowIndex === rowIndex,
          selected = state.selection.ids.includes(item.id)
        return (
          <ListGridRow
            key={item.id}
            id={item.id}
            index={rowIndex}
            dispatch={dispatch}
            className={rowClassName}
            focused={rowIsFocused}
            selected={selected}
            enableSelection={selectable}
            menuData={item}
            menuUseMultiple={selected && state.selection.ids.length > 1}
            menuSingle={menus?.single}
            menuMulti={menuMulti}
          >
            {columns.map(({ component: Cell }, colIndex) => (
              <Cell
                key={colIndex}
                data={item}
                onKeyDown={onKeyDown}
                tabIndex={
                  rowIsFocused && state.keyboardPosition.colIndex === colIndex
                    ? 0
                    : -1
                }
                className={clsx("list-grid-cell", cellClassName)}
              />
            ))}
          </ListGridRow>
        )
      })}
    </div>
  )
}

interface ListGridColumn<T extends ListGridData> {
  component: React.ComponentType<ListGridCellsBaseProps<T>>
}

export interface ListGridCellsBaseProps<T extends ListGridData> {
  data: T
  className: string
  tabIndex: 0 | -1
  onKeyDown: React.KeyboardEventHandler
}
