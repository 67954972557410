import { ListGridSelection } from "../../components/base"

export enum SidebarTab {
  Doc,
  Code,
  Analysis,
  Search,
}

export interface SidebarState {
  open: boolean
  tab: SidebarTab
  selection: ListGridSelection
  filterTagIds: string[]
}

export const defaultSidebarState: SidebarState = {
  open: true,
  tab: SidebarTab.Doc,
  selection: { ids: [] },
  filterTagIds: [],
}

export type SidebarAction =
  | {
      type: "toggle-sidebar-tab"
      tab: SidebarTab
    }
  | {
      type: "set-selection"
      tab: SidebarTab
      selection: ListGridSelection
    }
  | {
      type: "apply-filter"
      tab: SidebarTab
      tagIds: string[]
    }
  | {
      type: "clear-filter"
    }

export function sidebarReducer(
  state: SidebarState,
  action: SidebarAction
): SidebarState {
  switch (action.type) {
    case "toggle-sidebar-tab":
      if (action.tab === state.tab) {
        return state.open
          ? { ...defaultSidebarState, open: false }
          : { ...state, open: true }
      } else {
        return { ...state, tab: action.tab, open: true }
      }
    case "set-selection":
      return {
        ...state,
        tab: action.tab,
        open: true,
        selection: action.selection,
      }
    case "apply-filter":
      return {
        ...state,
        open: true,
        tab: action.tab,
        filterTagIds: action.tagIds,
      }
    case "clear-filter":
      return { ...state, filterTagIds: [] }
    default:
      return state
  }
}
