import React from "react"
import { useDropzone } from "react-dropzone"
import { Alert } from "./Alert"

interface UploaderProps {
  types: string[]
  multiple?: boolean
  render: (files: File[]) => React.ReactChild
}

export const Uploader: React.FC<UploaderProps> = ({
  types,
  render,
  multiple = false,
}) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    acceptedFiles,
    fileRejections,
  } = useDropzone({
    accept: types.map((type) => "." + type),
    multiple,
  })

  const displayFileLabel =
    acceptedFiles.length === 1
      ? acceptedFiles[0].name
      : `${acceptedFiles.length} files`

  return (
    <>
      <div
        {...getRootProps()}
        className={
          "bg-washed-blue b--dark-blue pa3 b--dashed ba br2 mv3 f5 tc mid-gray pointer"
        }
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop {multiple ? "files" : "file"} here</p>
        ) : acceptedFiles.length ? (
          <p>
            You've selected <b>{displayFileLabel}</b>
          </p>
        ) : (
          <p>{getTypeInfo(types, multiple)}</p>
        )}
      </div>
      {fileRejections.length ? (
        <Alert className="mv3 pa3 tc">Oops! We can't open that.</Alert>
      ) : acceptedFiles.length ? (
        render(acceptedFiles)
      ) : null}
    </>
  )
}

function getTypeInfo(types: string[], multiple: boolean) {
  const typeString = types.join("/")
  if (multiple) {
    return (
      <>
        Drag <b>{typeString}</b> files here, or click to select files
      </>
    )
  } else {
    return (
      <>
        Drag a <b>{typeString}</b> file here, or click to select a file
      </>
    )
  }
}
