import React from "react"
import { Button, ButtonProps } from "./Button"
import { Command, useExec, ExecOptions } from "../../utils"

interface CommandButtonProps<I extends unknown, O extends unknown>
  extends ExecOptions,
    Omit<ButtonProps, "onClick"> {
  command: Command<I, O>
  args: I
  onComplete?: (data: O) => void
}

export function CommandButton<I, O>({
  command,
  args,
  onComplete,
  closeDialog,
  ...buttonProps
}: CommandButtonProps<I, O>) {
  const [loading, setLoading] = React.useState(false),
    exec = useExec(),
    unmounted = React.useRef(false)

  React.useEffect(() => {
    return () => {
      unmounted.current = true
    }
  }, [])
  return (
    <Button
      {...buttonProps}
      loading={loading}
      onClick={async () => {
        try {
          setLoading(true)
          const data = await exec(command, args, { closeDialog })
          // many commands unmount component, which gets
          // a memory leak / no-op error from ReactDOM
          if (!unmounted.current) setLoading(false)
          if (onComplete && data) onComplete(data)
        } catch (err) {
          setLoading(false)
        }
      }}
    />
  )
}
