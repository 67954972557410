import { Named, Tagged, indexById } from "../../utils"
import { OverlapEdge } from "../EdgeProvider"
import { ExploreCodedTextState } from "../ExploreCodedText"
import { MatrixState, SeriesType } from "./state"

type EdgeSet = Map<string, OverlapEdge[]>

export interface EdgesIndex {
  docs: EdgeSet
  codes: EdgeSet
  tags: EdgeSet
}

function edgeSet(): EdgeSet {
  return new Map()
}

type AnyTagged = Tagged<Named>

export function indexEdges(
  _docs: AnyTagged[],
  _codes: AnyTagged[],
  edges: OverlapEdge[]
): EdgesIndex {
  const docsById = indexById(_docs),
    codesById = indexById(_codes),
    docs = edgeSet(),
    codes = edgeSet(),
    tags = edgeSet()

  edges.forEach((edge) => {
    const docTags = docsById.get(edge.docId)?.tags || []
    addEdge(docs, edge.docId, edge)
    docTags.forEach((tag) => addEdge(tags, tag.id, edge))

    edge.codeIds.forEach((codeId) => {
      addEdge(codes, codeId, edge)
      const codeTags = codesById.get(codeId)?.tags || []
      codeTags.forEach((tag) => addEdge(tags, tag.id, edge))
    })
  })

  return { docs, codes, tags }
}

function addEdge(map: EdgeSet, id: string, edge: OverlapEdge) {
  const edges = map.get(id)
  if (edges) {
    if (!edges.includes(edge)) edges.push(edge)
  } else {
    map.set(id, [edge])
  }
}

export function getNavigationState(
  state: MatrixState,
  xItems: Named[],
  yItems: Named[],
  docs: AnyTagged[],
  codes: AnyTagged[]
): ExploreCodedTextState {
  const getDocsAndCodes = (key: "x" | "y") => {
      if (!state.navigateFrom) return {}
      const seriesType = state[key].series,
        seriesItems = key === "x" ? xItems : yItems,
        item = seriesItems[state.navigateFrom[key] - 1]

      if (seriesType === SeriesType.Docs) {
        return { docs: [item.id] }
      } else if (seriesType === SeriesType.Codes) {
        return { codes: [item.id] }
      } else if (seriesType === SeriesType.DocTags) {
        return { docs: pickItemsWithTag(docs, item.id) }
      } else if (seriesType === SeriesType.CodeTags) {
        return { codes: pickItemsWithTag(codes, item.id) }
      } else {
        return {}
      }
    },
    { docs: xDocs = [], codes: xCodes = [] } = getDocsAndCodes("x"),
    { docs: yDocs = [], codes: yCodes = [] } = getDocsAndCodes("y")

  return {
    ...mergeDocAndCodeIds(xDocs.concat(yDocs), xCodes.concat(yCodes)),
    showIntersectionsOnly:
      state.x.series === SeriesType.Codes &&
      state.y.series === SeriesType.Codes,
  }
}

function mergeDocAndCodeIds(docIds: string[], codeIds: string[]) {
  return {
    docIds: [...new Set(docIds)],
    codeIds: [...new Set(codeIds)],
  }
}

function pickItemsWithTag(items: AnyTagged[], tagId: string) {
  return items
    .filter((item) => item.tags.some((tag) => tag.id === tagId))
    .map((item) => item.id)
}
