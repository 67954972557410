import React from "react"

export function FocusOutlineStyles() {
  const [keyboard, setKeyboard] = React.useState(false)

  React.useEffect(() => {
    const onMouseDown = () => {
        if (keyboard) setKeyboard(false)
      },
      onKeydown = (e: KeyboardEvent) => {
        if (e.key === "Tab") setKeyboard(true)
      }

    window.addEventListener("mousedown", onMouseDown)
    window.addEventListener("keydown", onKeydown)

    return () => {
      window.removeEventListener("mousedown", onMouseDown)
      window.removeEventListener("keydown", onKeydown)
    }
  })

  if (keyboard) {
    return null
  } else {
    return (
      <style>
        {
          "* { outline: none !important }\n:root { --focus-color: transparent; }"
        }
      </style>
    )
  }
}
