import React from "react"
import { ascending, descending } from "lib"
import { Named } from "../../utils"
import { Sort } from "./state"

interface SeriesItem extends Named {
  color?: string
}

export function useSortSeriesByOther(
  series: SeriesItem[],
  sort: Sort | undefined,
  sizes: Map<string, number>,
  seriesIsX: boolean
) {
  return React.useMemo(() => {
    if (!sort) return series
    const func = sort.asc ? ascending : descending
    return series.slice().sort((a, b) => {
      const getKey = (item: SeriesItem) =>
          seriesIsX ? `${item.id}-${sort.id}` : `${sort.id}-${item.id}`,
        getSize = (item: SeriesItem) => sizes.get(getKey(item)) || 0
      return func(getSize(a), getSize(b))
    })
  }, [series, sort, sizes, seriesIsX])
}
