import { getEnv } from "lib"
import { postAuth } from "./auth-client"
import { dispatchCustomEvent } from "./custom-events"

export type AccessToken = string | null | undefined

export const AUTH_CHANGE_EVENT = "authstatechanged"

let token: AccessToken = undefined

const MIGHT_EXPIRE_DANGER_ZONE = 1000 * 60 // 1 min

export function setToken(newToken: AccessToken) {
  const oldTokenType = typeof token
  token = newToken || null
  if (oldTokenType !== typeof newToken) {
    dispatchCustomEvent(AUTH_CHANGE_EVENT, token)
  }
}

export async function getToken() {
  if (getEnv() === "test") return "do-not-try-to-parse-me"

  if (token) {
    const exp = getTokenPayload(token).exp * 1000
    if (exp - Date.now() > MIGHT_EXPIRE_DANGER_ZONE) {
      return token
    }
  }

  try {
    const { accessToken } = await postAuth("GetToken", undefined)
    setToken(accessToken)
  } catch (err) {
    setToken(null) // logs the user out
  }

  return token
}

export function getTokenPayload(token: string) {
  const payload = token.split(".")[1]

  return JSON.parse(atob(payload)) as { exp: number; userId: number }
}
