import { ExploreCodedText } from "../ExploreCodedText"
import { RelationshipMatrix } from "../RelationshipMatrix"
import { ProjectSettingsTab } from "../ProjectSettings"
import { TabDataType, TabWidget } from "../../utils"
import { File, Gear, Help, Tools, SquareFill } from "../base"
import { IconType } from "@react-icons/all-files/lib"

export const tabWidgets: { [id: string]: React.ComponentType } = {
  [TabWidget.ExploreCodedText]: ExploreCodedText,
  [TabWidget.RelationshipMatrix]: RelationshipMatrix,
  [TabWidget.ProjectSettings]: ProjectSettingsTab,
}

export const tabTitles = Object.keys(TabWidget).map((name) => ({
  id: (TabWidget as unknown as Record<string, number>)[name].toString(),
  name: name.split(/(?=[A-Z])/).join(" "),
}))

export const iconsByDataType: { [id: string]: IconType } = {
  [TabDataType.Doc]: File,
  [TabDataType.Code]: SquareFill,
  [TabDataType.Analysis]: Tools,
  [TabDataType.Settings]: Gear,
  [TabDataType.Help]: Help,
}
