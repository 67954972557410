import React from "react"
import { AccessToken, getTokenPayload } from "../auth"

export const AuthContext = React.createContext<AccessToken>(undefined)

export function useAccessToken() {
  const token = React.useContext(AuthContext)

  if (token) {
    return getTokenPayload(token)
  } else {
    throw Error("not logged in")
  }
}

export function useAccessTokenIfExists() {
  const token = React.useContext(AuthContext)

  return token ? getTokenPayload(token) : null
}
