import React from "react"
import { cl, ConfirmationContext, ConfirmSpec } from "../utils"
import { P, Button, WirelessDialog } from "./base"
import clsx from "clsx"

export const ConfirmationProvider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<ConfirmSpec | null>(),
    [loading, setLoading] = React.useState(false),
    close = () => {
      setState(null)
      setLoading(false)
    },
    action = state ? state.action : () => null

  return (
    <ConfirmationContext.Provider value={setState}>
      {state ? (
        <WirelessDialog onClose={close} className="top-1 mw6-l">
          <h2 className="near-black lh-title fw4 f3 mt4 mb3">Are you sure?</h2>
          <P> {state.message}</P>
          <div className="flex justify-end">
            <Button
              loading={loading}
              className={clsx(cl.btnDanger)}
              label="Confirm"
              onClick={async () => {
                setLoading(true)
                try {
                  await action()
                  close()
                } catch (err) {
                  setLoading(false)
                }
              }}
            />
          </div>
        </WirelessDialog>
      ) : null}
      {children}
    </ConfirmationContext.Provider>
  )
}
