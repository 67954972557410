import React from "react"
import { ClientRoute } from "lib"
import { cl, useExec, resetPassword } from "../utils"
import {
  CenteredPage,
  Field,
  Form,
  H1PageTitle,
  P,
  yup,
  RouterLink,
} from "./base"

export const ResetPassword: React.FC = () => {
  const token = new URLSearchParams(window.location.search).get(
      "token"
    ) as string,
    exec = useExec()

  return (
    <CenteredPage>
      <H1PageTitle>Reset Password</H1PageTitle>

      <P>Enter your new password below.</P>
      <Form
        values={{ password: "", password2: "" }}
        schema={yup.object({
          password: yup
            .string()
            .required("missing password")
            .min(8, "need at least 8 characters"),
          password2: yup
            .string()
            .oneOf([yup.ref("password"), undefined], "passwords don't match")
            .required("please confirm password"),
        })}
        submitText="Reset Password"
        classNames={{ button: cl.btn }}
        onSubmit={({ password }) => exec(resetPassword, { password, token })}
      >
        <Field name="password" label="New Password" type="password" />
        <Field name="password2" label="Confirm New Password" type="password" />
      </Form>

      <div className="tc mv3">
        <RouterLink to={ClientRoute.SignIn} className="f5">
          Back to Sign-in
        </RouterLink>
      </div>
    </CenteredPage>
  )
}
