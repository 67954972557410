import React from "react"
import { randomId, scrollIntoView } from "../../utils"

export interface CodePaletteProviderProps {
  activeCodeIds: string[]
  onCodeCreated: (id: string) => void
  onAddCoding: (id: string) => void
  onRemoveCoding: (id: string) => void
  canCode: boolean
}

interface CodePaletteContextValue extends CodePaletteProviderProps {
  paletteId: string
  scrollCodeIntoView: (codeId: string) => void
}

//@ts-expect-error null ctx
const CodePaletteContext = React.createContext<CodePaletteContextValue>(null)

export const CodePaletteProvider: React.FC<CodePaletteProviderProps> = ({
  children,
  activeCodeIds: _activeCodeIds,
  onCodeCreated,
  onAddCoding,
  onRemoveCoding,
  canCode,
}) => {
  const [paletteId] = React.useState(randomId()),
    scrollCodeIntoView = React.useCallback(
      (codeId: string) => {
        const el = document.querySelector(
          `[data-palette-item="${paletteId}-${codeId}"]`
        ) as HTMLElement
        if (!el) return
        scrollIntoView(el)
      },
      [paletteId]
    ),
    [activeCodeIds, setActiveCodeIds] = React.useState(_activeCodeIds),
    value = React.useMemo(
      () => ({
        paletteId,
        scrollCodeIntoView,
        activeCodeIds,
        onCodeCreated,
        onAddCoding,
        onRemoveCoding,
        canCode,
      }),
      [
        paletteId,
        scrollCodeIntoView,
        activeCodeIds,
        onCodeCreated,
        onAddCoding,
        onRemoveCoding,
        canCode,
      ]
    )

  // memoization hack
  React.useEffect(() => {
    if (serializeIds(_activeCodeIds) !== serializeIds(activeCodeIds)) {
      setActiveCodeIds(_activeCodeIds)
    }
  }, [activeCodeIds, _activeCodeIds])

  return (
    <CodePaletteContext.Provider value={value}>
      {children}
    </CodePaletteContext.Provider>
  )
}

export function useCodePalette() {
  return React.useContext(CodePaletteContext)
}

function serializeIds(ids: string[]) {
  return ids.slice().sort().join(",")
}
