import { cl } from "../../utils"
import { Alert, Button, Footer } from "../base"

export const SyncErrorPopup: React.FC<{
  onClick: () => unknown
  loading?: boolean
  message: React.ReactChild
  action: string
}> = ({ onClick, message, action, loading = false }) => {
  return (
    <Alert className="fixed bottom-1 left-1 pa3 shadow-1 z-3 f5">
      <div>{message}</div>
      <Footer>
        <Button
          label={action}
          onClick={onClick}
          loading={loading}
          className={cl.btn}
        />
      </Footer>
    </Alert>
  )
}
