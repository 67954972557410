import React from "react"
import clsx from "clsx"
import { gql, useQuery } from "@apollo/client"
import { useDebounce } from "use-debounce"
import { ascending, descending } from "lib"
import {
  useCodes,
  useDocs,
  useProjectId,
  SearchProject,
  SearchProjectVariables,
  Named,
  DEFAULT_CODE_COLOR,
  TabDataType,
} from "../utils"
import {
  File,
  TabLink,
  ListGrid,
  ListGridCellsBaseProps,
  SquareFill,
} from "./base"
import { SidebarPlaceholder } from "./Project"

import "./SidebarSearch.css"

interface SearchResult {
  id: string
  dataId: string
  dataType: TabDataType
  name: string
  preview?: string
  color?: string
  score: number
}

const SEARCH_PROJECT = gql`
  query SearchProject($query: String!, $id: ID!) {
    searchProject(query: $query, id: $id) {
      id
      name
      rank
      preview
      type
    }
  }
`

export const SidebarSearch: React.FC = () => {
  const [queryValue, setQuery] = React.useState(""),
    [query, { isPending }] = useDebounce(queryValue, !queryValue ? 0 : 300, {
      trailing: true,
      leading: false,
    }),
    { docs } = useDocs(),
    { codes } = useCodes(),
    id = useProjectId(),
    { data, loading: queryLoading } = useQuery<
      SearchProject,
      SearchProjectVariables
    >(SEARCH_PROJECT, {
      variables: { id, query },
      fetchPolicy: "network-only",
      skip: !queryValue,
    }),
    loading = queryLoading || isPending(),
    results: SearchResult[] = React.useMemo(() => {
      if (!queryValue) return []

      const isMatch = (item: { name: string }) =>
          item.name.toLowerCase().includes(queryValue.toLowerCase()),
        matchDocs = docs.filter(isMatch).map(resultMapper(TabDataType.Doc)),
        matchCodes = codes.filter(isMatch).map(resultMapper(TabDataType.Code)),
        found = data && !loading ? data.searchProject : [],
        foundResults: SearchResult[] = found.map((found) => {
          const dataType =
            found.type === "code" ? TabDataType.Code : TabDataType.Doc
          return {
            id: `${dataType}-${found.id}`,
            dataId: found.id,
            dataType,
            name: found.name,
            score: found.rank,
            preview: found.preview,
            color:
              found.type === "code"
                ? codes.find((code) => code.id === found.id)?.color
                : undefined,
          }
        })

      return mergeResults([...matchDocs, ...matchCodes, ...foundResults])
        .sort((a, b) => ascending(a.name, b.name))
        .sort((a, b) => descending(a.score, b.score))
    }, [queryValue, docs, codes, data, loading])

  return (
    <div className="flex-grow-1 flex flex-column overflow-hidden">
      <div className="relative flex-shrink-0">
        <input
          className="w-100 bt-0 br-0 bl-0 bb b--black-20 f5 pa3 border-box"
          placeholder="Search for anything..."
          value={queryValue}
          onChange={(e) => setQuery(e.currentTarget.value)}
          autoFocus
        />
        {queryValue && loading && (
          <div
            className="search-spinner"
            style={{
              borderColor: "transparent",
              borderTopColor: "#cccccc",
              borderLeftColor: "#cccccc",
            }}
          />
        )}
      </div>

      {results.length ? (
        <>
          <ListGrid
            items={results}
            columns={[
              {
                component: SearchResult,
              },
            ]}
            selectable={false}
            className="flex-grow-1 overflow-auto"
          />
        </>
      ) : queryValue && !loading ? (
        <SidebarPlaceholder>No results.</SidebarPlaceholder>
      ) : null}
    </div>
  )
}

interface ResultMapperItem extends Named {
  color?: string
}

function resultMapper<T extends ResultMapperItem>(
  dataType: TabDataType
): (item: T) => SearchResult {
  return (item) => {
    const id = `${dataType}-${item.id}`
    return {
      id,
      dataId: item.id,
      dataType,
      name: item.name,
      color: item.color,
      score: 2,
    }
  }
}

const SearchResult = React.memo<ListGridCellsBaseProps<SearchResult>>(
  ({
    data: { dataId, dataType, name, preview, color },
    className,
    ...rest
  }) => {
    return (
      <div className={clsx("pv3 bb b--black-20 pr3", className)}>
        <div className="f5 flex items-center">
          <div className="tc w3 flex-shrink-0 flex items-center justify-center">
            {dataType === TabDataType.Code ? (
              <SquareFill color={color || DEFAULT_CODE_COLOR} />
            ) : dataType === TabDataType.Doc ? (
              <File className="f4" />
            ) : null}
          </div>
          <TabLink id={dataId} dataType={dataType} aria-label={name} {...rest}>
            {name}
          </TabLink>
        </div>
        <div className="flex">
          <div className="w3 flex-shrink-0" />
          {preview && (
            <div className="lh-title mid-gray f6 mt2 search-result">
              <span dangerouslySetInnerHTML={{ __html: preview }} />
            </div>
          )}
        </div>
      </div>
    )
  }
)

function mergeResults(results: SearchResult[]) {
  const unique = new Map<string, SearchResult>()

  results.forEach((found) => {
    const first = unique.get(found.id)
    if (first) {
      first.score += found.score
      if (found.preview) first.preview = found.preview
      if (found.color) first.color = found.color
    } else {
      unique.set(found.id, found)
    }
  })

  return [...unique.values()]
}
