import { Tabs } from "./base"
import { CodeReport } from "./CodeReport"
import { CodeUsageChart } from "./CodeUsage"
import { CodeOverlapChart } from "./CodeOverlap"
import { useDocs, useTabState, withTabStateProvider } from "../utils"

export interface CodeTabState {
  tab: number
  codeReportDocId?: string
}

export const CodeTabs = withTabStateProvider<{ codeId: string }, CodeTabState>(
  ({ codeId }) => {
    const docIds = useDocs().docs.map((doc) => doc.id),
      { state, setState } = useTabState<CodeTabState>()
    return (
      <Tabs
        activeIndex={state.tab}
        onChange={(tab) => setState({ tab })}
        className="mv4"
        tabs={[
          {
            name: "Coded Text",
            render: () => (
              <CodeReport
                codeIds={[codeId]}
                docIds={docIds}
                showIntersectionsOnly={false}
                docId={state.codeReportDocId}
                setDocId={(codeReportDocId) =>
                  setState({ ...state, codeReportDocId })
                }
              />
            ),
          },
          {
            name: "Usage by Document",
            render: () => <CodeUsageChart codeId={codeId} />,
          },
          {
            name: "Overlap with Other Codes",
            render: () => <CodeOverlapChart codeId={codeId} />,
          },
        ]}
      />
    )
  },
  { tab: 0 }
)
