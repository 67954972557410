import React from "react"
import { EditorView, Command, Transaction, EditorState } from "editor"

import "prosemirror-view/style/prosemirror.css"
import "prosemirror-gapcursor/style/gapcursor.css"

interface EditorProps {
  createView: (
    node: HTMLDivElement,
    updateView: UpdateProseMirrorView
  ) => EditorView
}

interface EditorContextValue {
  isInitialized: boolean
  view: EditorView | null
  viewRef: React.MutableRefObject<EditorView | null>
  viewNodeRef: React.MutableRefObject<HTMLDivElement | null>
}

export type UpdateProseMirrorView = (tr: Transaction) => EditorState | undefined

// @ts-expect-error no context value
const ProseMirrorContext = React.createContext<EditorContextValue>()

export const ProseMirrorEditor: React.FC<EditorProps> = ({
  children,
  createView,
}) => {
  const [isInitialized, setIsInitialized] = React.useState(false), // triggers React update when view created
    [lastUpdateTime, setLastUpdateTime] = React.useState(-1), // triggers React updates when state changes
    viewRef = React.useRef<EditorView | null>(null),
    viewNodeRef = React.useRef<HTMLDivElement>(null),
    isUnmounting = React.useRef(false),
    updateView = React.useCallback((tr: Transaction) => {
      const view = viewRef.current
      if (!view) return
      const newState = view.state.apply(tr)
      view.updateState(newState)
      // this can lead to errors in tests about updating
      // unmounted components
      if (!isUnmounting.current) setLastUpdateTime(Date.now())
      return newState
    }, []),
    value = React.useMemo(
      () => ({
        isInitialized,
        view: viewRef.current,
        viewRef: viewRef,
        viewNodeRef,
        lastUpdateTime,
      }),
      [isInitialized, lastUpdateTime]
    )

  React.useEffect(() => {
    if (!viewRef.current && viewNodeRef.current) {
      viewRef.current = createView(viewNodeRef.current, updateView)
      setIsInitialized(true)
    }
  }, [createView, updateView])

  React.useEffect(
    () => () => {
      isUnmounting.current = true
    },
    []
  )

  return (
    <ProseMirrorContext.Provider value={value}>
      {children}
    </ProseMirrorContext.Provider>
  )
}

export const ProsemirrorDiv = () => {
  const { viewNodeRef } = React.useContext(ProseMirrorContext)

  return <div ref={viewNodeRef} spellCheck={false} />
}

export function useProsemirror() {
  return React.useContext(ProseMirrorContext)
}

export function useCommand(command: Command) {
  const { view } = useProsemirror()

  return () => {
    if (!view) return
    command(view.state, view.dispatch)
  }
}
