import clsx from "clsx"
import { msg } from "../../utils"
import { Alert } from "./Alert"
import { ContentPageContainer, PageTitle } from "./layout"
import { H1, P } from "./typography"

export const NoDataMessage: React.FC<{ className?: string }> = ({
  className,
}) => {
  return (
    <Alert error={false} className={clsx(className, "f5 pa3")}>
      We didn't find anything to show here.
    </Alert>
  )
}

export const DataLoadingError: React.FC<{ className?: string }> = ({
  className,
}) => {
  return (
    <Alert className={clsx(className, "f5 pa3")}>
      {msg.load("data", 1).error}
    </Alert>
  )
}

export const FullPageDataError: React.FC = () => {
  return (
    <ContentPageContainer>
      <PageTitle>Error</PageTitle>
      <H1 className="dark-red">Something Went Wrong!</H1>
      <P>We couldn't find what you're looking for. Sorry about that.</P>
    </ContentPageContainer>
  )
}
