import { cl, useExec, createProject } from "../../utils"
import { Field, yup, Textarea, DialogForm } from "../base"

export const ProjectCreate: React.FC = () => {
  const exec = useExec()

  return (
    <DialogForm
      label="Create Project"
      values={{ name: "", description: "" }}
      schema={yup.object({
        name: yup.string().required("your project needs a name"),
        description: yup.string(),
      })}
      submitText="Create"
      classNames={{ button: cl.btn }}
      onSubmit={async (data) => exec(createProject, data)}
    >
      <Field label="Name" name="name" />
      <Field
        label="Description (optional)"
        name="description"
        component={Textarea}
      />
    </DialogForm>
  )
}
