import { ProjectStateProvider } from "./State"
import { ProjectContainer } from "./ProjectContainer"
import { ProjectDataProvider } from "../ProjectDataProvider"
import { EdgeProvider } from "../EdgeProvider"
import { DialogProvider } from "../DialogProvider"
import { ContextMenuProvider } from "../ContextMenuProvider"
import { setProjectLastAccess, useProjectId } from "../../utils"
import React from "react"

export const Project = () => {
  const id = useProjectId()

  React.useEffect(() => {
    setProjectLastAccess(id)
  }, [id])
  return (
    <ProjectDataProvider>
      <EdgeProvider>
        <ProjectStateProvider>
          <DialogProvider>
            <ContextMenuProvider>
              <ProjectContainer />
            </ContextMenuProvider>
          </DialogProvider>
        </ProjectStateProvider>
      </EdgeProvider>
    </ProjectDataProvider>
  )
}
