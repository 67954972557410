export function drawClipPath(clipFrom: boolean, clipTo: boolean) {
  if (!clipFrom && !clipTo) return undefined

  const topLeft = `0% 0%`,
    topRight = `100% 0%`,
    bottomRight = `100% 100%`,
    bottomLeft = `0% 100%`,
    clipTopPaths = clipFrom ? zigzags(true) : [],
    clipBottomPaths = clipTo ? zigzags(false) : []

  const paths = [
    topLeft,
    ...clipTopPaths,
    topRight,
    bottomRight,
    ...clipBottomPaths,
    bottomLeft,
  ].filter((str) => !!str)

  return `polygon(${paths.join(", ")})`
}

function zigzags(top: boolean, inc = 25, depth = 8) {
  const positions = []

  for (let i = 1; i * inc < 100; i++) {
    const x = inc * i,
      baseLine = top ? 0 : 100,
      op = top ? "+" : "-",
      y = i % 2 === 0 ? `${baseLine}%` : `calc(${baseLine}% ${op} ${depth}px)`
    positions.push(`${x}% ${y}`)
  }

  return top ? positions : positions.reverse()
}
