import React from "react"
import ReactDOM from "react-dom"
import clsx from "clsx"
import { cl, TAB_RENDER_CONTAINER, useToast } from "../../utils"
import { Button, CloseButton } from "../base"
import { useInternalMediaContext } from "./utils"

export const MediaPlayer: React.FC = ({ children }) => {
  const { pos, src, error, setPos } = useInternalMediaContext(),
    ref = React.useRef<HTMLAudioElement>(null),
    [ready, setReady] = React.useState(false),
    [hidden, setHidden] = React.useState(false),
    [rate, setRate] = React.useState(1),
    { addToast } = useToast()

  React.useEffect(() => {
    const audio = ref.current
    if (ready && audio && pos !== undefined) {
      audio.currentTime = pos
      audio.play()
      setHidden(false)
    }
  }, [pos, ready])

  React.useEffect(() => {
    if (error) {
      addToast({
        type: "error",
        message: "There was a problem playing your media.",
      })
    }
  }, [addToast, error])

  React.useEffect(() => {
    if (ref.current && !hidden) {
      ref.current.addEventListener("ratechange", () => {
        if (ref.current) setRate(ref.current.playbackRate)
      })
      const handler = getOnKeyDown(ref.current)
      window.addEventListener("keydown", handler)
      return () => window.removeEventListener("keydown", handler)
    }
  }, [ready, hidden])

  if (!src) return null

  return ReactDOM.createPortal(
    <div
      style={{ width: 600 }}
      className={clsx(
        `fixed bottom-0
        pa2 bg-near-white shadow-1 br2 br--top br--right items-center
        overflow-auto`,
        hidden ? "dn" : "flex"
      )}
    >
      <audio
        className="br2 flex-grow-1"
        data-testid="audio"
        ref={ref}
        src={src}
        controls
        onError={() => {
          addToast({ type: "error", message: "Error loading audio" })
        }}
        onLoadedMetadata={() => setReady(true)}
        onPlaying={() => {
          // re-initialize pos so you can click the same
          // timestamp repeatedly
          if (pos !== undefined) setPos(undefined)
        }}
      />
      {rate !== 1 && (
        <Button
          label={`${rate}x`}
          className={clsx("mh2 w3 justify-center", cl.btnLightActive)}
          onClick={() => {
            if (ref.current) ref.current.playbackRate = 1
          }}
        />
      )}
      {children}
      <CloseButton
        label="Close"
        onClick={() => {
          if (ref.current) ref.current.pause()
          setHidden(true)
        }}
        className={clsx(cl.btnGhost, "ml2")}
      />
    </div>,
    document.querySelector(`.${TAB_RENDER_CONTAINER}`) || document.body
  )
}

function getOnKeyDown(audio: HTMLAudioElement) {
  return function (e: KeyboardEvent) {
    const handled = handle(audio, e)
    if (handled) {
      e.stopPropagation()
      e.preventDefault()
    }
  }
}

function handle(audio: HTMLAudioElement, e: KeyboardEvent) {
  if (!e.altKey) return false
  switch (e.key) {
    case "ArrowRight":
      if (audio.duration - audio.currentTime >= 5) {
        audio.currentTime += 5
      } else {
        audio.currentTime = audio.duration - 0.1
      }
      audio.play()
      return true
    case "ArrowLeft":
      if (audio.currentTime >= 5) {
        audio.currentTime -= 5
      } else {
        audio.currentTime = 0
      }
      audio.play()
      return true
    case "ArrowUp":
      if (audio.playbackRate < 2) audio.playbackRate += 0.25
      return true
    case "ArrowDown":
      if (audio.playbackRate > 0.5) audio.playbackRate -= 0.25
      return true
    case "p":
      if (audio.paused) {
        audio.play()
      } else {
        audio.pause()
      }
      return true
    default:
      return false
  }
}
