import React from "react"
import { SomeObject } from "lib"
import { DialogContext } from "../utils"

interface DialogState {
  component: React.FC
  props: SomeObject
}

export const DialogProvider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<DialogState[]>([]),
    hide = React.useCallback(
      () => setState((state) => state.filter((_, i) => i < state.length - 1)),
      []
    ),
    hideAll = React.useCallback(() => setState([]), []),
    show = React.useCallback((component: React.FC, props: SomeObject) => {
      setState((state) => [...state, { component, props }])
    }, []),
    value = React.useMemo(
      () => ({ show, hide, hideAll }),
      [show, hide, hideAll]
    )
  return (
    <DialogContext.Provider value={value}>
      {state.map(({ component: SomeDialog, props }, i) => (
        <SomeDialog key={i} {...props} />
      ))}
      {children}
    </DialogContext.Provider>
  )
}
