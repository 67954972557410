import React from "react"
import { useQuery } from "@apollo/client"
import {
  allowedMediaTypes,
  cl,
  GetDocDetail,
  GetDocDetailVariables,
  GET_DOC_DETAIL,
  useExec,
  useMediaUploader,
  addMediaToDoc,
  removeMediaFromDoc,
} from "../utils"
import {
  Button,
  DataLoadingError,
  DeletableCard,
  Dialog,
  DialogH2,
  Footer,
  Info,
  ProgressBar,
  Uploader,
} from "./base"
import { MediaJobStatus } from "./MediaJobStatus"

const DIALOG_TITLE = "Add or Remove Audio"

export const AddMediaDialog: React.FC<{ docId: string }> = ({ docId }) => {
  const { error, data } = useQuery<GetDocDetail, GetDocDetailVariables>(
    GET_DOC_DETAIL,
    {
      variables: { id: docId },
      fetchPolicy: "cache-and-network",
    }
  )
  const doc = data?.getDoc

  const upload = useMediaUploader().uploads.find(
    (upload) => upload.id === docId
  )

  if (doc?.transcript) {
    return (
      <Dialog>
        <DialogH2>{DIALOG_TITLE}</DialogH2>
        <Info>You can't manage audio until transcription is complete.</Info>
      </Dialog>
    )
  }

  return (
    <Dialog>
      <DialogH2>{DIALOG_TITLE}</DialogH2>
      {error && <DataLoadingError className="mv3" />}
      {doc?.media ? (
        <DeleteMediaWidget id={docId} filename={doc.media.filename} />
      ) : data ? (
        <Uploader
          types={allowedMediaTypes}
          multiple={false}
          render={(files) => (
            <>
              {files.length ? (
                <ConfirmUpload docId={docId} file={files[0]} />
              ) : null}
            </>
          )}
        />
      ) : null}
      {upload && (
        <ProgressBar
          label={`Uploading ${upload.file.name}`}
          progress={upload.progress}
        />
      )}
      {!upload && doc && doc.media && !doc.media.available && (
        <MediaJobStatus
          docId={docId}
          message="We're processing your audio file, please wait."
        />
      )}
    </Dialog>
  )
}

const DeleteMediaWidget: React.FC<{
  filename: string
  id: string
}> = ({ filename, id }) => {
  const exec = useExec()
  return (
    <DeletableCard
      onDelete={() => exec(removeMediaFromDoc, { docId: id, filename })}
      reset
    >
      <b>{filename}</b> is linked to this document.
    </DeletableCard>
  )
}

const ConfirmUpload: React.FC<{ docId: string; file: File }> = ({
  docId,
  file,
}) => {
  const { startUpload } = useMediaUploader(),
    [loading, setLoading] = React.useState(false),
    exec = useExec()
  return (
    <Footer>
      <Button
        className={cl.btn}
        label="Start Upload"
        loading={loading}
        onClick={async () => {
          try {
            setLoading(true)
            const data = await exec(addMediaToDoc, {
              docId,
              filename: file.name,
            })
            if (!data) return
            startUpload({
              id: data.addMediaToDoc.doc.id,
              url: data.addMediaToDoc.uploadUrl,
              file,
            })
          } catch (err) {
            setLoading(false)
          }
        }}
      />
    </Footer>
  )
}
