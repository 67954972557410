import React from "react"
import { Dialog, DialogH2, P, Spinner, Uploader } from "../base"
import { Previewer } from "./Previewer"
//import { PreviewPDF } from "./PreviewPDF"
import { PreviewMedia } from "./PreviewMedia"

const PreviewPDF = React.lazy(() =>
  import("./PreviewPDF").then(({ PreviewPDF }) => ({ default: PreviewPDF }))
)

export const ImportWordDialog: React.FC = () => {
  return (
    <Dialog>
      <DialogH2>Import Word Documents</DialogH2>
      <Uploader
        types={["docx"]}
        multiple
        render={(files) => <Previewer type="docx" files={files} />}
      />
    </Dialog>
  )
}

export const ImportCSVDialog: React.FC = () => {
  return (
    <Dialog>
      <DialogH2>Import Spreadsheet</DialogH2>
      <P>Turn each row in a .csv file into a document.</P>
      <Uploader
        types={["csv"]}
        render={(files) => <Previewer type="csv" files={files} />}
      />
    </Dialog>
  )
}

export const ImportPDFDialog: React.FC = () => {
  return (
    <Dialog>
      <DialogH2>Import PDF</DialogH2>
      <P>Extract text from a PDF document.</P>
      <Uploader
        types={["pdf"]}
        render={(files) => (
          <React.Suspense fallback={<Spinner />}>
            <PreviewPDF file={files[0]} />
          </React.Suspense>
        )}
      />
    </Dialog>
  )
}

export const ImportMediaDialog: React.FC = () => {
  return (
    <Dialog>
      <DialogH2>Transcribe Audio or Video Files</DialogH2>

      <Uploader
        types={["mp3", "mp4", "flac", "WebM", "WAV", "Ogg"]}
        render={(files) => <PreviewMedia file={files[0]} />}
      />
    </Dialog>
  )
}
