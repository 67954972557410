import clsx from "clsx"
import { cl, refreshTranscriptionStatus } from "../utils"
import { CommandButton, Info } from "./base"

export const MediaJobStatus: React.FC<{ docId: string; message: string }> = ({
  docId,
  message,
}) => (
  <Info className="mv3 flex flex-column flex-row-ns items-center justify-between-ns">
    <span>{message}</span>
    <CommandButton
      command={refreshTranscriptionStatus}
      args={{ docId }}
      label="Refresh"
      className={clsx(cl.btnLight, "mt3 mt0-ns")}
    />
  </Info>
)
