import React from "react"
import { Node } from "lib"
import { docToHTML, SerializerModifier } from "../utils"
import clsx from "clsx"

const PREVIEW_MAX_HEIGHT = 500

export const DocPreview: React.FC<{
  doc: Node
  modifySerializer?: SerializerModifier
  className?: string
  style?: React.CSSProperties
}> = ({ doc, modifySerializer, className, style }) => {
  const htmlPreview = React.useMemo(() => {
    return docToHTML(doc, modifySerializer)
  }, [doc, modifySerializer])

  return (
    <div
      className={clsx(
        "ph4 pv3 mv3 br2 bg-white ba b--black-20 overflow-auto",
        className
      )}
      style={{ maxHeight: PREVIEW_MAX_HEIGHT, ...style }}
      dangerouslySetInnerHTML={{ __html: htmlPreview || "" }}
    />
  )
}
