import React from "react"
import { Ellipsis, SortDown } from "./Icons"
import { AnyFunc } from "../../utils"
import {
  Menu,
  MenuItem,
  MenuButton,
  MenuDivider,
  MenuButtonProps,
  MenuProps,
} from "@szhsin/react-menu"

interface Action<T extends AnyFunc = AnyFunc> {
  label: string
  action: T
}

interface Divider {
  divider: true
}

export function action<T extends AnyFunc = AnyFunc>(label: string, action: T) {
  return { label, action }
}

export const DIVIDER: Divider = { divider: true }

type Falsy = void | null | undefined | false

export type ActionMenuItem = Action | Divider | Falsy

interface ActionMenuProps {
  label: string
  content?: React.ReactChild
  items: ActionMenuItem[]
  menuProps?: Omit<MenuProps, "menuButton">
  buttonProps?: MenuButtonProps
}

export function renderMenuItems(items: ActionMenuItem[]) {
  const truthyItems = items.filter(Boolean) as (Action | Divider)[]
  return truthyItems.map((item, i) =>
    "divider" in item ? (
      <MenuDivider key={i} />
    ) : (
      <MenuItem key={i} className="flex items-center f5" onClick={item.action}>
        {item.label}
      </MenuItem>
    )
  )
}

export const ActionMenu: React.FC<ActionMenuProps> = ({
  label,
  content = (
    <>
      {label} <MenuCaret />
    </>
  ),
  items,
  menuProps,
  buttonProps = {},
}) => {
  const keyDownFromProps = buttonProps.onKeyDown,
    onKeyDown = React.useCallback(
      (e: React.KeyboardEvent<HTMLButtonElement>) => {
        // prevent misbehavior with <Grid /> focus management
        if (e.key.match(/up|down/i)) {
          e.stopPropagation()
        } else if (keyDownFromProps) {
          keyDownFromProps(e)
        }
      },
      [keyDownFromProps]
    )

  return (
    <Menu
      {...menuProps}
      menuButton={
        <MenuButton
          {...buttonProps}
          onKeyDown={onKeyDown}
          title={label}
          aria-label={label}
          className={buttonProps.className}
        >
          {content}
        </MenuButton>
      }
    >
      {renderMenuItems(items)}
    </Menu>
  )
}

export const MenuDots = Ellipsis
export const MenuCaret = () => <SortDown className="ml1" />
