import { generate } from "shortid"
import { ascending } from "lib"
import { Named, Tagged } from "./types"

export const any = (...args: boolean[]) => args.some(isTruthy)

export const all = (...args: boolean[]) => args.every(isTruthy)

export const none = (...args: boolean[]) => args.every(isFalsy)

const isTruthy = (value: boolean) => value

const isFalsy = (value: boolean) => !value

export function randomId() {
  return "lf-" + generate()
}

export function toggle<T>(array: T[], value: T) {
  return array.includes(value)
    ? array.filter((val) => val !== value)
    : [...array, value]
}

export function sortByName<T extends { name: string }>(a: T, b: T) {
  return ascending(a.name.toLowerCase(), b.name.toLowerCase())
}

export function sortThingsByName<T extends Named>(items: T[]) {
  return items.slice().sort(sortByName)
}

export function extractTags<T extends Named>(tagged: Tagged<T>[]): T[] {
  const tags: T[] = []

  tagged.forEach((tagged) => tags.push(...tagged.tags))

  return dedupeById(tags)
}

function dedupeById<T extends { id: string }>(items: T[]) {
  const used = new Set<string>()

  return items.filter((item) => {
    if (used.has(item.id)) return false
    used.add(item.id)
    return true
  })
}

export function pickManyById<T extends { id: string }>(
  items: T[],
  ids: string[]
) {
  return items.filter((item) => ids.includes(item.id))
}

export function sum(numbers: number[]) {
  return numbers.reduce((y, x) => y + x, 0)
}

export function groupify<T, K>(items: T[], keyFn: (item: T) => K): Map<K, T[]> {
  const grouped = new Map<K, T[]>()

  items.forEach((item) => {
    const key = keyFn(item)
    grouped.set(key, (grouped.get(key) || []).concat(item))
  })

  return grouped
}

export function formatSecondsAsTimeStamp(seconds: number) {
  const secPerHour = 60 * 60,
    hours = Math.floor(seconds / secPerHour),
    hrSec = hours * secPerHour,
    minutes = Math.floor((seconds - hrSec) / 60),
    minSec = minutes * 60,
    secSec = Math.floor(seconds - (hrSec + minSec)),
    pad = (num: number) => num.toString().padStart(2, "0")
  return pad(hours) + ":" + pad(minutes) + ":" + pad(secSec)
}

export function pick<T, K extends keyof T>(object: T, keys: K[]): Pick<T, K> {
  const copy = { ...object }

  Object.keys(copy).forEach((key) => {
    const k = key as K
    if (!keys.includes(k)) delete copy[k]
  })

  return copy
}

export function clamp(value: number, min: number, max: number) {
  return Math.max(Math.min(value, max), min)
}

export function indexById<T extends { id: string }>(items: T[]) {
  return new Map(items.map((item) => [item.id, item]))
}

export function toTextPercent(val: number) {
  return `${val * 100}%`
}
