import React from "react"
import clsx from "clsx"
import { cl } from "../../utils"
import { X } from "./Icons"

import "./Button.css"

export interface ButtonProps {
  label: string
  text?: string | null
  onClick?: React.MouseEventHandler
  onKeyDown?: React.KeyboardEventHandler
  icon?: React.ReactElement
  loading?: boolean
  disabled?: boolean
  className?: string
  submit?: boolean
  tabIndex?: number
  testId?: string
  tooltip?: string | boolean
  style?: React.CSSProperties
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const {
      label,
      text = label,
      loading,
      submit,
      disabled,
      className,
      icon,
      testId,
      tooltip,
      style,
      ...rest
    } = props
    return (
      <button
        ref={ref}
        data-testid={testId}
        {...rest}
        type={submit ? "submit" : "button"}
        aria-label={loading ? "Please wait..." : label}
        data-loading={loading ? true : undefined}
        style={{ ...style, color: loading ? "transparent" : undefined }}
        disabled={disabled || loading || undefined}
        className={clsx(className, "truncate", "btn")}
        title={typeof tooltip === "boolean" ? label : tooltip}
      >
        {icon ? (
          <span className="flex-shrink-0 flex items-center">{icon}</span>
        ) : null}

        <span>{text}</span>
        {loading ? (
          <div
            className="button-spinner"
            style={{
              borderColor: "transparent",
              borderLeftColor: "white",
            }}
          />
        ) : null}
      </button>
    )
  }
)

export const CloseButton: React.FC<{
  label: string
  onClick: () => unknown
  className?: string
  loading?: boolean
}> = ({ label, onClick, className, loading }) => (
  <Button
    label={label}
    text={null}
    icon={<X />}
    className={clsx(cl.btnGhost, className)}
    onClick={onClick}
    loading={loading}
  />
)
