import { gql } from "@apollo/client"
import { fragments } from "../../graphql-fragments"
import {
  CreateDoc,
  CreateDocVariables,
  UpdateDocs,
  UpdateDocsVariables,
  DeleteDocs,
  DeleteDocsVariables,
  ImportData,
  ImportDataVariables,
} from "../../types"
import { msg, Noun } from "../../msg"
import { mutationCommand, toMutationOptions } from "../command"
import { addProjectItem, removeProjectItems } from "../modify-project"
import { CANCEL_UPLOAD_EVENT, TabDataType } from "../../hooks"
import { dispatchCustomEvent } from "../../custom-events"

const CREATE_DOC = gql`
  mutation CreateDoc($projectId: ID!, $data: CreateDocInput!) {
    createDoc(projectId: $projectId, data: $data) {
      ...DocPreview
    }
  }

  ${fragments.docPreview}
`

export const createDoc = mutationCommand<
  CreateDocVariables["data"],
  CreateDoc,
  CreateDocVariables
>({
  messages: (_, { name }) => msg.create(Noun.Doc, name || "Untitled"),
  getMutationOptions: ({ route }, input) => ({
    mutation: CREATE_DOC,
    variables: {
      projectId: route.projectId,
      data: input,
    },
    update: (cache, { data }) => {
      if (!data) return
      addProjectItem({
        projectId: route.projectId,
        field: "docs",
        cache,
        fragment: fragments.docPreview,
        item: data.createDoc,
      })
    },
  }),
  sideEffect: ({ openTab }, _, output) => {
    openTab(output.createDoc.id, TabDataType.Doc)
  },
})

const UPDATE_DOCS = gql`
  mutation UpdateDocs($ids: [ID!]!, $data: UpdateDocInput!) {
    updateDocs(ids: $ids, data: $data) {
      id
      name
      description
      updatedAt
      tags {
        id
        name
      }
    }
  }
`

export const updateDocs = mutationCommand<UpdateDocsVariables, UpdateDocs>({
  getMutationOptions: toMutationOptions(UPDATE_DOCS),
  messages: () => msg.update(Noun.Doc),
})

const DELETE_DOCS = gql`
  mutation DeleteDocs($ids: [ID!]!) {
    deleteDocs(ids: $ids)
  }
`

export const deleteDoc = mutationCommand<
  { id: string; name: string },
  DeleteDocs,
  DeleteDocsVariables
>({
  messages: (_, { name }) => msg.delete(Noun.Doc, name),
  confirm: true,
  getMutationOptions: ({ route }, input) => ({
    mutation: DELETE_DOCS,
    variables: { ids: [input.id] },
    update: (cache) => {
      removeProjectItems({
        projectId: route.projectId,
        field: "docs",
        cache,
        ids: [input.id],
      })
    },
  }),
  sideEffect: (ctx, { id }) => {
    ctx.projectDispatch({
      type: "close-tab-if-exists",
      ids: [id],
      dataType: TabDataType.Doc,
    })
    dispatchCustomEvent(CANCEL_UPLOAD_EVENT, id)
  },
})

export const deleteDocs = mutationCommand<DeleteDocsVariables, DeleteDocs>({
  messages: (_, { ids }) => msg.delete(Noun.Doc, ids.length),
  confirm: true,
  getMutationOptions: ({ route }, input) => ({
    mutation: DELETE_DOCS,
    variables: input,
    update: (cache) => {
      removeProjectItems({
        projectId: route.projectId,
        field: "docs",
        cache,
        ids: input.ids,
      })
    },
  }),
  sideEffect: (ctx, { ids }) => {
    ctx.projectDispatch({
      type: "close-tab-if-exists",
      ids,
      dataType: TabDataType.Doc,
    })
    ids.forEach((id) => dispatchCustomEvent(CANCEL_UPLOAD_EVENT, id))
  },
})

const IMPORT_DATA = gql`
  mutation ImportData($projectId: ID!, $docs: [ImportDocInput!]!) {
    importData(projectId: $projectId, docs: $docs)
  }
`

// TODO: this command could return imported data
// instead of requiring refetch of whole project
export const importDocs = mutationCommand<
  Pick<ImportDataVariables, "docs">,
  ImportData,
  ImportDataVariables
>({
  messages: (_, { docs }) => msg.create(Noun.Doc, docs.length),
  getMutationOptions: ({ route: { projectId } }, input) => ({
    mutation: IMPORT_DATA,
    variables: { ...input, projectId },
    refetchQueries: ["GetProjectData"],
    awaitRefetchQueries: true,
  }),
})
