import { gql } from "@apollo/client"
import { fragments } from "./graphql-fragments"

export const GET_PROJECT = gql`
  query GetProjectData($projectId: ID!) {
    getProject(id: $projectId) {
      id
      name
      description
      docs {
        ...DocPreview
      }
      codes {
        ...CodePreview
      }
    }
  }

  ${fragments.docPreview}
  ${fragments.codePreview}
`

export const GET_DOCS = gql`
  query GetDocs($projectId: ID!) {
    getProject(id: $projectId) {
      id
      docs {
        ...DocPreview
      }
    }
  }

  ${fragments.docPreview}
`

export const GET_CODES = gql`
  query GetCodes($projectId: ID!) {
    getProject(id: $projectId) {
      id
      codes {
        ...CodePreview
      }
    }
  }

  ${fragments.codePreview}
`

export const GET_DOC_DETAIL = gql`
  query GetDocDetail($id: ID!) {
    getDoc(id: $id) {
      ...DocDetail
    }
  }

  ${fragments.docDetail}
`

export const GET_CODE = gql`
  query GetCode($id: ID!) {
    getCode(id: $id) {
      ...CodePreview
      description
    }
  }

  ${fragments.codePreview}
`

export const WATCH_CHANGES = gql`
  subscription ProjectChanges($projectId: ID!) {
    subscribeToProjectChanges(id: $projectId) {
      id
      eventName
      entityType
      entityIds
      clientId
    }
  }
`

export const ACCOUNT_DATA = gql`
  query AccountData {
    getUser {
      id
      name
      email
      createdAt
    }
  }
`
