import React from "react"
import {
  useMutation,
  useSubscription,
  useApolloClient,
  ApolloError,
} from "@apollo/client"
import { collab, makeTransactionBypassEditPlugin } from "editor"
import { getEnv, getStepsFromJSON } from "lib"
import { useProsemirror } from "../base"
import { DOC_CHANGES, GET_TEXT, SYNC_DOC } from "./graphql"
import { SyncErrorPopup } from "./SyncErrorPopup"
import {
  SyncDoc,
  SyncDocVariables,
  DocChanges,
  DocChangesVariables,
  GetText,
  GetTextVariables,
} from "../../utils"

export interface SyncProps {
  docId: string
  debug?: (msg: string) => void
  onReload: () => void
}

export const DocSyncer: React.FC<SyncProps> = ({
  docId,
  debug = myDebug,
  onReload,
}) => {
  const { view } = useProsemirror(),
    client = useApolloClient(),
    didInitialSync = React.useRef(false),
    getVersion = () => (view ? collab.getVersion(view.state) : -1)

  const { data: latest } = useSubscription<DocChanges, DocChangesVariables>(
    DOC_CHANGES,
    { variables: { docId } }
  )

  const [mutate, { loading: syncing, error }] = useMutation<
    SyncDoc,
    SyncDocVariables
  >(SYNC_DOC)

  const sync = (variables: SyncDocVariables) => {
    debug("[SYNC] Start")
    mutate({ variables })
      .then(({ data }) => {
        setLastSyncTime(docId)
        if (!view || !data) return
        const tr = collab.receiveTransaction(
          view.state,
          getStepsFromJSON(data.syncDoc.steps),
          data.syncDoc.clientIds
        )
        view.dispatch(makeTransactionBypassEditPlugin(tr))
        debug(`[SYNC] OK - ${data.syncDoc.status}`)
        client.writeQuery<GetText, GetTextVariables>({
          query: GET_TEXT,
          variables: { docId },
          data: {
            connectToDoc: {
              id: docId,
              version: getVersion(),
              text: view.state.doc.toJSON(),
              __typename: "DocText",
            },
          },
        })
      })
      .catch((err) => {
        debug(`[SYNC] Error - ${err.message}`)
      })
  }

  React.useEffect(() => {
    if (syncing || !view || error) return

    const version = getVersion(),
      sendable = collab.sendableSteps(view.state)

    if (latest) {
      const serverVersion = latest.subscribeToDocChanges.version

      if (version < serverVersion) {
        return sync({ docId, version })
      }
    }

    if (sendable) {
      const { version, steps } = sendable
      return sync({ docId, version, steps })
    }

    if (!didInitialSync.current) {
      sync({ docId, version })
      didInitialSync.current = true
    }
  })

  if (reloadRequired(error)) {
    return (
      <SyncErrorPopup
        message={
          <>
            <b>Oops!</b> We need to reload this document.
          </>
        }
        action="Reload"
        onClick={onReload}
      />
    )
  } else if (error) {
    return (
      <SyncErrorPopup
        message={
          <>
            <b>Uh-oh!</b> We can't save your changes right now.
          </>
        }
        action="Reconnect"
        onClick={() => sync({ docId, version: getVersion() })}
        loading={syncing}
      />
    )
  } else {
    return null
  }
}

function reloadRequired(error?: ApolloError) {
  //@ts-expect-error no result
  const result = error?.networkError?.result

  if (result && result.errors && result.errors.length) {
    const message = result.errors[0].message as string
    return message.match(/version|instance/i)
  } else {
    return null
  }
}

function myDebug(msg: string) {
  if (getEnv() === "development") {
    console.info(msg)
  }
}

function getSyncKey(docId: string) {
  return `sync:${docId}`
}

export function setLastSyncTime(docId: string) {
  sessionStorage.setItem(getSyncKey(docId), Date.now().toString())
}

export function getLastSyncTime(docId: string) {
  const t = sessionStorage.getItem(getSyncKey(docId))
  return t ? parseInt(t) : Date.now()
}
