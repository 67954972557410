import React from "react"
import clsx from "clsx"
import Modal, { Props } from "react-modal"
import { RemoveScroll } from "react-remove-scroll"
import { CloseButton } from "./Button"
import { useDialog } from "../../utils"

type DialogProps = Omit<Props, "isOpen">

export const Dialog: React.FC<DialogProps> = ({ children, ...rest }) => {
  const { hide } = useDialog()
  return (
    <WirelessDialog {...rest} onClose={hide}>
      {children}
    </WirelessDialog>
  )
}

export const DIALOG_PORTAL = "relative z-2 overflow-hidden"
export const DIALOG_OVERLAY =
  "bg-black-40 fixed top-0 left-0 h-100 w-100 flex items-start justify-center overflow-auto dialog-overlay"
export const DIALOG_CONTENT =
  "relative bg-near-white ba b--black-20 shadow-1 ph4 pb4 br1 ma1 w-100 ma5-ns mw7-ns"

export const WirelessDialog: React.FC<DialogProps & { onClose: () => void }> =
  ({ children, onClose, ...rest }) => {
    return (
      <RemoveScroll>
        <Modal
          isOpen
          {...rest}
          portalClassName={clsx(DIALOG_PORTAL, rest.portalClassName)}
          overlayClassName={clsx(DIALOG_OVERLAY, rest.overlayClassName)}
          className={clsx(DIALOG_CONTENT, rest.className)}
          onRequestClose={onClose}
          style={{ overlay: { animation: "fade-in 0.25s" } }}
        >
          <CloseButton
            label="Close dialog"
            className="absolute top-0 right-0 ma2"
            onClick={onClose}
          />
          {children}
        </Modal>
      </RemoveScroll>
    )
  }
