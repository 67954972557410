import clsx from "clsx"

interface GenericProps {
  className?: string
  style?: React.CSSProperties
}

export const ChartContainer: React.FC<GenericProps> = ({
  children,
  className,
  style,
}) => {
  return (
    <div
      className={clsx(
        "br1 bg-light-gray ba b--black-10 mv4 mh2 mh0-ns",
        className
      )}
      style={style}
    >
      {children}
    </div>
  )
}

export const ChartDataLabel: React.FC<GenericProps> = ({
  children,
  className,
  style,
}) => {
  return (
    <div className={clsx("fw6 f6 dark-gray mb1", className)} style={style}>
      {children}
    </div>
  )
}
