import { ApolloClient, DocumentNode, MutationOptions } from "@apollo/client"
import { ProjectDispatch, TabDataType } from "../hooks"
import { Messages } from "../msg"
import { History } from "history"

export interface Command<I, O> {
  execute: WithInput<I, O | Promise<O>>
  messages?: (ctx: CommandContext, input: I) => Partial<Messages>
  sideEffect?: (ctx: CommandContext, input: I, output: O) => void
  errorRedirect?: (
    ctx: CommandContext,
    input: I,
    error: Error
  ) => string | null | undefined
  confirm?: boolean
}

type WithInput<I, T> = (ctx: CommandContext, input: I) => T

interface CommandContext {
  client: ApolloClient<unknown>
  route: RouteParams
  projectDispatch: ProjectDispatch
  openTab: (id: string, dataType: TabDataType) => void
  history: History
}

export interface RouteParams {
  projectId: string
  codeId: string
  docId: string
}

type MutationCommandParams<V, I, O> = Omit<Command<I, O>, "execute"> & {
  getMutationOptions: WithInput<I, MutationOptions<O, V>>
}

export function mutationCommand<I, O, V = I>({
  getMutationOptions,
  ...options
}: MutationCommandParams<V, I, O>) {
  const command: Command<I, O> = {
    ...options,
    execute: async (ctx, input) => {
      const { data, errors } = await ctx.client.mutate<O, V>(
        getMutationOptions(ctx, input)
      )
      if (errors?.length) throw errors[0]
      return data as O
    },
  }

  return command
}

export function toMutationOptions<I, O>(
  mutation: DocumentNode
): WithInput<I, MutationOptions<O, I>> {
  return (_, input) => ({
    mutation,
    variables: input,
  })
}
