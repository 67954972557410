import React from "react"
import { Named, Tagged } from "../types"

export function useItemsFilteredById<T extends { id: string }>(
  items: T[],
  ids: string[]
) {
  return React.useMemo(() => {
    return items.filter((item) => {
      if (!ids.length) return true
      return ids.includes(item.id)
    })
  }, [items, ids])
}

export function useItemsFilteredByTags<T extends Tagged<Named>>(
  items: T[],
  tagIds: string[]
) {
  return React.useMemo(() => {
    return items.filter((item) => {
      if (!tagIds.length) return true
      const itemTagIds = item.tags.map((tag) => tag.id)
      return tagIds.every((tagId) => itemTagIds.includes(tagId))
    })
  }, [items, tagIds])
}
