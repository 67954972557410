import { cl } from "../../utils"
import { Dialog, DialogH2, P } from "../base"

export const MediaHelpDialog: React.FC = () => {
  return (
    <Dialog>
      <DialogH2>Working with Audio</DialogH2>
      <P>Keyboard shortcuts:</P>
      <ShortCut keys={["Alt", "p"]} label="Pause/Play" />
      <ShortCut keys={["Alt", "←"]} label="Back 5 seconds" />
      <ShortCut keys={["Alt", "→"]} label="Ahead 5 seconds" />
      <ShortCut keys={["Alt", "↑"]} label="Increase playback speed" />
      <ShortCut keys={["Alt", "↓"]} label="Decrease playback speed" />
      <ShortCut keys={["Alt", "i"]} label="Insert current timestamp" />
      <P>
        <b>Tip:</b> typing a time followed by a space (e.g., "1:02:15 ") will
        automatically create a timestamp. Press the backspace key to undo.
      </P>
    </Dialog>
  )
}

const ShortCut: React.FC<{ keys: string[]; label: string }> = ({
  keys,
  label,
}) => {
  return (
    <div className="flex items-center">
      <div className="w4">
        <KeyboardShortcut keys={keys} />
      </div>
      <div className={cl.p}>{label}</div>
    </div>
  )
}

const COMBO_TOKEN = "+"

const KeyboardShortcut: React.FC<{ keys: string[] }> = ({ keys }) => {
  const tokens = keys.reduce((tokens, key, i) => {
    if (i < keys.length - 1) {
      return [...tokens, key, COMBO_TOKEN]
    } else {
      return [...tokens, key]
    }
  }, [] as string[])

  return (
    <>
      {tokens.map((token, i) => (
        <KeyToken key={i} token={token} />
      ))}
    </>
  )
}

const KeyToken: React.FC<{ token: string }> = ({ token }) => {
  if (token === COMBO_TOKEN) {
    return <span className="mh2">{token}</span>
  } else {
    return (
      <span className="pa2 bg-white br2 ba b--black-20 courier">{token}</span>
    )
  }
}
