import { isEditable, disableEditing, enableEditing } from "editor"
import { Pencil } from "../base"
import { useViewOrFail, ProseMirrorButton } from "./utils"

export const ToggleEditButton: React.FC = () => {
  const view = useViewOrFail(),
    editable = isEditable(view.state)
  return (
    <ProseMirrorButton
      icon={<Pencil />}
      isActive={() => editable}
      execute={editable ? disableEditing : enableEditing}
      label={editable ? "Disable editing" : "Enable editing"}
      className="mr3"
    />
  )
}
