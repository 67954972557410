import React from "react"
import clsx from "clsx"
import {
  cl,
  DEFAULT_CODE_COLOR,
  Named,
  TabDataType,
  useCodes,
  useDialog,
  useItemsFilteredByTags,
  useProjectState,
} from "../utils"
import {
  ListGrid,
  ListGridCellsBaseProps,
  Plus,
  Spinner,
  SquareFill,
  ActionMenu,
  Ellipsis,
} from "./base"
import { useCodeMenuItems } from "./CodeMenu"
import { HoverLink, HOVER_CODE } from "./HoverLink"
import { CodeCreate } from "./dialogs"
import { SidebarPlaceholder } from "./Project"
import { SidebarFilterMenu } from "./SidebarFilterMenu"

export const CodeList = () => {
  const { codes, loading } = useCodes(),
    menus = useCodeMenuItems(),
    { filterTagIds } = useProjectState().sidebar,
    listCodes = useItemsFilteredByTags(codes, filterTagIds)

  if (loading) return <Spinner />
  if (!codes.length)
    return <SidebarPlaceholder>No codes yet.</SidebarPlaceholder>
  if (!listCodes.length)
    return <SidebarPlaceholder>No matching codes.</SidebarPlaceholder>

  return (
    <ListGrid
      items={listCodes}
      columns={[
        {
          component: CodeLink,
        },
        {
          component: CodeMenu,
        },
      ]}
      selectable
      menus={menus}
      className="overflow-auto flex-grow-1 bg-near-white pb5"
      rowClassName="pl4 pv1 f5 hide-child flex items-center justify-between"
      cellClassName="pv1"
    />
  )
}

type CellProps = ListGridCellsBaseProps<Named & { color?: string }>

const CodeLink = React.memo<CellProps>(
  ({ data: { id, name, color }, ...rest }) => (
    <div className="flex items-center truncate">
      <SquareFill
        style={{ color: color || DEFAULT_CODE_COLOR }}
        className="mr2 flex-shrink-0"
      />
      <HoverLink
        id={id}
        name={name}
        dataType={TabDataType.Code}
        query={HOVER_CODE}
        offsetX={5}
        offsetY={20}
        {...rest}
      />
    </div>
  )
)

const CodeMenu = React.memo<CellProps>(({ data, ...rest }) => {
  const { single } = useCodeMenuItems(false)
  return (
    <ActionMenu
      label={`Actions for ${data.name}`}
      items={single(data)}
      menuProps={{ portal: true }}
      content={<Ellipsis />}
      buttonProps={{
        ...rest,
        className: clsx(cl.btnGhost, "pv2 child mh2"),
      }}
    />
  )
})

const NewCodeButton: React.FC = () => {
  const { show } = useDialog(),
    label = "Create code"
  return (
    <button
      aria-label={label}
      title={label}
      className={clsx(cl.btnGhost, "f4")}
      onClick={() => show(CodeCreate, {})}
    >
      <Plus />
    </button>
  )
}

export const CodeTools = () => {
  const { tags } = useCodes()

  return (
    <div className="flex items-center">
      <NewCodeButton />
      <SidebarFilterMenu tags={tags} className="mh2" />
    </div>
  )
}
