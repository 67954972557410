import clsx from "clsx"
import React from "react"
import {
  ToastContext,
  ToastContextValue,
  ToastSpec,
  useToast,
  ToastType,
} from "../utils"
import { CloseButton } from "./base"

export const ToastProvider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<ToastSpec[]>([]),
    addToast = React.useCallback(
      (spec: ToastSpec) => setState((state) => [...state, spec]),
      []
    ),
    dismissToast = React.useCallback((id: string) => {
      setState((state) => state.filter((state) => state.id !== id))
    }, []),
    value: ToastContextValue = React.useMemo(
      () => ({
        toasts: state,
        addToast,
        dismissToast,
      }),
      [state, addToast, dismissToast]
    )

  return (
    <ToastContext.Provider value={value}>
      <Toaster toasts={state} />
      {children}
    </ToastContext.Provider>
  )
}

const Toaster: React.FC<{ toasts: ToastSpec[] }> = ({ toasts }) => (
  <div className="fixed bottom-0 left-0 flex-column flex-reverse ph2 ph3-ns z-3 w-100 w-30-ns">
    {toasts.map((toast) => (
      <Toast key={toast.id} {...toast} />
    ))}
  </div>
)

const TOAST_SHOW = 5000

const Toast = React.memo<ToastSpec>(({ id, message, type }) => {
  const { dismissToast } = useToast()

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      dismissToast(id)
    }, TOAST_SHOW)

    return () => clearTimeout(timeout)
  }, [dismissToast, id])
  return (
    <div
      style={{ animation: "slide-up 0.25s" }}
      className={clsx(
        "toast relative pa3 dark-gray ba br1 shadow-1 w-100 mv2 mv3-ns f5 fw4 flex items-center justify-between",
        getTypeStyles(type)
      )}
    >
      <span>{message}</span>
      <CloseButton
        label="Dismiss"
        className="ml3 flex-shrink-0"
        onClick={() => dismissToast(id)}
      />
    </div>
  )
})

function getTypeStyles(type: ToastType) {
  switch (type) {
    case "error":
      return "b--dark-red bg-washed-red"
    case "warn":
      return "b--gold bg-washed-yellow"
    default:
      return "b--dark-blue bg-washed-blue"
  }
}
