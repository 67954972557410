import clsx from "clsx"
import React from "react"
import { Helmet } from "react-helmet"

export const CenteredPage: React.FC = ({ children }) => (
  <div className="vh-100 vw-100 bg-near-white flex items-start justify-center pv5 ph2">
    <div style={{ width: 500 }}>{children}</div>
  </div>
)

export const PageTitle: React.FC = (props) => (
  <Helmet>
    <title>Leaf - {props.children}</title>
  </Helmet>
)

export const Footer: React.FC<{ className?: string }> = ({
  children,
  className,
}) => <div className={clsx("flex justify-end mt3", className)}>{children}</div>

export const FullWidthLayout: React.FC = ({ children }) => {
  return <div className="ph3 ph4-ns ph5-l mw9-l mw-100 center">{children}</div>
}

export const Narrow: React.FC = ({ children }) => {
  return <div className="ph3 center mw6 mb5">{children}</div>
}

export const ContentPageContainer: React.FC<{ className?: string }> = ({
  children,
  className,
}) => {
  return (
    <div className={clsx("mw9-l center-l", className)}>
      <div className={clsx("w-100 w-auto-ns ml5-ns mw7-l", className)}>
        {children}
      </div>
    </div>
  )
}
