import React from "react"
import {
  defaultSidebarState,
  SidebarAction,
  sidebarReducer,
  SidebarState,
} from "./project-state-sidebar"
import {
  defaultTabsState,
  TabAction,
  tabsReducer,
  TabsState,
} from "./project-state-tabs"

export interface ProjectState extends TabsState {
  sidebar: SidebarState
}

export const defaultProjectState: ProjectState = {
  sidebar: defaultSidebarState,
  ...defaultTabsState,
}

export type Action = SidebarAction | TabAction

export function projectReducer(
  state: ProjectState,
  action: Action
): ProjectState {
  if (action.type.includes("tab") && !action.type.includes("sidebar")) {
    return {
      sidebar: state.sidebar,
      ...tabsReducer(state, action as TabAction),
    }
  } else {
    return {
      ...state,
      sidebar: sidebarReducer(state.sidebar, action as SidebarAction),
    }
  }
}

export const ProjectStateContext =
  React.createContext<ProjectState>(defaultProjectState)

export type ProjectDispatch = React.Dispatch<Action>

export const ProjectDispatchContext =
  //@ts-expect-error null ctx
  React.createContext<ProjectDispatch>(null)

export function useProjectState() {
  return React.useContext(ProjectStateContext)
}

export function useProjectDispatch() {
  return React.useContext(ProjectDispatchContext)
}
