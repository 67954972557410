import React from "react"
import { ClientRoute } from "lib"
import { cl, useExec, signIn } from "../utils"
import {
  P,
  RouterLink,
  CenteredPage,
  H1PageTitle,
  Form,
  Field,
  yup,
} from "./base"
import { AuthFooter } from "./AuthFooter"

export const SignIn = () => {
  const exec = useExec()

  return (
    <CenteredPage>
      <H1PageTitle>Sign in to Leaf</H1PageTitle>
      <Form
        values={{ email: "", password: "" }}
        schema={yup.object({
          email: yup.string().email("invalid email").required("missing email"),
          password: yup.string().required("missing password"),
        })}
        submitText="Sign In"
        classNames={{ button: cl.btn }}
        onSubmit={(values) => exec(signIn, values)}
      >
        <Field name="email" label="Email Address" type="email" />
        <Field name="password" label="Password" type="password" />
        <div className="tr">
          <RouterLink
            to={ClientRoute.ForgotPassword}
            className="f6 relative"
            style={{ top: "-1.5rem" }}
          >
            Forgot password?
          </RouterLink>
        </div>

        <AuthFooter />
      </Form>

      <P className="tc b">
        New to Leaf? <RouterLink to={ClientRoute.SignUp}>Sign up</RouterLink>.
      </P>
    </CenteredPage>
  )
}
