import React from "react"
import { CodePaletteInput } from "./Input"
import { ListGrid } from "../base"
import { CodeLink, CodePaletteToggleButton } from "./ToggleButton"
import { useCodes } from "../../utils"
import { useCodeMenuItems } from "../CodeMenu"

export const CodePalette = React.memo(() => {
  const { codes } = useCodes(),
    [search, setSearch] = React.useState(""),
    filteredCodes = React.useMemo(() => {
      return codes.filter((code) => {
        return code.name.toLowerCase().includes(search.toLowerCase())
      })
    }, [codes, search]),
    allowCreate = !filteredCodes.length,
    menus = useCodeMenuItems()

  return (
    <>
      <div className="flex bb b--black-20 bg-white">
        <CodePaletteInput
          value={search}
          onChange={setSearch}
          allowCreate={allowCreate}
        />
      </div>
      <ListGrid
        items={filteredCodes}
        columns={[
          {
            component: CodePaletteToggleButton,
          },
          {
            component: CodeLink,
          },
        ]}
        selectable
        menus={menus}
        className="relative overflow-auto flex-grow-1 bg-near-white pb5"
        rowClassName="pl3 f5 flex items-center pv1"
      />
    </>
  )
})
