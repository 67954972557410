import React from "react"
import { getEnv } from "lib"
import {
  AuthContext,
  getToken,
  AccessToken,
  AUTH_CHANGE_EVENT,
  useCustomEventHandler,
} from "../utils"
import { AppProtected } from "./AppProtected"
import { AppPublic } from "./AppPublic"
import { Spinner } from "./base"

export const AuthProvider: React.FC = () => {
  const [accessToken, setAccessToken] = React.useState<AccessToken>()

  useCustomEventHandler<AccessToken>(AUTH_CHANGE_EVENT, setAccessToken)

  React.useEffect(() => {
    // on load, makes initial request to determine auth status
    updateTokenWithErrorHandling(setAccessToken)
  }, [])

  return (
    <AuthContext.Provider value={accessToken}>
      {accessToken === undefined ? (
        <Spinner />
      ) : accessToken === null ? (
        <AppPublic />
      ) : (
        <AppProtected />
      )}
    </AuthContext.Provider>
  )
}

function updateTokenWithErrorHandling(setToken: (token: AccessToken) => void) {
  getToken()
    .then(setToken)
    .catch((err) => {
      if (getEnv() !== "production") {
        console.error(err)
      }
    })
}
