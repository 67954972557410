import clsx from "clsx"
import React from "react"
import { cl } from "../../utils"
import { PageTitle } from "./layout"

export const H1 = React.forwardRef<
  HTMLHeadingElement,
  React.HTMLProps<HTMLHeadingElement>
>((props, ref) => (
  <h1 {...props} ref={ref} className={clsx(cl.h1, props.className)}>
    {props.children}
  </h1>
))

export const H1PageTitle: React.FC<{ className?: string }> = ({
  children,
  className,
}) => (
  <>
    <PageTitle>{children}</PageTitle>
    <H1 className={className}>{children}</H1>
  </>
)

export const H2 = React.forwardRef<
  HTMLHeadingElement,
  React.HTMLProps<HTMLHeadingElement>
>((props, ref) => (
  <h2 {...props} ref={ref} className={clsx(cl.h2, props.className)}>
    {props.children}
  </h2>
))

const DIALOG_H2_CLASS = cl.h2.replace("mt5", "mt4").replace("mb3", "mb4")

export const DialogH2 = React.forwardRef<
  HTMLHeadingElement,
  React.HTMLProps<HTMLHeadingElement>
>((props, ref) => (
  <h2 {...props} ref={ref} className={clsx(DIALOG_H2_CLASS, props.className)}>
    {props.children}
  </h2>
))

export const H3 = React.forwardRef<
  HTMLHeadingElement,
  React.HTMLProps<HTMLHeadingElement>
>((props, ref) => (
  <h3 {...props} ref={ref} className={clsx(cl.h3, props.className)}>
    {props.children}
  </h3>
))

export const P = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLProps<HTMLParagraphElement>
>((props, ref) => (
  <p {...props} ref={ref} className={clsx(cl.p, props.className)}>
    {props.children}
  </p>
))

export const Paragraphs: React.FC<{ className?: string; text: string }> = ({
  className,
  text,
}) => {
  return (
    <>
      {text.split(/[\n\r]+/).map((paragraph, i) => (
        <P className={className} key={i}>
          {paragraph}
        </P>
      ))}
    </>
  )
}
