import React from "react"
import { ActionMenu, action, DIVIDER, Gear } from "./base"
import {
  useDialog,
  cl,
  useExec,
  deleteDoc,
  Named,
  deleteDocs,
  useOpenTab,
  TabDataType,
} from "../utils"
import { DocsUpdate, DocUpdate } from "./dialogs"
import clsx from "clsx"
import { AddMediaDialog } from "./AddMediaDialog"

export interface DocMenuProps {
  id: string
  name: string
  buttonProps?: React.HTMLAttributes<HTMLButtonElement>
  canOpen?: boolean
}

export const DocMenu = React.memo<DocMenuProps>(
  ({ id, name, buttonProps = {}, canOpen }) => {
    const { single } = useDocMenuItems(canOpen)

    return (
      <ActionMenu
        menuProps={{ direction: "bottom", align: "end" }}
        buttonProps={{
          ...buttonProps,
          className: clsx(cl.btnGhost, "f4 mid-gray", buttonProps.className),
        }}
        label={`Actions for ${name}`}
        content={<Gear />}
        items={single({ id, name })}
      />
    )
  }
)

export function useDocMenuItems(canOpen = true) {
  const exec = useExec(),
    { show } = useDialog(),
    open = useOpenTab(),
    single = React.useCallback(
      ({ id, name }: Named) => {
        return [
          canOpen && action("Open", () => open(id, TabDataType.Doc)),
          action("Edit Details", () => show(DocUpdate, { id })),
          action("Add/Remove Audio", () => show(AddMediaDialog, { docId: id })),
          DIVIDER,
          action("Delete", () => exec(deleteDoc, { id, name })),
        ]
      },
      [exec, show, open, canOpen]
    ),
    multiple = React.useCallback(
      (items: Named[]) => {
        const ids = items.map((item) => item.id)
        return [
          action("Edit Multiple", () => show(DocsUpdate, { ids })),
          DIVIDER,
          action("Delete", () => exec(deleteDocs, { ids })),
        ]
      },
      [exec, show]
    )

  return React.useMemo(() => ({ single, multiple }), [single, multiple])
}
