import { SomeObject } from "lib"
import { cl, useDialog } from "../../utils"
import { Form, FormProps } from "./Form"
import { Spinner } from "./Spinner"
import { Alert } from "./Alert"
import { Dialog } from "./Dialog"
import { DialogH2 } from "./typography"

export interface DialogFormProps<T extends SomeObject> extends FormProps<T> {
  label: string
  loading?: boolean
  error?: boolean
}

export function DialogForm<T extends SomeObject>({
  label,
  loading,
  onSubmit,
  error,
  ...rest
}: DialogFormProps<T>) {
  const { hide } = useDialog()
  return (
    <Dialog contentLabel={label}>
      <DialogH2>{label}</DialogH2>
      {error ? (
        <Alert className="mv3 pa3">
          Something went wrong. Sorry about that.
        </Alert>
      ) : loading ? (
        <Spinner />
      ) : (
        <Form
          classNames={{ button: cl.btn }} // a sensible default
          {...rest}
          onSubmit={async (values) => {
            await onSubmit(values)
            hide()
          }}
        />
      )}
    </Dialog>
  )
}
