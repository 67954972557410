import React from "react"
import clsx from "clsx"
import {
  StoredTabData,
  TabDataType,
  TabState,
  TAB_RENDER_CONTAINER,
  useProjectState,
  useTabData,
} from "../../utils"
import { Code } from "../Code"
import { Doc } from "../Doc"
import { tabWidgets } from "./TabWidgets"

export const TabRenderer: React.FC<TabState> = ({ id, dataType }) => {
  const Widget = tabWidgets[id],
    key = [id, dataType].join("-"),
    { scrollRef, onScroll } = useScrollRestoration()
  return (
    <div
      className={clsx(
        "flex-grow-1 overflow-auto relative",
        TAB_RENDER_CONTAINER
      )}
      ref={scrollRef}
      onScroll={onScroll}
    >
      {dataType === TabDataType.Doc ? (
        <Doc id={id} key={key} />
      ) : dataType === TabDataType.Code ? (
        <Code id={id} key={key} />
      ) : (
        <Widget key={key} />
      )}
    </div>
  )
}

const ATTEMPT_SCROLL_DELAY = 250
const MAX_ATTEMPTS = 12

function useScrollRestoration() {
  const { tabs, activeTabIndex } = useProjectState(),
    { id, dataType } = tabs[activeTabIndex],
    scrollRef = React.useRef<HTMLDivElement>(null),
    tabData = useTabData(),
    onScroll = React.useCallback(
      (e: React.UIEvent) => {
        tabData.write(
          id,
          dataType,
          StoredTabData.ScrollTop,
          e.currentTarget.scrollTop
        )
      },
      [tabData, id, dataType]
    ),
    // "smart" restoration: wait for possible scrolltop to exist
    [attempts, setAttempts] = React.useState(0),
    attemptTimeout = React.useRef(-1),
    prevRef = React.useRef(
      tabData.read<number>(id, dataType, StoredTabData.ScrollTop)
    )

  React.useEffect(() => {
    prevRef.current = tabData.read<number>(
      id,
      dataType,
      StoredTabData.ScrollTop
    )
    clearTimeout(attemptTimeout.current)
    setAttempts(0)
  }, [tabData, id, dataType])

  React.useEffect(() => {
    const div = scrollRef.current,
      prevScrollTop = prevRef.current || 0

    if (!div || prevScrollTop < 0) {
      return
    }

    if (prevScrollTop === 0) {
      div.scrollTo(0, 0)
    } else if (
      prevScrollTop > div.scrollHeight - div.clientHeight &&
      attempts < MAX_ATTEMPTS
    ) {
      attemptTimeout.current = window.setTimeout(
        () => setAttempts(attempts + 1),
        ATTEMPT_SCROLL_DELAY
      )
    } else {
      div.scrollTo(0, prevScrollTop)
    }
  }, [id, dataType, attempts, setAttempts])

  React.useEffect(() => {
    return () => {
      clearTimeout(attemptTimeout.current)
    }
  }, [])

  return { scrollRef, onScroll }
}
