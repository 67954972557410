import React from "react"
import { CollabEditor } from "../CollabEditor"
import { MaybeClippedCodeRange } from "../CodeStripes"
import { createFilterPlugin, setFilterPluginState } from "editor"
import { CodeRange, collapseAdjacentRanges, computeEdgeRanges } from "lib"
import { PassagesToolbar } from "./PassagesToolbar"
import { gql, useQuery } from "@apollo/client"
import { GetDocMedia, GetDocMediaVariables, groupify } from "../../utils"
import { useProsemirror } from "../base"
import { useReportStripeMenuItems } from "../StripeMenu"

const GET_MEDIA_AVAILABILITY = gql`
  query GetDocMedia($id: ID!) {
    getDoc(id: $id) {
      id
      media {
        id
        available
      }
    }
  }
`

export const DocPassages: React.FC<
  {
    docId: string
  } & PassageFilterUpdaterProps
> = ({ docId, codeIds, showIntersectionsOnly }) => {
  const { data } = useQuery<GetDocMedia, GetDocMediaVariables>(
    GET_MEDIA_AVAILABILITY,
    {
      variables: { id: docId },
    }
  )

  const [forceUpdateTime, setForceUpdateTime] = React.useState(Date.now())

  const mapRanges = React.useCallback(
    (ranges: MaybeClippedCodeRange[]) => {
      if (forceUpdateTime) {
        // hax
      }

      const froms = new Set<string>(),
        tos = new Set<string>(),
        toKey = (codeId: string, pos: number) => `${codeId}-${pos}`

      ranges.forEach((range) => {
        froms.add(toKey(range.codeId, range.from))
        tos.add(toKey(range.codeId, range.to))
      })

      const filteredRanges: CodeRange[] = []

      computeEdgeRanges(ranges)
        .filter((range) => {
          if (showIntersectionsOnly) {
            return codeIds.every((id) => range.codeIds.includes(id))
          } else {
            return codeIds.some((id) => range.codeIds.includes(id))
          }
        })
        .forEach(({ from, to, codeIds }) => {
          codeIds.forEach((codeId) => {
            filteredRanges.push({
              from,
              to,
              codeId,
            })
          })
        })

      const byCode = groupify(filteredRanges, (range) => range.codeId)

      const truncatedRanges: MaybeClippedCodeRange[] = []

      byCode.forEach((value, codeId) => {
        collapseAdjacentRanges(value).forEach((range) => {
          truncatedRanges.push({
            ...range,
            codeId,
            clipFrom: !froms.has(toKey(codeId, range.from)),
            clipTo: !tos.has(toKey(codeId, range.to)),
          })
        })
      })

      return truncatedRanges
    },
    [codeIds, showIntersectionsOnly, forceUpdateTime]
  )

  return (
    <CollabEditor
      key={docId} // prevent component from updating with data for different doc
      docId={docId}
      hasMedia={data?.getDoc.media?.available}
      plugins={[
        createFilterPlugin({ codeIds, active: true, showIntersectionsOnly }),
      ]}
      mapRanges={mapRanges}
      ToolbarComponent={PassagesToolbar}
      useStripeMenuItems={useReportStripeMenuItems}
    >
      <FilterUpdater
        codeIds={codeIds}
        showIntersectionsOnly={showIntersectionsOnly}
        setForceUpdateTime={setForceUpdateTime}
      />
    </CollabEditor>
  )
}

export interface PassageFilterUpdaterProps {
  codeIds: string[]
  showIntersectionsOnly: boolean
}

const FilterUpdater: React.FC<
  PassageFilterUpdaterProps & { setForceUpdateTime: (time: number) => void }
> = ({ codeIds, showIntersectionsOnly, setForceUpdateTime }) => {
  const { viewRef } = useProsemirror()

  React.useEffect(() => {
    const view = viewRef.current
    if (!view) return
    setFilterPluginState({ codeIds, active: true, showIntersectionsOnly })(
      view.state,
      view.dispatch
    )
    setForceUpdateTime(Date.now())
  }, [codeIds, viewRef, showIntersectionsOnly, setForceUpdateTime])

  return null
}
