import { gql } from "@apollo/client"
import { UpdateUser, UpdateUserVariables } from "../../types"
import { msg, Noun } from "../../msg"
import { mutationCommand, toMutationOptions } from "../command"

const UPDATE_USER = gql`
  mutation UpdateUser($name: String!) {
    updateUser(data: { name: $name }) {
      id
      name
    }
  }
`

export const updateUser = mutationCommand<UpdateUserVariables, UpdateUser>({
  messages: () => msg.update("profile"),
  getMutationOptions: toMutationOptions(UPDATE_USER),
})

const DELETE_USER = gql`
  mutation DeleteUser {
    deleteUser
  }
`

export const deleteAccount = mutationCommand({
  messages: () => msg.delete(Noun.Account),
  confirm: false,
  getMutationOptions: toMutationOptions(DELETE_USER),
})
