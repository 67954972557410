import React from "react"
import { MarkupRange } from "lib"
import {
  TextSelection,
  EditorView,
  findPlugin,
  findKeys,
  CLASS_SELECTED,
} from "editor"
import { CollabEditor } from "../CollabEditor"
import { EditorToolbar } from "./EditorToolbar"
import { useTabState, withTabStateProvider } from "../../utils"
import { useDocStripeMenuItems } from "../StripeMenu"

interface EditorProps {
  id: string
  hasMedia?: boolean
}

export interface EditorState {
  scrollToRange?: MarkupRange
}

export const Editor = withTabStateProvider<EditorProps, EditorState>(
  ({ id, hasMedia }) => {
    const {
      state: { scrollToRange },
      writeState,
    } = useTabState<EditorState>()

    const onLoad = React.useCallback(
      (view: EditorView) => {
        if (!scrollToRange) return
        const { from, to } = scrollToRange

        try {
          const selection = TextSelection.create(view.state.doc, from, to)
          view.dispatch(view.state.tr.setSelection(selection))
          const el = document.querySelector("." + CLASS_SELECTED)
          if (el) el.scrollIntoView({ block: "center", behavior: "smooth" })
          writeState({})
        } catch (err) {
          // the range is invalid
        }
      },
      [scrollToRange, writeState]
    )

    return (
      <CollabEditor
        docId={id}
        hasMedia={hasMedia}
        onLoad={onLoad}
        plugins={[findPlugin, findKeys]}
        ToolbarComponent={EditorToolbar}
        useStripeMenuItems={useDocStripeMenuItems}
      />
    )
  },
  {}
)
