import { useQuery } from "@apollo/client"
import clsx from "clsx"
import * as yup from "yup"
import { AccountData, ACCOUNT_DATA, cl, updateUser, useExec } from "../../utils"
import { DialogForm, Field } from "../base"

export const AccountUpdate: React.FC = () => {
  const exec = useExec(),
    { data, error, loading } = useQuery<AccountData>(ACCOUNT_DATA)
  return (
    <DialogForm
      label="Update Profile"
      loading={loading}
      error={!!error}
      values={{ name: data?.getUser.name || "" }}
      schema={yup.object({
        name: yup.string().required(),
      })}
      submitText={"Update"}
      classNames={{ button: cl.btn }}
      onSubmit={async (data) => exec(updateUser, data)}
    >
      <Field name="name" label="User Name" />
      <div className="flex flex-column w-100">
        <label className={clsx(cl.label, "db mb1")}>Email</label>
        <input
          className={cl.input}
          style={{ backgroundColor: "#eee" }}
          disabled
          value={data?.getUser.email || ""}
        />
      </div>
    </DialogForm>
  )
}
