import React from "react"
import { ClientRoute } from "lib"
import { useHistory } from "react-router-dom"
import { useExec, verifyEmail } from "../utils"
import { Spinner } from "./base"

export const VerifyEmail: React.FC = () => {
  const token = new URLSearchParams(window.location.search).get("token") || "",
    history = useHistory(),
    exec = useExec()

  React.useEffect(() => {
    exec(verifyEmail, { token }).catch(() => {
      history.push(ClientRoute.SignIn)
    })
  }, [token, exec, history])

  return <Spinner />
}
