import {
  cl,
  sortByName,
  Named,
  SidebarTab,
  useProjectDispatch,
  useProjectState,
} from "../utils"
import clsx from "clsx"
import React from "react"

interface TagLinksProps {
  tags: Named[]
  className?: string
  tab: SidebarTab
}

export const TagFilterButtons: React.FC<TagLinksProps> = ({
  tags,
  className,
  tab,
}) => {
  const dispatch = useProjectDispatch(),
    state = useProjectState().sidebar

  return (
    <div className={clsx("f6", className)}>
      {sortTags(tags).map((tag) => {
        const active = state.tab === tab && state.filterTagIds.includes(tag.id),
          btnClass = active ? cl.btnLightActive : cl.btnLight
        return (
          <button
            key={tag.id}
            aria-label={tag.name}
            className={clsx(btnClass.replace("pa2", "pa1"), "mb1 mr1")}
            onClick={() =>
              dispatch({
                type: "apply-filter",
                tab,
                tagIds:
                  active && state.filterTagIds.length === 1 ? [] : [tag.id],
              })
            }
          >
            {tag.name}
          </button>
        )
      })}
    </div>
  )
}

function sortTags(tags: Named[]) {
  return tags.slice().sort(sortByName)
}
