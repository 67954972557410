import { arrayMove } from "../array-move"
import { clamp } from "../misc"

export enum TabDataType {
  Doc,
  Code,
  Analysis,
  Settings,
  Help,
  Widget,
}

export enum TabWidget {
  RelationshipMatrix = "1",
  ExploreCodedText = "2",
  ProjectSettings = "3",
}

export interface TabsState {
  tabs: TabState[]
  activeTabIndex: number
}

export const defaultTabsState: TabsState = {
  tabs: [],
  activeTabIndex: 0,
}

export interface TabState {
  id: string
  dataType: TabDataType
}

export type TabAction =
  | {
      type: "open-tab"
      dataType: TabDataType
      id: string
    }
  | {
      type: "select-tab"
      index: number
    }
  | {
      type: "close-tab"
      index: number
    }
  | {
      type: "close-other-tabs"
      index: number
    }
  | {
      type: "close-tabs-to-right"
      index: number
    }
  | {
      type: "close-all-tabs"
    }
  | {
      type: "close-tab-if-exists"
      ids: string[]
      dataType: TabDataType
    }
  | {
      type: "sort-tabs"
      dragIndex: number
      dropIndex: number
    }

export function tabsReducer(state: TabsState, action: TabAction): TabsState {
  switch (action.type) {
    case "open-tab": {
      const { id, dataType } = action,
        index = state.tabs.findIndex(
          (t) => t.id === id && t.dataType === dataType
        )
      if (index < 0) {
        return {
          tabs: [...state.tabs, { id, dataType }],
          activeTabIndex: state.tabs.length,
        }
      } else {
        return { ...state, activeTabIndex: index }
      }
    }
    case "close-tab":
      return {
        ...state,
        tabs: state.tabs.filter((_, i) => i !== action.index),
        activeTabIndex: clamp(
          action.index < state.activeTabIndex
            ? state.activeTabIndex - 1
            : state.activeTabIndex,
          0,
          state.tabs.length - 2
        ),
      }
    case "close-tabs-to-right": {
      const tabs = state.tabs.filter((_, i) => i <= action.index)
      return {
        ...state,
        tabs,
        activeTabIndex: clamp(state.activeTabIndex, 0, tabs.length - 1),
      }
    }
    case "close-other-tabs":
      return {
        ...state,
        tabs: state.tabs.filter((_, i) => i === action.index),
        activeTabIndex: 0,
      }
    case "close-all-tabs":
      return { ...state, tabs: [], activeTabIndex: 0 }
    case "select-tab":
      return { ...state, activeTabIndex: action.index }
    case "close-tab-if-exists": {
      const indicesToRemove = state.tabs
        .map((tab, i) => {
          if (tab.dataType === action.dataType && action.ids.includes(tab.id)) {
            return i
          } else {
            return -1
          }
        })
        .filter((idx) => idx >= 0)

      const shiftLeft = indicesToRemove.filter(
        (idx) => idx <= state.activeTabIndex
      ).length

      return {
        ...state,
        tabs: state.tabs.filter((_, i) => !indicesToRemove.includes(i)),
        activeTabIndex: clamp(
          state.activeTabIndex - shiftLeft,
          0,
          state.tabs.length - indicesToRemove.length
        ),
      }
    }
    case "sort-tabs": {
      const { id, dataType } = state.tabs[state.activeTabIndex],
        tabs = arrayMove(state.tabs, action.dragIndex, action.dropIndex)
      return {
        ...state,
        tabs,
        activeTabIndex: tabs.findIndex(
          (tab) => tab.id === id && tab.dataType === dataType
        ),
      }
    }
    default:
      return state
  }
}
