import React from "react"
import { DEFAULT_CODE_COLOR, TabDataType, Named } from "../../utils"
import {
  Button,
  SquareFill,
  SquareOutline,
  SquareSlash,
  ListGridCellsBaseProps,
} from "../base"
import { useCodePalette } from "./Provider"
import { HoverLink, HOVER_CODE } from "../HoverLink"

type CellProps = ListGridCellsBaseProps<Named & { color?: string }>

export const CodePaletteToggleButton = React.memo<CellProps>(
  ({ data: { id, color, name }, ...rest }) => {
    const { activeCodeIds, onAddCoding, onRemoveCoding, paletteId, canCode } =
        useCodePalette(),
      active = activeCodeIds.includes(id),
      label = `${
        !canCode
          ? "Select some text to assign code"
          : active
          ? "Unassign code"
          : "Assign code"
      } ${name}`

    return (
      <Button
        data-palette-item={`${paletteId}-${id}`}
        label={label}
        text={null}
        tooltip
        icon={
          <Icon
            color={color || DEFAULT_CODE_COLOR}
            active={active}
            canCode={canCode}
          />
        }
        {...rest}
        className="flex-shrink-0 f4 pa2 bn bg-transparent hover-bg-black-10 br2 pointer mr2"
        onClick={() => {
          if (active) {
            onRemoveCoding(id)
          } else {
            onAddCoding(id)
          }
        }}
      />
    )
  }
)

const Icon = React.memo<{ color: string; active: boolean; canCode: boolean }>(
  ({ color, active, canCode }) => {
    const Icon = !canCode ? SquareSlash : active ? SquareFill : SquareOutline
    return (
      <Icon color={color || DEFAULT_CODE_COLOR} className="flex-shrink-0" />
    )
  }
)

export const CodeLink = React.memo<CellProps>(
  ({ data: { id, name }, ...rest }) => (
    <HoverLink
      id={id}
      name={name}
      dataType={TabDataType.Code}
      query={HOVER_CODE}
      offsetX={50}
      {...rest}
      className="truncate pv1"
    />
  )
)
