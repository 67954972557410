import { useQuery } from "@apollo/client"
import {
  AccountData,
  ACCOUNT_DATA,
  deleteAccount,
  signOut,
  useExec,
} from "../../utils"
import { DataLoadingError, DeleteForm, Dialog, Spinner } from "../base"

export const AccountDelete: React.FC = () => {
  const exec = useExec(),
    { data, error } = useQuery<AccountData>(ACCOUNT_DATA)

  return (
    <Dialog>
      {error ? (
        <DataLoadingError />
      ) : !data ? (
        <Spinner />
      ) : (
        <DeleteForm
          deleteNoun="Account"
          confirmNoun="User Name"
          confirmText={data.getUser.name}
          warning="Deleting your account is irreversible. All of your projects will be permanently deleted. Confirm that you really want to do this by typing your user name."
          onDelete={async () => {
            await exec(deleteAccount, undefined)
            await exec(signOut, undefined)
          }}
        />
      )}
    </Dialog>
  )
}
