import React from "react"
import { getCodeRangesFromDoc, rangesOverlap } from "lib"
import { TextSelection, EditorView, insertTimestamp } from "editor"
import {
  ActionMenuItem,
  ProsemirrorDiv,
  Toolbar,
  useProsemirror,
} from "../base"
import {
  CodeStripes,
  getRangeKey,
  MaybeClippedCodeRange,
  serializeActiveRangeKeys,
  StripeClickHandlerProps,
} from "../CodeStripes"
import { CodePalette, CodePaletteWindow, useCodePalette } from "../CodePalette"
import { MediaPlayer } from "../MediaPlayer"
import { MediaPlayerButtons } from "./MediaPlayerButtons"

import "./View.css"
import "./styles.css"

export type CodeRangeMapper = (
  ranges: MaybeClippedCodeRange[]
) => MaybeClippedCodeRange[]

const defaultCodeRangeMapper = (ranges: MaybeClippedCodeRange[]) => ranges

export interface ViewProps {
  docId: string
  mapRanges?: CodeRangeMapper
  ToolbarComponent: CollabEditorToolbarComponent
  useStripeMenuItems: (
    docId: string
  ) => (props: StripeClickHandlerProps) => ActionMenuItem[]
}

export const View: React.FC<ViewProps> = ({
  docId,
  ToolbarComponent,
  mapRanges = defaultCodeRangeMapper,
  useStripeMenuItems,
}) => {
  const { view, viewRef } = useProsemirror(),
    { scrollCodeIntoView } = useCodePalette(),
    selectPassage = React.useCallback(
      ({ codeId, from, to }: StripeClickHandlerProps) => {
        if (!view) return
        scrollCodeIntoView(codeId)
        view.dispatch(
          view.state.tr.setSelection(
            TextSelection.create(view.state.doc, from, to)
          )
        )
      },
      [view, scrollCodeIntoView]
    ),
    doc = view?.state.doc,
    ranges = React.useMemo(() => {
      if (!doc) return []
      return mapRanges(getCodeRangesFromDoc(doc))
    }, [doc, mapRanges]),
    from = view?.state.selection.from,
    to = view?.state.selection.to,
    activeRangeKeys = React.useMemo(() => {
      if (from === undefined || to === undefined) return null
      if (to - from < 1) return null
      const keys: string[] = [],
        selection = { from, to }
      ranges.forEach((range) => {
        if (rangesOverlap(range, selection))
          keys.push(getRangeKey(range.codeId, range.from))
      })
      return serializeActiveRangeKeys(keys)
    }, [ranges, from, to]),
    getStartNode = React.useCallback(
      (pos: number) => getSpan(viewRef.current, pos, 1),
      [viewRef]
    ),
    getEndNode = React.useCallback(
      (pos: number) => getSpan(viewRef.current, pos, -1),
      [viewRef]
    ),
    getStripeMenuItems = useStripeMenuItems(docId)

  React.useEffect(() => {
    const onKeyDown = function (e: KeyboardEvent) {
      if (!e.altKey || e.key !== "i") return
      e.stopPropagation()
      e.preventDefault()
      const audio = document.querySelector("audio"),
        view = viewRef.current
      if (!audio || !view) return
      insertTimestamp(audio.currentTime)(view.state, view.dispatch)
    }
    window.addEventListener("keydown", onKeyDown)
    return () => window.removeEventListener("keydown", onKeyDown)
  }, [viewRef])

  return (
    <>
      <Toolbar
        classNames={{
          toolbar:
            "bb b--black-20 bg-near-white z-1 ph2 ph0-ns pv2 w-100 overflow-x-scroll overflow-visible-ns",
        }}
        style={{
          position: "sticky",
          top: 0,
        }}
      >
        {view ? <ToolbarComponent docId={docId} view={view} /> : null}
      </Toolbar>
      <div className="flex overflow-x-scroll overflow-visible-ns z-1">
        <div className="w-100 flex-shrink-0 bg-white-80 bl-ns br bb b--black-20 br1 br--bottom mb5">
          <ProsemirrorDiv />
        </div>
        <div className="pl3 w-50 w-auto-ns">
          <CodeStripes
            ranges={ranges}
            getStartNode={getStartNode}
            getEndNode={getEndNode}
            onStripeClick={selectPassage}
            getMenuItems={getStripeMenuItems}
            activeRangeKeys={activeRangeKeys}
          />
        </div>
      </div>
      <CodePaletteWindow>
        <CodePalette />
      </CodePaletteWindow>
      <MediaPlayer>
        <MediaPlayerButtons />
      </MediaPlayer>
    </>
  )
}

export interface CollabEditorToolbarProps {
  docId: string
  view: EditorView
}

export type CollabEditorToolbarComponent = React.FC<CollabEditorToolbarProps>

function getSpan(
  view: EditorView | undefined | null,
  pos: number,
  side: number
) {
  if (!view) return null

  const { node } = view.domAtPos(pos, side)
  let current =
    node.nodeType === Node.ELEMENT_NODE
      ? (node as HTMLElement)
      : node.parentElement

  while (current) {
    if (current.getAttribute("data-coded")) break
    if (current.parentElement) {
      current = current.parentElement
    } else {
      break
    }
  }

  return current
}
