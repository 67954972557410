import { gql } from "@apollo/client"

// NOTE: do not use fragments within other fragments
// this will prevent them being used in cache.writeFragment, which is handy
// for writing data with cache.modify.

const projectPreview = gql`
  fragment ProjectPreview on Project {
    id
    name
    owner {
      id
      name
    }
    collaborators {
      id
      name
    }
    docCount
    codeCount
  }
`

const collaborator = gql`
  fragment Collaborator on User {
    id
    name
    email
  }
`

const inviteForOwner = gql`
  fragment InviteForOwner on Invite {
    id
    email
    createdAt
  }
`

const inviteForUser = gql`
  fragment InviteForUser on Invite {
    id
    createdAt
    project {
      id
      name
      owner {
        id
        name
        email
      }
    }
  }
`

const docPreview = gql`
  fragment DocPreview on Doc {
    id
    name
    createdAt
    updatedAt
    codeCount
    tags {
      id
      name
    }
  }
`

// should be same as docPreview, but with more detail
const docDetail = gql`
  fragment DocDetail on Doc {
    id
    name
    description
    createdAt
    createdBy {
      id
      name
    }
    updatedAt
    codeCount
    tags {
      id
      name
    }
    media {
      id
      available
      filename
    }
    transcript {
      id
      error
      length
    }
  }
`

const codePreview = gql`
  fragment CodePreview on Code {
    id
    name
    color
    createdAt
    updatedAt
    docCount
    tags {
      id
      name
    }
  }
`

export const fragments = {
  projectPreview,
  collaborator,
  inviteForOwner,
  inviteForUser,
  docPreview,
  docDetail,
  codePreview,
}
