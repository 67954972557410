import { cl, useExec, useDialog, retryTranscription } from "../../utils"
import { Alert, Button, Dialog, DialogH2, Uploader } from "../base"
import { TranscriptionStarter } from "../TranscriptionStarter"

export interface TranscriptErrorProps {
  docId: string
  error: string // not shown to client but good to have
}

export const TranscriptError: React.FC<TranscriptErrorProps> = ({ docId }) => {
  const { show } = useDialog()
  return (
    <Alert className="mv3 pa3 flex items-center justify-between">
      <div>There was an error transcribing your audio file.&nbsp;</div>

      <div className="dib">
        <Button
          label="Retry"
          onClick={() => show(RetryTranscription, { docId })}
          className={cl.btn}
        />
      </div>
    </Alert>
  )
}

const RetryTranscription: React.FC<{ docId: string }> = ({ docId }) => (
  <Dialog>
    <DialogH2>Retry Transcription</DialogH2>
    <Uploader
      types={["mp3", "mp4", "flac", "WebM", "WAV", "Ogg"]}
      render={(files) => (
        <PreviewRetryTranscription docId={docId} file={files[0]} />
      )}
    />
  </Dialog>
)

export const PreviewRetryTranscription: React.FC<{
  docId: string
  file: File
}> = ({ docId, file }) => {
  const exec = useExec()

  return (
    <TranscriptionStarter
      file={file}
      getUploadSpec={async (file) => {
        const data = await exec(retryTranscription, {
          docId,
          filename: file.name,
        })
        if (!data) throw new Error("could not import audio")
        return {
          id: docId,
          url: data.retryTranscription.uploadUrl,
        }
      }}
    />
  )
}
