import { gql } from "@apollo/client"
import { fragments } from "../../graphql-fragments"
import {
  CreateCode,
  CreateCodeVariables,
  DeleteCodes,
  DeleteCodesVariables,
  UpdateCodes,
  UpdateCodesVariables,
} from "../../types"
import { msg, Noun } from "../../msg"
import { mutationCommand, toMutationOptions } from "../command"
import { addProjectItem, removeProjectItems } from "../modify-project"
import { TabDataType } from "../../hooks"

const CREATE_CODE = gql`
  mutation CreateCode($projectId: ID!, $data: CreateCodeInput!) {
    createCode(projectId: $projectId, data: $data) {
      ...CodePreview
    }
  }

  ${fragments.codePreview}
`

export const createCode = mutationCommand<
  CreateCodeVariables["data"],
  CreateCode,
  CreateCodeVariables
>({
  messages: (_, { name }) => msg.create(Noun.Code, name || "Untitled"),
  getMutationOptions: ({ route }, input) => ({
    mutation: CREATE_CODE,
    variables: {
      projectId: route.projectId,
      data: input,
    },
    update: (cache, { data }) => {
      if (!data) return
      addProjectItem({
        projectId: route.projectId,
        field: "codes",
        cache,
        fragment: fragments.codePreview,
        item: data.createCode,
      })
    },
  }),
})

const UPDATE_CODES = gql`
  mutation UpdateCodes($ids: [ID!]!, $data: UpdateCodeInput!) {
    updateCodes(ids: $ids, data: $data) {
      id
      name
      description
      color
      updatedAt
      tags {
        id
        name
      }
    }
  }
`

export const updateCodes = mutationCommand<UpdateCodesVariables, UpdateCodes>({
  getMutationOptions: toMutationOptions(UPDATE_CODES),
  messages: () => msg.update(Noun.Code),
})

const DELETE_CODES = gql`
  mutation DeleteCodes($ids: [ID!]!) {
    deleteCodes(ids: $ids)
  }
`

export const deleteCode = mutationCommand<
  { id: string; name: string },
  DeleteCodes,
  DeleteCodesVariables
>({
  messages: (_, { name }) => msg.delete(Noun.Code, name),
  confirm: true,
  getMutationOptions: ({ route }, input) => ({
    mutation: DELETE_CODES,
    variables: { ids: [input.id] },
    update: (cache) => {
      removeProjectItems({
        projectId: route.projectId,
        field: "codes",
        cache,
        ids: [input.id],
      })
    },
  }),
  sideEffect: (ctx, { id }) => {
    ctx.projectDispatch({
      type: "close-tab-if-exists",
      ids: [id],
      dataType: TabDataType.Code,
    })
  },
})

export const deleteCodes = mutationCommand<DeleteCodesVariables, DeleteCodes>({
  messages: (_, { ids }) => msg.delete(Noun.Code, ids.length),
  confirm: true,
  getMutationOptions: ({ route }, input) => ({
    mutation: DELETE_CODES,
    variables: input,
    update: (cache) => {
      removeProjectItems({
        projectId: route.projectId,
        field: "codes",
        cache,
        ids: input.ids,
      })
    },
  }),
  sideEffect: (ctx, { ids }) => {
    ctx.projectDispatch({
      type: "close-tab-if-exists",
      ids,
      dataType: TabDataType.Code,
    })
  },
})
