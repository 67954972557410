import React from "react"
import { ControlledMenu, useMenuState } from "@szhsin/react-menu"
import { ContextMenuContext, ContextMenuState } from "../utils"
import { renderMenuItems } from "./base"

export const ContextMenuProvider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<null | ContextMenuState>(null),
    { toggleMenu, ...menuProps } = useMenuState()

  React.useEffect(() => {
    if (state) toggleMenu(true)
  }, [state, toggleMenu])
  return (
    <ContextMenuContext.Provider value={setState}>
      {state && (
        <div className="fixed top-0 left-0 vh-100 w-100 z-3">
          <ControlledMenu
            {...menuProps}
            anchorPoint={{ x: state.x, y: state.y }}
            onContextMenu={(e) => {
              e.preventDefault()
              e.stopPropagation()
            }}
            align="start"
            direction="bottom"
            position="initial"
            onClose={() => {
              setState(null)
              toggleMenu(false)
            }}
          >
            {renderMenuItems(state.items)}
          </ControlledMenu>
        </div>
      )}
      {children}
    </ContextMenuContext.Provider>
  )
}
