import clsx from "clsx"
import { SomeObject } from "lib"
import React from "react"
import { cl } from "../../utils"

interface FormikProps {
  form: unknown
  field: unknown
}

export const Input = React.forwardRef<
  HTMLInputElement,
  React.HTMLProps<HTMLInputElement>
>((props, ref) => (
  <input
    {...spreadInputProps(props)}
    ref={ref}
    className={clsx(cl.input, props.className)}
  >
    {props.children}
  </input>
))

// fixes issue with formik props
function spreadInputProps(props: SomeObject) {
  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { form, field, ...rest } = props
  return { ...rest, ...field }
}

export const Textarea = React.forwardRef<
  HTMLTextAreaElement,
  React.HTMLProps<HTMLTextAreaElement> & (FormikProps | undefined)
  //eslint-disable-next-line @typescript-eslint/no-unused-vars
>((props, ref) => (
  <textarea
    {...spreadInputProps(props)}
    rows={5}
    style={{
      resize: "vertical",
      ...props.style,
    }}
    ref={ref}
    className={clsx(cl.input, props.className)}
  >
    {props.children}
  </textarea>
))
