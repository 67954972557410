import { ApolloCache } from "@apollo/client"
import { DocumentNode } from "graphql"
import { fragments } from "../graphql-fragments"
import {
  CodePreview,
  Collaborator,
  DocPreview,
  InviteForOwner,
  ProjectPreview,
} from "../types"

// add projects
export function addProjectToCache(
  cache: ApolloCache<unknown>,
  data: ProjectPreview
) {
  const fragment = cache.writeFragment({
    data,
    fragment: fragments.projectPreview,
  })

  return cache.modify({
    fields: {
      listProjects: (projects) => [...projects, fragment],
    },
  })
}

export function removeProjectFromCache(
  cache: ApolloCache<unknown>,
  id: string
) {
  return cache.modify({
    fields: {
      listProjects(projects = [], { readField }) {
        return projects.filter((proj: undefined) => readField("id", proj) != id)
      },
    },
  })
}

// modify projects
interface ProjectFragments {
  docs: DocPreview
  codes: CodePreview
  collaborators: Collaborator
  invites: InviteForOwner
}

type ProjectField = keyof ProjectFragments

export function addProjectItem<T extends ProjectField>({
  projectId,
  field,
  fragment,
  cache,
  item,
}: AddItemArgs<T>) {
  if (typeof item === "string") throw new Error("invalid item")

  const newItemRef = cache.writeFragment({
    data: item,
    fragment: fragment,
  })

  return cache.modify({
    id: cache.identify({ __typename: "Project", id: projectId }),
    fields: {
      [field]: (items) => [...items, newItemRef],
    },
  })
}

export function removeProjectItems<T extends ProjectField>({
  projectId,
  field,
  ids,
  cache,
}: RemoveItemArgs<T>) {
  cache.modify({
    id: cache.identify({ __typename: "Project", id: projectId }),
    fields: {
      [field]: (items, { readField }) => {
        return items.filter(
          //eslint-disable-next-line @typescript-eslint/no-explicit-any
          (item: any) => !ids.includes(readField("id", item) as any)
        )
      },
    },
  })
}

interface BaseArgs<T extends ProjectField> {
  projectId: string
  field: T
  cache: ApolloCache<unknown>
}

interface AddItemArgs<T extends ProjectField> extends BaseArgs<T> {
  fragment: DocumentNode
  item: ProjectFragments[T]
}

interface RemoveItemArgs<T extends ProjectField> extends BaseArgs<T> {
  ids: string[]
}
