export function scrollIntoView(el: HTMLElement, offset = 0) {
  const scrollParent = el.offsetParent
  if (!scrollParent) return

  const { scrollTop: viewTop, clientHeight } = scrollParent,
    viewBottom = viewTop + clientHeight,
    elTop = el.offsetTop,
    elBottom = elTop + el.clientHeight,
    visible = elTop > viewTop && elBottom < viewBottom

  if (!visible) {
    scrollParent.scrollTop = elTop + offset
  }
}
