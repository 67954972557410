import { SubMenu, MenuGroup, MenuItem } from "@szhsin/react-menu"
import clsx from "clsx"
import { extractTags, Named, Tagged } from "../utils"
import {
  ComboSelect,
  ComboSelectPositionProps,
  ITEM_CLASS,
  ICON_CLASS,
  CheckBox,
  Square,
  DashSquare,
} from "./base"

interface TaggablePickerProps extends ComboSelectPositionProps {
  items: (Named & Tagged<Named>)[]
  ids: string[]
  onChange: (ids: string[]) => void
  className?: string
  label?: string
}

export const TaggablePicker: React.FC<TaggablePickerProps> = ({
  items,
  ids,
  onChange,
  label = "Choose items",
  className,
  align,
  direction,
}) => {
  const tags = extractTags(items)

  return (
    <div className={clsx("flex items-center", className)}>
      <ComboSelect
        align={align}
        direction={direction}
        label={label}
        selectedIds={ids}
        options={items}
        onChange={onChange}
        extra={
          !tags.length
            ? undefined
            : () => (
                <SubMenu
                  label="Select by Tag..."
                  itemProps={{
                    className: ITEM_CLASS,
                  }}
                  overflow="auto"
                >
                  <MenuGroup takeOverflow>
                    {tags.map(({ id, name }) => {
                      const tagged = hasTags(items, [id]).map(
                          (item) => item.id
                        ),
                        taggedAndSelected = tagged.filter((id) =>
                          ids.includes(id)
                        ),
                        checked = taggedAndSelected.length > 0,
                        allTaggedSelected =
                          taggedAndSelected.length === tagged.length,
                        Icon = allTaggedSelected
                          ? CheckBox
                          : checked
                          ? DashSquare
                          : Square
                      return (
                        <MenuItem
                          key={id}
                          type="checkbox"
                          checked={checked}
                          onClick={(e) => {
                            e.stopPropagation = true
                            e.keepOpen = true
                            if (allTaggedSelected) {
                              onChange(ids.filter((id) => !tagged.includes(id)))
                            } else {
                              onChange(mergeUniquely(ids, tagged))
                            }
                          }}
                          className={ITEM_CLASS}
                        >
                          <Icon className={ICON_CLASS} /> {name}
                        </MenuItem>
                      )
                    })}
                  </MenuGroup>
                </SubMenu>
              )
        }
      />
    </div>
  )
}

function hasTags<T extends Tagged<Named>>(items: T[], tagIds: string[]) {
  return items.filter((item) => {
    const itemTagIds = item.tags.map((tag) => tag.id)
    return tagIds.every((tagId) => itemTagIds.includes(tagId))
  })
}

function mergeUniquely(a: string[], b: string[]) {
  return Array.from(new Set([...a, ...b]))
}
