import React from "react"
import clsx from "clsx"
import { panels } from "./SidebarPanels"
import {
  useProjectState,
  useProjectDispatch,
  SidebarTab,
  TabDataType,
  TabWidget,
  useAccountMenu,
} from "../../utils"
import { ActionMenu, Gear, House, Person } from "../base"
import { useHistory } from "react-router-dom"

const NAV_BUTTON =
  "bg-transparent pa3 bw2 ba br-0 bt-0 bb-0 flex items-center pointer f3"
const INACTIVE_BUTTON = "silver b--transparent hover-mid-gray"
const STATIC_BUTTON = clsx(NAV_BUTTON, INACTIVE_BUTTON)

export const SidebarNavButtons: React.FC = () => {
  const { tab: activeTab, open: open } = useProjectState().sidebar,
    dispatch = useProjectDispatch(),
    history = useHistory(),
    accountActions = useAccountMenu()

  return (
    <>
      {panels.map(({ Icon, label, tab }) => (
        <button
          key={tab}
          aria-label={label}
          title={label}
          className={clsx(
            NAV_BUTTON,
            tab === activeTab && open
              ? "near-black b--near-black"
              : INACTIVE_BUTTON
          )}
          onClick={() => {
            dispatch({ type: "toggle-sidebar-tab", tab })
          }}
        >
          <Icon />
        </button>
      ))}
      <button
        className={STATIC_BUTTON}
        title="Project Settings"
        aria-label="Project Settings"
        onClick={() => {
          dispatch({
            type: "open-tab",
            id: TabWidget.ProjectSettings,
            dataType: TabDataType.Settings,
          })
        }}
      >
        <Gear />
      </button>
      <div className="flex-grow-1" />
      <ActionMenu
        content={<Person />}
        label="Account"
        buttonProps={{ className: STATIC_BUTTON }}
        items={accountActions}
        menuProps={{ direction: "right", align: "end" }}
      />
      <button
        className={STATIC_BUTTON}
        aria-label="Home"
        onClick={() => {
          history.push("/projects")
        }}
      >
        <House />
      </button>
    </>
  )
}

export interface SidebarPanelProps {
  tab: SidebarTab
  label: string
  Content: React.ComponentType
  Icon: React.ComponentType
  Tools: React.ComponentType
}

export const SidebarPanel: React.FC<SidebarPanelProps> = ({
  label,
  Content,
  Tools,
}) => {
  return (
    <>
      <div className="flex items-center justify-between bb b--black-20 flex-shrink-0 shadow-1 z-1">
        <div className="fw6 f5 ma3 dark-gray">{label}</div>
        <Tools />
      </div>
      <Content />
    </>
  )
}

export const SidebarPlaceholder: React.FC = ({ children }) => (
  <div className="mid-gray f5 lh-copy pa3">{children}</div>
)
