import React from "react"
import { gql, useQuery } from "@apollo/client"
import * as yup from "yup"
import {
  updateProject,
  useExec,
  GetProjectForUpdate,
  cl,
  GetProjectForUpdateVariables,
} from "../../utils"
import { DialogForm, Textarea, Field } from "../base"

const GET_PROJECT = gql`
  query GetProjectForUpdate($id: ID!) {
    getProject(id: $id) {
      id
      name
      description
    }
  }
`

export const ProjectUpdate: React.FC<{ id: string }> = ({ id }) => {
  const { data, loading, error } = useQuery<
      GetProjectForUpdate,
      GetProjectForUpdateVariables
    >(GET_PROJECT, { variables: { id } }),
    exec = useExec(),
    { name = "", description = "" } = data?.getProject || {}
  return (
    <DialogForm
      label="Edit Project Details"
      loading={loading}
      error={!!error}
      values={{ name, description }}
      schema={yup.object({
        name: yup.string().required(),
        description: yup.string(),
      })}
      onSubmit={async (data) => exec(updateProject, { id, data })}
      submitText="Save Changes"
      classNames={{ button: cl.btn }}
      reset
    >
      <Field name="name" label="Name" />
      <Field name="description" label="Description" component={Textarea} />
    </DialogForm>
  )
}
