// in general: include colors and weights
// exlude layout properties other than with the exception of:
// - input padding

const btnBase = "br2 pa2 pointer tc inline-flex items-center"

const grid = {
  row: {
    header: "bw1 bb b--black",
    data: "bb b--black-20 hover-bg-black-10",
  },
  cell: {
    base: "pv2 ph3 items-center f5",
    header: "black fw6",
    data: "dark-gray",
  },
  container: "pb5",
}

const btnLightBase = `${btnBase} dark-gray ba b--black-20`

export const cl = {
  h1: "black lh-title fw2 f2 mt4 mb3",
  h2: "near-black lh-title fw4 f3 mt4 mb3",
  h3: "mid-gray lh-title f4 fw6 mt4 mb3",
  p: "dark-gray lh-copy f5 mv3",
  label: "mid-gray fw6 f5",
  help: "mid-gray lh-copy f6",
  input: "dark-gray bg-white b--black-20 ba br2 pa2 f5 self-stretch border-box",
  btn: `${btnBase} white bg-dark-gray hover-bg-black ba b--dark-gray`,
  btnLight: `${btnLightBase} bg-light-gray hover-bg-near-white`,
  btnLightActive: `${btnLightBase} bg-moon-gray hover-bg-light-silver`,
  btnDanger: `${btnBase} white bg-dark-red hover-bg-dark-gray bn`,
  btnGhost: `${btnBase} dark-gray bn bg-transparent hover-bg-black-10`,
  btnGhostActive: `${btnBase} dark-gray bn bg-black-20`,
  a: "dark-blue hover-blue no-underline underline-hover pointer",
  grid,
}

export const DEFAULT_CODE_COLOR = "#333" // tachyons dark-gray

export const TAB_RENDER_CONTAINER = "tab-render-container"
