import React from "react"
import {
  cl,
  Named,
  TabDataType,
  useDialog,
  useDocs,
  useItemsFilteredByTags,
  useProjectState,
} from "../utils"
import {
  action,
  ActionMenu,
  DIVIDER,
  ListGrid,
  ListGridCellsBaseProps,
  Plus,
  Spinner,
  Ellipsis,
} from "./base"
import { useDocMenuItems } from "./DocMenu"
import { HoverLink, HOVER_DOC } from "./HoverLink"
import clsx from "clsx"
import {
  ImportCSVDialog,
  ImportMediaDialog,
  ImportPDFDialog,
  ImportWordDialog,
} from "./Importer"
import { DocCreate } from "./dialogs"
import { SidebarPlaceholder } from "./Project"
import { SidebarFilterMenu } from "./SidebarFilterMenu"

export const DocList = () => {
  const { docs, loading } = useDocs(),
    menus = useDocMenuItems(),
    { filterTagIds } = useProjectState().sidebar,
    listDocs = useItemsFilteredByTags(docs, filterTagIds)

  if (loading) return <Spinner />
  if (!docs.length)
    return <SidebarPlaceholder>No documents yet.</SidebarPlaceholder>
  if (!listDocs.length)
    return <SidebarPlaceholder>No matching documents.</SidebarPlaceholder>

  return (
    <ListGrid
      items={listDocs}
      columns={[
        {
          component: DocLink,
        },
        {
          component: DocMenu,
        },
      ]}
      selectable
      menus={menus}
      className="overflow-auto flex-grow-1 bg-near-white pb5"
      rowClassName="pl4 pv1 f5 hide-child flex items-center justify-between"
      cellClassName="pv1"
    />
  )
}

const DocLink = React.memo<ListGridCellsBaseProps<Named>>(
  ({ data: { id, name }, ...rest }) => (
    <HoverLink
      id={id}
      name={name}
      dataType={TabDataType.Doc}
      query={HOVER_DOC}
      offsetX={40}
      {...rest}
    />
  )
)

const DocMenu = React.memo<ListGridCellsBaseProps<Named>>(
  ({ data, ...rest }) => {
    const { single } = useDocMenuItems()
    return (
      <ActionMenu
        label={`Actions for ${data.name}`}
        items={single(data)}
        menuProps={{ portal: true }}
        content={<Ellipsis />}
        buttonProps={{
          ...rest,
          className: clsx(cl.btnGhost, "pv2 child mh2"),
        }}
      />
    )
  }
)

const AddDocsMenu = () => {
  const { show } = useDialog()

  return (
    <ActionMenu
      label="Add documents"
      content={<Plus />}
      menuProps={{ portal: true }}
      buttonProps={{ className: clsx(cl.btnGhost, "f4") }}
      items={[
        action("Import Word (.docx)", () => show(ImportWordDialog, {})),
        action("Import Spreadsheet (.csv)", () => show(ImportCSVDialog, {})),
        action("Import PDF (.pdf)", () => show(ImportPDFDialog, {})),
        action("Import Audio/Video...", () => show(ImportMediaDialog, {})),
        DIVIDER,
        action("New Document", () => show(DocCreate, {})),
      ]}
    />
  )
}

export const DocTools = () => {
  const { tags } = useDocs()

  return (
    <div className="flex items-center">
      <AddDocsMenu />
      <SidebarFilterMenu tags={tags} className="mh2" />
    </div>
  )
}
