import { useQuery } from "@apollo/client"
import {
  PageTitle,
  ContentPageContainer,
  Spinner,
  FullPageDataError,
} from "./base"
import { Metadata } from "./Metadata"
import { Editor } from "./Editor"
import { GetDocDetail, GET_DOC_DETAIL, SidebarTab } from "../utils"
import { DocMenu } from "./DocMenu"
import { TranscriptViewer } from "./TranscriptViewer"
import { TagFilterButtons } from "./TagPills"

export const Doc: React.FC<{ id: string }> = ({ id }) => {
  const { data, error } = useQuery<GetDocDetail>(GET_DOC_DETAIL, {
    variables: { id },
  })

  if (error) return <FullPageDataError />
  if (!data) return <Spinner />

  const doc = data.getDoc

  return (
    <ContentPageContainer>
      <PageTitle>{doc.name}</PageTitle>
      <Metadata
        name={doc.name}
        description={doc.description}
        updatedAt={doc.updatedAt}
        menuComponent={<DocMenu id={id} name={doc.name} canOpen={false} />}
        tagsComponent={
          <TagFilterButtons tags={doc.tags} tab={SidebarTab.Doc} />
        }
        className="bb b--black-20 ph2 ph0-ns"
      />
      {doc.transcript ? (
        <TranscriptViewer
          docId={id}
          name={doc.name}
          error={doc.transcript.error}
          length={doc.transcript.length}
        />
      ) : (
        <Editor id={id} hasMedia={doc.media?.available} />
      )}
    </ContentPageContainer>
  )
}
