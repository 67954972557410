import React from "react"
import { useExec, createCode, cl } from "../../utils"
import { CommandButton } from "../base"
import { addCoding } from "editor"
import { useCodePalette } from "./Provider"
import clsx from "clsx"

interface CodePaletteInputProps {
  value: string
  onChange: (value: string) => void
  allowCreate: boolean
}

const LABEL = "Find or create code"

export const CodePaletteInput: React.FC<CodePaletteInputProps> = ({
  value,
  onChange,
  allowCreate,
}) => {
  const exec = useExec(),
    { onCodeCreated } = useCodePalette(),
    onCreate = (id: string) => {
      onCodeCreated(id)
      addCoding(id)
      onChange("")
    }

  return (
    <>
      <input
        aria-label={LABEL}
        placeholder={LABEL}
        className="flex-grow-1 f5 pa3 bn"
        value={value}
        onChange={(e) => onChange(e.currentTarget.value)}
        onKeyDown={async (e) => {
          if (e.key === "Escape") {
            e.preventDefault()
            e.stopPropagation()
            return onChange("")
          }
          if (e.key !== "Enter" || !allowCreate) return

          const result = await exec(createCode, { name: value })

          if (!result || !result.createCode) return

          onCreate(result.createCode.id)
        }}
      />
      {allowCreate && value.length ? (
        <CommandButton
          label="Create"
          command={createCode}
          args={{ name: value }}
          className={clsx(cl.btn, "ma2")}
          onComplete={(result) => onCreate(result.createCode.id)}
        />
      ) : null}
    </>
  )
}
