import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
} from "@apollo/client"
import { split } from "@apollo/client"
import { getMainDefinition } from "@apollo/client/utilities"
import { onError } from "@apollo/client/link/error"
import { setContext } from "@apollo/client/link/context"
import { CLIENT_ID_HEADER, getEnv, WebSocketLink } from "lib"
import { getToken } from "./auth"
import { getClientId } from "./client-id"

// Apollo warns about data loss if you don't do this
const merge = (_: unknown, incoming: unknown) => incoming

let apolloClient = createApolloClient()

export function getApolloClient() {
  return apolloClient
}

export function resetApolloClient() {
  apolloClient = createApolloClient()
}

function createApolloClient() {
  return new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        SearchResult: {
          keyFields: ["id", "type"],
        },
        Project: {
          fields: {
            docs: { merge },
            codes: { merge },
          },
        },
        Doc: {
          fields: {
            codeMarks: { merge },
            tags: { merge },
          },
        },
        Code: {
          fields: {
            codeMarks: { merge },
            tags: { merge },
          },
        },
        ProjectEdges: {
          fields: {
            edges: { merge },
          },
        },
      },
    }),
    link: from([
      getAuthLink(),
      ...maybeGetErrorLink(),
      ...maybeGetDevLink(),
      getSplitLink(),
    ]),
  })
}

function getSplitLink() {
  // don't use WS in Jest + JSDOM
  if (getEnv() === "test") return getHttpLink()
  return split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      )
    },
    getWebsocketLink(),
    getHttpLink()
  )
}

function getHttpLink() {
  return createHttpLink({
    uri: "/graphql",
    credentials: "same-origin",
  })
}

function getWebsocketLink() {
  const protocol = window.location.protocol.match(/https/i) ? "wss" : "ws"
  return new WebSocketLink({
    url: `${protocol}://${window.location.host}/graphql`,
    connectionParams: async () => {
      const accessToken = await getToken()
      return { accessToken, clientId: getClientId() }
    },
  })
}

function getAuthLink() {
  return setContext(async (_, { headers }) => {
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${await getToken()}`,
        [CLIENT_ID_HEADER]: getClientId(),
      },
    }
  })
}

function maybeGetErrorLink() {
  if (getEnv() !== "development") return []
  return [
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          console.error(err)
        }
      }

      if (networkError) {
        console.error(networkError)
      }
    }),
  ]
}

function maybeGetDevLink() {
  if (getEnv() === "production") return []
  const DELAY = window.origin.match(/localhost/i) ? 300 : 0
  return [
    setContext(async () => {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          //@ts-expect-error window var
          if (window.DEV_GQL_FAIL) {
            reject(new Error("fake network error"))
          } else {
            resolve({})
          }
        }, DELAY)
      })
    }),
  ]
}

if (getEnv() === "development") {
  //@ts-expect-error window var
  window.enableNetworkErrors = () => {
    //@ts-expect-error window var
    window.DEV_GQL_FAIL = true
  }
  //@ts-expect-error window var
  window.disableNetworkErrors = () => {
    //@ts-expect-error window var
    window.DEV_GQL_FAIL = false
  }
}
