import React from "react"
import clsx from "clsx"
import { gql } from "@apollo/client"
import { DocumentNode, useQuery } from "@apollo/client"
import { TabLink } from "./base"
import { useDebouncedCallback } from "use-debounce"
import { useFloating, offset, shift, flip } from "@floating-ui/react-dom"
import { TagFilterButtons } from "./TagPills"
import { SidebarTab, TabDataType } from "../utils"

interface HoverLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  id: string
  name: string
  dataType: TabDataType
  query: DocumentNode
  offsetX?: number
  offsetY?: number
}

const POPUP_WIDTH = 300
const POPUP_MAX_TEXT_HEIGHT = 500

export const HoverLink: React.FC<HoverLinkProps> = ({
  id,
  name,
  query,
  dataType,
  offsetX = 0,
  offsetY = 0,
  ...rest
}) => {
  const [active, setActive] = React.useState(false),
    { data } = useQuery(query, {
      variables: { id },
      skip: !active,
      fetchPolicy: "cache-and-network",
    }),
    _set = useDebouncedCallback(() => setActive(true), 350),
    _clear = useDebouncedCallback(() => setActive(false), 100),
    set = () => {
      _clear.cancel()
      _set()
    },
    clear = () => {
      _set.cancel()
      _clear()
    },
    dataKey = Object.keys(data || {})[0],
    { description = "", tags = [] } = (data || {})[dataKey] || {},
    handlers = {
      onMouseEnter: set,
      onMouseLeave: clear,
    },
    { x, y, reference, floating, strategy } = useFloating({
      placement: "right-start",
      middleware: [
        offset({ mainAxis: offsetX, crossAxis: offsetY }),
        shift(),
        flip(),
      ],
      strategy: "fixed",
    })

  return (
    <>
      <TabLink
        id={id}
        dataType={dataType}
        {...rest}
        ref={reference}
        {...handlers}
        onFocus={set}
        onBlur={clear}
        className={clsx("truncate", rest.className)}
      >
        {name}
      </TabLink>
      {active && data && (
        <div
          className="shadow-1 z-2 br2 bg-white pa3 overflow-hidden"
          {...handlers}
          ref={floating}
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
          }}
          style={{
            position: strategy,
            top: y || 0,
            left: x || 0,
            width: POPUP_WIDTH,
            animation: "fade-in 0.25s",
          }}
        >
          <div className="f5 fw6 dark-gray lh-title mb2">{name}</div>
          <p
            className="overflow-auto mv0 lh-title f5 dark-gray"
            style={{
              maxHeight: POPUP_MAX_TEXT_HEIGHT,
              whiteSpace: "normal",
            }}
          >
            {description || "No description."}
          </p>

          {tags.length ? (
            <TagFilterButtons
              tags={tags}
              className="mt2"
              tab={
                dataType === TabDataType.Doc ? SidebarTab.Doc : SidebarTab.Code
              }
            />
          ) : null}
        </div>
      )}
    </>
  )
}

export const HOVER_DOC = gql`
  query GetDocForHover($id: ID!) {
    getDoc(id: $id) {
      id
      name
      description
      tags {
        id
        name
      }
    }
  }
`

export const HOVER_CODE = gql`
  query GetCodeForHover($id: ID!) {
    getCode(id: $id) {
      id
      name
      description
      tags {
        id
        name
      }
    }
  }
`
