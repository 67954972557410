export function getReadableTimestamp(secondsString: string) {
  const secPerHour = 60 * 60,
    seconds = parseFloat(secondsString),
    hours = Math.floor(seconds / secPerHour),
    hrSec = hours * secPerHour,
    minutes = Math.floor((seconds - hrSec) / 60),
    minSec = minutes * 60,
    secSec = Math.floor(seconds - (hrSec + minSec)),
    pad = (num: number) => num.toString().padStart(2, "0"),
    minSecString = pad(minutes) + ":" + pad(secSec)

  if (hours) return pad(hours) + ":" + minSecString
  return minSecString
}

export function setProjectLastAccess(id: string) {
  localStorage.setItem(`last-access-${id}`, Date.now().toString())
}

export function getProjectLastAccess(id: string) {
  const value = localStorage.getItem(`last-access-${id}`)
  return value ? parseInt(value) : 0
}
