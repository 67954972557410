import * as yup from "yup"
import { gql, useQuery } from "@apollo/client"
import { DialogForm } from "../base"
import {
  extractTags,
  pickManyById,
  UpdateGetDoc,
  UpdateGetDocVariables,
  useDocs,
  useExec,
  createDoc,
  updateDocs,
} from "../../utils"
import {
  NameField,
  DescriptionField,
  commonSchema,
  getDefaultTagValues,
  TagField,
  CommonValues,
} from "./common"

export const docSchema = yup.object(commonSchema),
  docSchemaMultiple = yup.object({ tags: commonSchema.tags })

export function DocCreate() {
  const exec = useExec()

  return (
    <DialogForm<CommonValues>
      label="Create Document"
      values={{ name: "", description: "", tags: getDefaultTagValues() }}
      schema={docSchema}
      submitText="Create"
      onSubmit={(data) => exec(createDoc, data)}
    >
      <DocFields />
    </DialogForm>
  )
}

const GET_DOC = gql`
  query UpdateGetDoc($id: ID!) {
    getDoc(id: $id) {
      id
      name
      description
      tags {
        id
      }
    }
  }
`

export const DocUpdate: React.FC<{ id: string }> = ({ id }) => {
  const { data, error } = useQuery<UpdateGetDoc, UpdateGetDocVariables>(
      GET_DOC,
      {
        variables: { id },
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-first",
      }
    ),
    exec = useExec()

  return (
    <DialogForm<CommonValues>
      label="Edit Document"
      error={!!error}
      loading={!data}
      values={{
        name: data?.getDoc.name || "",
        description: data?.getDoc.description || "",
        tags: getDefaultTagValues(data?.getDoc.tags),
      }}
      schema={docSchema}
      submitText="Update"
      onSubmit={(data) => exec(updateDocs, { ids: [id], data })}
    >
      <DocFields />
    </DialogForm>
  )
}

export const DocsUpdate: React.FC<{ ids: string[] }> = ({ ids }) => {
  const { docs, tags } = useDocs(),
    exec = useExec(),
    activeTags = extractTags(pickManyById(docs, ids))

  return (
    <DialogForm<Pick<CommonValues, "tags">>
      label="Edit Documents"
      values={{ tags: getDefaultTagValues(activeTags) }}
      schema={docSchemaMultiple}
      submitText="Update"
      onSubmit={(data) => exec(updateDocs, { ids, data })}
    >
      <TagField tags={tags} />
    </DialogForm>
  )
}

const DocFields = () => {
  const { tags } = useDocs()
  return (
    <>
      <NameField />
      <DescriptionField />
      <TagField tags={tags} />
    </>
  )
}
