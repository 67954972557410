import React from "react"
import {
  CANCEL_UPLOAD_EVENT,
  MediaUploadContext,
  Upload,
  UploadSpec,
  useCustomEventHandler,
} from "../utils"

type UploadState = Upload & { req: XMLHttpRequest }

export const MediaUploader: React.FC = ({ children }) => {
  const [uploads, dispatch] = React.useReducer(uploadsReducer, []),
    value = React.useMemo(
      () => ({
        uploads,
        startUpload: ({ id, url, file }: UploadSpec) => {
          dispatch({
            id,
            type: "add",
            req: beginUpload({ id, url, file, dispatch }),
            url,
            file,
          })
        },
        cancelUpload: (id: string) => dispatch({ id, type: "remove" }),
      }),
      [uploads]
    )

  React.useEffect(() => {
    if (uploads.length) {
      window.onbeforeunload = () => {
        return `You have uploads in progress. Are you sure you want to leave?`
      }
    } else {
      window.onbeforeunload = null
    }
  }, [uploads.length])

  // handle cancel side effects from elsewhere in the app
  useCustomEventHandler<string>(CANCEL_UPLOAD_EVENT, (id) => {
    dispatch({ id, type: "remove" })
  })

  return (
    <MediaUploadContext.Provider value={value}>
      {children}
    </MediaUploadContext.Provider>
  )
}

type UploadProps = UploadSpec & {
  dispatch: React.Dispatch<UploadAction>
}

function beginUpload({ id, url, dispatch, file }: UploadProps) {
  const req = new XMLHttpRequest()
  req.upload.onprogress = (e) =>
    dispatch({ type: "progress", id, progress: e.loaded / e.total })
  req.upload.onerror = (e) => dispatch({ type: "error", id, error: e.type })
  req.open("PUT", url)
  req.send(file)
  return req
}

type UploadAction =
  | {
      type: "add"
      id: string
      req: XMLHttpRequest
      file: File
      url: string
    }
  | {
      type: "remove"
      id: string
    }
  | {
      type: "progress"
      id: string
      progress: number
    }
  | {
      type: "error"
      id: string
      error: string
    }

function uploadsReducer(
  uploads: UploadState[],
  action: UploadAction
): UploadState[] {
  const others = uploads.filter((upload) => upload.id !== action.id)
  switch (action.type) {
    case "add":
      return [
        ...uploads,
        {
          id: action.id,
          req: action.req,
          file: action.file,
          url: action.url,
          progress: 0,
        },
      ]
    case "remove": {
      const thisUpload = uploads.find((u) => u.id === action.id)
      if (thisUpload) thisUpload.req.abort()
      return others
    }
    case "error":
      return updateUpload(uploads, action.id, { error: action.error })
    case "progress":
      if (action.progress == 1) return others
      return updateUpload(uploads, action.id, { progress: action.progress })
  }
}

function updateUpload(
  uploads: UploadState[],
  id: string,
  update: Partial<UploadState>
) {
  const target = uploads.find((upload) => upload.id === id),
    others = uploads.filter((upload) => upload.id !== id)

  if (!target) return uploads

  return [...others, { ...target, ...update }]
}
