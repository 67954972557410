import { cl, Named, useProjectDispatch, useProjectState } from "../utils"
import { ComboSelect, Filter } from "./base"
import clsx from "clsx"

export const SidebarFilterMenu: React.FC<{
  tags: Named[]
  className?: string
}> = ({ tags, className }) => {
  const { tab: tab, filterTagIds } = useProjectState().sidebar,
    dispatch = useProjectDispatch()
  return (
    <ComboSelect
      label="Filter by tag"
      placeholder="Search tags..."
      content={<Filter className="f4" />}
      options={tags}
      selectedIds={filterTagIds}
      onChange={(tagIds) => dispatch({ type: "apply-filter", tab, tagIds })}
      buttonClass={clsx(cl.btnGhost, className)}
      buttonActiveClass={clsx(cl.btnGhostActive, className)}
    />
  )
}
