import React from "react"
import clsx from "clsx"
import { DEFAULT_CODE_COLOR } from "../../utils"
import { Action, Sort } from "./state"

export const CELL_HEIGHT = 35,
  CELL_WIDTH = 100,
  MARK_SCALE_MIN = 0.15,
  MARK_SCALE_MAX = 0.85,
  BASE_CELL = "flex-shrink-0 flex-grow-0",
  BASE_HEADER = "pointer pa1 f6"

export const Row = React.memo<
  React.PropsWithChildren<{ width: number; sticky?: boolean }>
>(({ children, width, sticky }) => (
  <div
    // we set width to ensure row is long enough for sticky labels to work
    style={{
      width,
      position: sticky ? "sticky" : undefined,
      top: 0,
      zIndex: 2,
    }}
    className="flex"
  >
    {children}
  </div>
))

interface CellProps {
  x: number
  y: number
  dispatch: React.Dispatch<Action>
  xId?: string
  yId?: string
  className?: string
  up?: boolean // allowed movement directions...
  down?: boolean
  left?: boolean
  right?: boolean
  focus: number // "level" of focus
  tooltip?: string
  interactive: boolean
  onClick?: () => void
}

export const Cell = React.memo<React.PropsWithChildren<CellProps>>(
  ({
    x,
    y,
    dispatch,
    xId,
    yId,
    className,
    up,
    down,
    left,
    right,
    focus,
    children,
    tooltip,
    interactive,
    onClick,
  }) => {
    return (
      <button
        style={{
          width: !x ? CELL_WIDTH * 2 : CELL_WIDTH,
          height: !y ? CELL_HEIGHT * 2 : CELL_HEIGHT,
          zIndex: !x ? 1 : undefined,
          position: !x ? "sticky" : "relative",
          left: !x ? 0 : undefined,
          borderRadius: 0,
          borderTop: "none",
          borderLeft: "none",
          borderRight: !x ? "2px solid black" : undefined,
          borderBottom: !y ? "2px solid black" : undefined,
          overflow: "hidden",
          textOverflow: "ellipsis",
          outline: "none",
          backgroundColor:
            // moon-gray, custom in-between, near-white
            focus > 1 ? "#cccccc" : focus ? "#dddddd" : "#f4f4f4",
        }}
        className={clsx(
          "hm-cell",
          "f6 db tc bb br b--black-20",
          interactive && "pointer",
          focus ? "black" : "dark-gray",
          className
        )}
        tabIndex={focus === 2 ? 0 : -1}
        onMouseEnter={() => dispatch({ type: "focus", x, y })}
        title={tooltip}
        onClick={() => {
          if (onClick) onClick()
          if (!x && !y) {
            return
          } else if (!x) {
            dispatch({ type: "sort", yId })
          } else if (!y) {
            dispatch({ type: "sort", xId })
          } else if (interactive && x && y) {
            dispatch({ type: "navigate", x, y })
          }
        }}
        onKeyDown={(e) => {
          const handleMove = (
            allowed: boolean | undefined,
            key: string,
            x: number,
            y: number
          ) => {
            if (allowed && e.key === key) {
              e.preventDefault()
              e.stopPropagation()
              dispatch({ type: "focus", x, y, focusDomCell: true })
            }
          }

          handleMove(up, "ArrowUp", x, y - 1)
          handleMove(down, "ArrowDown", x, y + 1)
          handleMove(left, "ArrowLeft", x - 1, y)
          handleMove(right, "ArrowRight", x + 1, y)
        }}
      >
        {children}
      </button>
    )
  }
)

interface HeaderCellProps extends CellProps {
  name: string
  sort?: Sort
}

export const HeaderCell = React.memo<HeaderCellProps>(
  ({ name, sort, ...rest }) => (
    <Cell tooltip={`${name} (click to sort)`} {...rest}>
      {getSortIcon(rest.xId || rest.yId || "", sort)}
      {name}
    </Cell>
  )
)

function getSortIcon(id: string, sort?: Sort) {
  if (!sort || sort.id !== id) return ""
  return sort.asc ? "↑ " : "↓ "
}

interface DataCellProps extends CellProps {
  value: number
  color?: string
  scaleOpacity?: boolean
}

export const DataCell = React.memo<DataCellProps>(
  ({ value, color, scaleOpacity, ...rest }) => (
    <Cell {...rest}>
      <div
        className="br1"
        style={{
          width: scale(
            value,
            CELL_WIDTH * MARK_SCALE_MIN,
            CELL_WIDTH * MARK_SCALE_MAX
          ),
          height: scale(
            value,
            CELL_HEIGHT * MARK_SCALE_MIN,
            CELL_HEIGHT * MARK_SCALE_MAX
          ),
          opacity: scaleOpacity ? scale(value, 0.25, 1) : 1,
          backgroundColor: scaleOpacity
            ? DEFAULT_CODE_COLOR
            : color || DEFAULT_CODE_COLOR,
        }}
      />
    </Cell>
  )
)

function scale(x: number, min: number, max: number) {
  if (!x) return 0
  return min + x * (max - min)
}
