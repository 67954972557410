import React from "react"
import {
  projectReducer,
  defaultProjectState,
  ProjectStateContext,
  ProjectDispatchContext,
  ProjectState,
  useProjectId,
  useAccessToken,
} from "../../utils"

export const ProjectStateProvider: React.FC<{
  initialValue?: Partial<ProjectState>
}> = ({ children, initialValue }) => {
  const key = useProjectStateKey()

  const [state, dispatch] = React.useReducer(projectReducer, {
    ...useInitialProjectState(key),
    ...initialValue,
  })

  React.useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state))
  }, [state, key])
  return (
    <ProjectStateContext.Provider value={state}>
      <ProjectDispatchContext.Provider value={dispatch}>
        {children}
      </ProjectDispatchContext.Provider>
    </ProjectStateContext.Provider>
  )
}

function useProjectStateKey() {
  const id = useProjectId(),
    { userId } = useAccessToken()
  return `project-state-${id}-${userId}`
}

function useInitialProjectState(key: string) {
  const storedState = localStorage.getItem(key)

  if (storedState) {
    try {
      return JSON.parse(storedState) as ProjectState
    } catch (err) {
      return defaultProjectState
    }
  } else {
    return defaultProjectState
  }
}
