import ReactDOM from "react-dom"
import Modal from "react-modal"

import "@szhsin/react-menu/dist/index.css"
import "tachyons/css/tachyons.min.css" // TODO import via HTML link
import "./index.css"
import "./animations.css"

import { App } from "./components/App"

const root = document.getElementById("root")

ReactDOM.render(<App />, root)

Modal.setAppElement(root as HTMLDivElement)

document.body.classList.add("bg-near-white")
