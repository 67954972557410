import React from "react"
import * as yup from "yup"
import { ClientRoute } from "lib"
import { cl, useExec, createAccount } from "../utils"
import { P, RouterLink, H1PageTitle, CenteredPage, Form, Field } from "./base"
import { AuthFooter } from "./AuthFooter"
import { SignUpNextSteps } from "./SignUpNextSteps"

export const SignUp = () => {
  const exec = useExec(),
    [email, setEmail] = React.useState<string | null>(null)

  if (email) return <SignUpNextSteps email={email} />

  return (
    <CenteredPage>
      <H1PageTitle>Sign up for Leaf</H1PageTitle>
      <Form
        values={{ name: "", email: "", password: "", password2: "" }}
        schema={yup.object({
          name: yup.string().required("missing name"),
          email: yup.string().email("invalid email").required("missing email"),
          password: yup
            .string()
            .required("missing password")
            .min(8, "need at least 8 characters"),
          password2: yup
            .string()
            .oneOf([yup.ref("password"), undefined], "passwords don't match")
            .required("please confirm password"),
        })}
        submitText="Create Account"
        classNames={{ button: cl.btn }}
        onSubmit={async (values) => {
          await exec(createAccount, values)
          setEmail(values.email)
        }}
      >
        <Field name="name" label="Full Name" />
        <Field name="email" label="Email Address" />
        <Field name="password" label="Password" type="password" />
        <Field name="password2" label="Confirm Password" type="password" />
        <AuthFooter />
      </Form>
      <P className="tc b">
        Already have an account?{" "}
        <RouterLink to={ClientRoute.SignIn}>Sign in</RouterLink>.
      </P>
    </CenteredPage>
  )
}
