import React from "react"
import { Transcript } from "lib"
import {
  cl,
  useMediaContext,
  getReadableTimestamp,
  acceptTranscript,
} from "../../utils"
import { CommandButton, Info, Input, Footer } from "../base"
import { MediaPlayer } from "../MediaPlayer"
import clsx from "clsx"
import { transcriptToDoc, Speaker } from "./transcript-to-doc"
import { DocPreview } from "../DocPreview"

export interface TranscriptPreviewProps {
  docId: string
  data: Transcript
}

export const TranscriptPreview: React.FC<TranscriptPreviewProps> = ({
  docId,
  data,
}) => {
  const [speakers, setSpeakers] = React.useState(
      getDefaultSpeakers(data.speaker_labels.speakers)
    ),
    { playMedia } = useMediaContext(),
    text = transcriptToDoc(data, speakers)

  React.useEffect(() => {
    const ts = document.querySelectorAll("[data-timestamp]")

    ts.forEach((ts) => {
      const seconds = ts.getAttribute("data-timestamp")
      ts.addEventListener("click", () => {
        if (seconds) playMedia(parseInt(seconds) || 0)
      })
    })
    // include speakers to re-add links on re-render
  }, [playMedia, speakers])
  return (
    <>
      <Info className="mt3 mb4">
        <b>Tip</b>: Before importing, use the fields below to label speakers in
        your document.
      </Info>
      <SpeakerEditor speakers={speakers} onChange={setSpeakers} />
      <DocPreview
        doc={text}
        modifySerializer={(serializer) => {
          serializer.nodes.timestamp = (node) => {
            const { seconds } = node.attrs,
              dom = document.createElement("span")

            dom.className = cl.a
            dom.dataset.timestamp = seconds
            const ts = getReadableTimestamp(seconds.toString())
            dom.innerHTML = `[${ts}]`
            return { dom }
          }
          return serializer
        }}
      />
      <Footer className="mb5">
        <CommandButton
          label="Import Transcript"
          className={cl.btn}
          command={acceptTranscript}
          args={{ docId, text }}
        />
      </Footer>
      <MediaPlayer />
    </>
  )
}

function getDefaultSpeakers(n: number): Speaker[] {
  return Array(n)
    .fill(0)
    .map((_, i) => {
      return { label: "Speaker " + (i + 1) }
    })
}

const SpeakerEditor: React.FC<{
  speakers: Speaker[]
  onChange: (speakers: Speaker[]) => void
}> = ({ speakers, onChange }) => {
  const updateSpeaker = (changes: Partial<Speaker>, index: number) => {
    onChange(
      speakers.map((speaker, i) => {
        if (i === index) {
          return { ...speaker, ...changes }
        } else {
          return speaker
        }
      })
    )
  }

  return (
    <div className="flex flex-column flex-row-ns">
      {speakers.map((speaker, i) => {
        const number = i + 1
        return (
          <SpeakerFormGroup
            key={i}
            label={`Speaker ${number}`}
            value={speaker.label}
            onChange={(label) => updateSpeaker({ label }, i)}
          />
        )
      })}
    </div>
  )
}

const SpeakerFormGroup: React.FC<{
  label: string
  value: string
  onChange: (value: string) => void
  placeholder?: string
}> = ({ label, value, onChange, placeholder }) => (
  <div className="mb3 mr3-ns flex flex-column w-100 w-40-ns">
    <label className={clsx(cl.label, "mb1")} htmlFor={label}>
      {label}
    </label>
    <Input
      id={label}
      value={value}
      placeholder={placeholder}
      className="w-100 border-box"
      onChange={(e) => {
        onChange(e.currentTarget.value)
      }}
    />
  </div>
)
