import React from "react"
import { GetProjectData, extractTags } from "../../utils"
import { DocumentNode, QueryResult, useApolloClient } from "@apollo/client"
import { useProjectId } from "./use-project-id"
import { logErrorIfNotProduction } from "../errors"
import { GET_CODES, GET_DOCS } from "../shared-queries"
import { indexById, sortThingsByName } from "../misc"

export const ProjectContext =
  //@ts-expect-error null ctx
  React.createContext<QueryResult<GetProjectData>>(null)

export function useProject() {
  return React.useContext(ProjectContext)
}

export function useCodes() {
  const result = React.useContext(ProjectContext),
    { data } = result,
    codes = React.useMemo(
      () => sortThingsByName(data ? data.getProject.codes : []),
      [data]
    )

  const tags = React.useMemo(() => {
    return data ? sortThingsByName(extractTags(data.getProject.codes)) : []
  }, [data])

  const codesById = React.useMemo(() => {
    return indexById(data ? data.getProject.codes : [])
  }, [data])

  const refetch = useFetchQueryImperative(GET_CODES)

  return React.useMemo(
    () => ({ ...result, codes, tags, codesById, refetch }),
    [result, codes, tags, refetch, codesById]
  )
}

export function useDocs() {
  const result = React.useContext(ProjectContext),
    { data } = result,
    docs = React.useMemo(
      () => sortThingsByName(data ? data.getProject.docs : []),
      [data]
    )

  const tags = React.useMemo(() => {
    return data ? sortThingsByName(extractTags(data.getProject.docs)) : []
  }, [data])

  const refetch = useFetchQueryImperative(GET_DOCS)

  return React.useMemo(
    () => ({ ...result, docs, tags, refetch }),
    [result, docs, tags, refetch]
  )
}

function useFetchQueryImperative(query: DocumentNode) {
  const client = useApolloClient(),
    projectId = useProjectId()

  return React.useCallback(() => {
    client
      .query({ query, variables: { projectId }, fetchPolicy: "network-only" })
      .catch(logErrorIfNotProduction)
  }, [query, client, projectId])
}
