import React from "react"
import { gql, useLazyQuery } from "@apollo/client"
import {
  MediaContext,
  MediaContextValue,
  GetMediaUrl,
  GetMediaUrlVariables,
  usePrevious,
  useToast,
} from "../../utils"
import {
  MediaPlayerInternalContext,
  MediaPlayerInternalContextValue,
} from "./utils"

const GET_LINK = gql`
  query GetMediaUrl($id: ID!) {
    getMediaUrl(docId: $id)
  }
`

export const MediaProvider: React.FC<{ id: string; available?: boolean }> = ({
  id,
  children,
  available = false,
}) => {
  const [pos, setState] = React.useState<number>(),
    { addToast } = useToast(),
    // this allows playMedia to be used in ProseMirror/non-react context
    availableRef = React.useRef(available),
    playMedia = React.useCallback(
      (pos = 0) => {
        if (availableRef.current) {
          setState(pos)
        } else {
          addToast({ type: "error", message: "No audio available" })
        }
      },
      [addToast]
    ),
    [query, { data, loading, error }] = useLazyQuery<
      GetMediaUrl,
      GetMediaUrlVariables
    >(GET_LINK),
    value: MediaContextValue = React.useMemo(
      () => ({ playMedia, mediaAvailable: available }),
      [playMedia, available]
    ),
    internalValue: MediaPlayerInternalContextValue = React.useMemo(
      () => ({
        pos,
        src: data ? data.getMediaUrl : undefined,
        error,
        setPos: setState,
      }),
      [pos, data, error, setState]
    ),
    previousPos = usePrevious(pos),
    posChanged = pos !== previousPos

  React.useEffect(() => {
    if (id && pos !== undefined && !data && !loading) {
      if (!posChanged && error) return
      query({ variables: { id } })
    }
  }, [id, pos, data, loading, query, error, posChanged])

  React.useEffect(() => {
    if (error) addToast({ type: "error", message: "Error loading audio" })
  }, [error, addToast])

  React.useEffect(() => {
    availableRef.current = available
  }, [available])
  return (
    <MediaContext.Provider value={value}>
      <MediaPlayerInternalContext.Provider value={internalValue}>
        {children}
      </MediaPlayerInternalContext.Provider>
    </MediaContext.Provider>
  )
}
