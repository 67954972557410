import { gql } from "@apollo/client"
import { msg, Noun } from "../../msg"
import { fragments } from "../../graphql-fragments"
import * as Types from "../../types"
import { mutationCommand, toMutationOptions } from "../command"
import { GENERIC_ERROR } from "../../errors"
import { addProjectItem } from "../modify-project"
import { dispatchCustomEvent } from "../../custom-events"
import { CANCEL_UPLOAD_EVENT } from "../../hooks"

const CREATE_DOC_FROM_AUDIO = gql`
  mutation CreateDocFromAudio($projectId: ID!, $filename: String!) {
    createDocFromAudio(projectId: $projectId, filename: $filename) {
      uploadUrl
      doc {
        ...DocDetail
      }
    }
  }

  ${fragments.docDetail}
`

export const createDocFromAudio = mutationCommand<
  { filename: string },
  Types.CreateDocFromAudio,
  Types.CreateDocFromAudioVariables
>({
  getMutationOptions: ({ route }, { filename }) => ({
    mutation: CREATE_DOC_FROM_AUDIO,
    variables: {
      projectId: route.projectId,
      filename,
    },
    update: (cache, { data }) => {
      if (!data) return
      addProjectItem({
        projectId: route.projectId,
        field: "docs",
        cache,
        fragment: fragments.docDetail,
        item: data.createDocFromAudio.doc,
      })
    },
  }),
  messages: (_, { filename }) => msg.start(Noun.Transcription, filename),
})

const REFRESH_TRANSCRIPT = gql`
  mutation RefreshTranscriptionStatus($docId: ID!) {
    refreshTranscriptionStatus(docId: $docId) {
      ...DocDetail
    }
  }

  ${fragments.docDetail}
`

export const refreshTranscriptionStatus = mutationCommand<
  Types.RefreshTranscriptionStatusVariables,
  Types.RefreshTranscriptionStatus
>({
  getMutationOptions: toMutationOptions(REFRESH_TRANSCRIPT),
  messages: () => ({ error: GENERIC_ERROR }),
})

const RETRY_TRANSCRIPT = gql`
  mutation RetryTranscription($docId: ID!, $filename: String!) {
    retryTranscription(docId: $docId, filename: $filename) {
      uploadUrl
      doc {
        ...DocDetail
      }
    }
  }

  ${fragments.docDetail}
`

export const retryTranscription = mutationCommand<
  Types.RetryTranscriptionVariables,
  Types.RetryTranscription
>({
  getMutationOptions: toMutationOptions(RETRY_TRANSCRIPT),
  messages: () => ({ error: GENERIC_ERROR }),
})

const ACCEPT_TRANSCRIPT = gql`
  mutation AcceptTranscript($docId: ID!, $text: JSONObject!) {
    acceptDocTranscript(docId: $docId, text: $text) {
      ...DocDetail
    }
  }

  ${fragments.docDetail}
`

export const acceptTranscript = mutationCommand<
  Types.AcceptTranscriptVariables,
  Types.AcceptTranscript
>({
  getMutationOptions: toMutationOptions(ACCEPT_TRANSCRIPT),
  messages: () => ({ error: GENERIC_ERROR }),
})

const ADD_MEDIA = gql`
  mutation AddMedia($docId: ID!, $filename: String!) {
    addMediaToDoc(docId: $docId, filename: $filename) {
      uploadUrl
      doc {
        ...DocDetail
      }
    }
  }

  ${fragments.docDetail}
`

export const addMediaToDoc = mutationCommand<
  Types.AddMediaVariables,
  Types.AddMedia
>({
  getMutationOptions: toMutationOptions(ADD_MEDIA),
  messages: () => msg.start(Noun.Upload),
})

const REMOVE_MEDIA = gql`
  mutation RemoveMedia($docId: ID!) {
    removeMediaFromDoc(docId: $docId) {
      ...DocDetail
    }
  }

  ${fragments.docDetail}
`

export const removeMediaFromDoc = mutationCommand<
  { docId: string; filename: string },
  Types.RemoveMedia,
  Types.RemoveMediaVariables
>({
  getMutationOptions: (_, { docId }) => ({
    mutation: REMOVE_MEDIA,
    variables: { docId },
  }),
  messages: (_, { filename }) => msg.remove(Noun.Media, filename),
  confirm: true,
  sideEffect: (_, { docId }) => dispatchCustomEvent(CANCEL_UPLOAD_EVENT, docId),
})
