import React from "react"
import { H2, P } from "./typography"
import { Form, Field, yup } from "./Form"
import { cl } from "../../utils"

interface DeleteFormProps {
  deleteNoun: string
  confirmNoun: string
  confirmText: string
  warning: string
  onDelete: () => Promise<unknown>
}

export const DeleteForm: React.FC<DeleteFormProps> = ({
  deleteNoun,
  confirmNoun,
  confirmText,
  warning,
  onDelete,
}) => (
  <div>
    <H2 className="mb3">Delete {deleteNoun}</H2>
    <P className="br1 b--dark-red bg-washed-red ba pa3">
      <b>Warning!</b> {warning}
    </P>
    <Form
      values={{ this: "" }}
      schema={yup.object({
        this: yup
          .string()
          .matches(new RegExp(`^${confirmText}$`))
          .required(),
      })}
      submitText={`Delete ${deleteNoun}`}
      onSubmit={onDelete}
      classNames={{ button: cl.btnDanger }}
    >
      <Field
        name="this"
        label={`Confirm ${confirmNoun}`}
        placeholder={confirmText}
        hideErrors
      />
    </Form>
  </div>
)
