import React from "react"
import { gql, useQuery } from "@apollo/client"
import {
  Narrow,
  H2,
  Alert,
  Spinner,
  H1,
  PageTitle,
  Paragraphs,
  P,
  Button,
  CommandButton,
  X,
  Footer,
} from "./base"
import {
  GetProjectSettings,
  GetProjectSettingsVariables,
  fragments,
  useProjectId,
  useAccessToken,
  cl,
  removeCollaborator,
  useDialog,
  leaveProject,
  cancelInvite,
  sortThingsByName,
} from "../utils"
import { ProjectDelete, ProjectShare, ProjectUpdate } from "./dialogs"
import clsx from "clsx"

const GET_PROJECT = gql`
  query GetProjectSettings($id: ID!) {
    getProject(id: $id) {
      id
      name
      description
      owner {
        id
        name
        email
      }
      collaborators {
        ...Collaborator
      }
      invites {
        ...InviteForOwner
      }
    }
  }

  ${fragments.collaborator}
  ${fragments.inviteForOwner}
`
export const ProjectSettingsTab: React.FC = () => {
  const id = useProjectId()
  return <ProjectSettings id={id} />
}

const ProjectSettings: React.FC<{ id: string }> = ({ id }) => {
  const { data, error } = useQuery<
    GetProjectSettings,
    GetProjectSettingsVariables
  >(GET_PROJECT, { variables: { id }, fetchPolicy: "cache-and-network" })

  return (
    <Narrow>
      <PageTitle>Project Settings</PageTitle>

      {error ? (
        <Alert className="mv3 pa3">
          There was a problem loading your project information.
        </Alert>
      ) : !data ? (
        <Spinner />
      ) : (
        <LoadedSettings {...data.getProject} />
      )}
    </Narrow>
  )
}

const Row: React.FC = ({ children }) => (
  <div className="flex items-center justify-between bt b--black-20">
    {children}
  </div>
)

const LoadedSettings: React.FC<GetProjectSettings["getProject"]> = ({
  id,
  name,
  description,
  owner,
  collaborators,
  invites,
}) => {
  const { userId } = useAccessToken(),
    isOwner = owner.id === userId.toString() ? true : false,
    { show } = useDialog(),
    sortedCollaborators = sortThingsByName(collaborators)
  return (
    <>
      <H1>{name}</H1>
      <Paragraphs text={description || "No description yet."} />
      {isOwner ? (
        <Footer>
          <Button
            label="Edit Details"
            onClick={() => show(ProjectUpdate, { id })}
            className={cl.btnLight}
          />
        </Footer>
      ) : null}

      <H2>Collaborators</H2>

      <P>
        {owner.name} ({owner.email}) — <b>Owner</b>
      </P>
      {sortedCollaborators.map((person) => (
        <Row key={person.id}>
          <P>
            {person.name} ({person.email})
          </P>
          {isOwner ? <RemoveCollabButton {...person} /> : null}
        </Row>
      ))}
      {isOwner ? (
        <>
          {invites.map((invite) => (
            <Row key={invite.id}>
              <P>
                <b>Invited User</b>&nbsp;({invite.email})
              </P>
              <CancelInviteButton id={invite.id} />
            </Row>
          ))}
        </>
      ) : null}

      {isOwner ? (
        <Footer>
          <Button
            label="Share"
            onClick={() => show(ProjectShare, { id })}
            className={cl.btnLight}
          />
        </Footer>
      ) : null}

      <H2>Danger Zone</H2>
      <Alert className="pa3 mv3">
        {isOwner ? (
          <>Deleting a project is permanent and can't be undone.</>
        ) : (
          <>
            If you leave this project, you won't be able to access it unless{" "}
            {owner.name} invites you again.
          </>
        )}
      </Alert>
      <Footer>
        {isOwner ? (
          <Button
            label="Delete Project"
            // hide is false because redirect will unmount dialog provider
            onClick={() => show(ProjectDelete, { id, hideOnDelete: false })}
            className={cl.btnDanger}
          />
        ) : (
          <CommandButton
            label="Leave Project"
            command={leaveProject}
            args={{ id }}
            className={cl.btnDanger}
          />
        )}
      </Footer>
    </>
  )
}

const BTN_CLASS = clsx(cl.btnGhost, "mr2")

const RemoveCollabButton: React.FC<{ id: string; name: string }> = ({
  id,
  name,
}) => {
  return (
    <CommandButton
      label="Remove Collaborator"
      command={removeCollaborator}
      icon={<X />}
      text={null}
      args={{ id, name }}
      className={BTN_CLASS}
    />
  )
}

const CancelInviteButton: React.FC<{ id: string }> = ({ id }) => {
  return (
    <CommandButton
      label="Cancel Invite"
      command={cancelInvite}
      icon={<X />}
      text={null}
      args={{ id }}
      className={BTN_CLASS}
    />
  )
}
