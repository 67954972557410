import { Auth, ClientRoute } from "lib"
import { setToken } from "../../auth"
import { postAuth } from "../../auth-client"
import { msg } from "../../msg"
import { pick } from "../../misc"
import { Command } from "../command"
import {
  GENERIC_ERROR,
  isEmailVerificationError,
  isTokenError,
  showTokenOrGenericErrorMessage,
} from "../../errors"

export const createAccount = authCommand("CreateUser", {
  messages: () => pick(msg.create("account"), ["error"]),
})

export const signIn = authCommand(
  "Login",
  {
    messages: () => ({
      error: (err) =>
        isEmailVerificationError(err)
          ? "Please verify your email."
          : "Incorrect email or password.",
    }),
  },
  (data) => setToken(data.accessToken)
)

export const signOut = authCommand(
  "Logout",
  {
    messages: () => ({ error: GENERIC_ERROR }),
  },
  () => setToken(null)
)

export const resendVerify = authCommand("ResendVerify", {
  messages: () => msg.send("email"),
})

export const requestPasswordReset = authCommand("RequestReset", {
  messages: (_, { email }) => ({
    success: (
      <>
        We've sent a password reset link to <b>{email}</b>.
      </>
    ),
    error: GENERIC_ERROR,
  }),
})

export const resetPassword = authCommand("ResetPassword", {
  messages: () => ({
    ...msg.reset("password"),
    error: showTokenOrGenericErrorMessage,
  }),
  sideEffect: ({ history }) => {
    history.push(ClientRoute.SignIn)
  },
  errorRedirect: (_, __, err) =>
    isTokenError(err) ? ClientRoute.ForgotPassword : null,
})

export const verifyEmail = authCommand(
  "VerifyEmail",
  {
    messages: () => ({
      ...msg.verify("email"),
      error: showTokenOrGenericErrorMessage,
    }),
    errorRedirect: (_, __, err) =>
      isTokenError(err) ? ClientRoute.SignIn : null,
  },
  (data) => setToken(data.accessToken)
)

function authCommand<P extends Auth.PathAlias>(
  path: P,
  options: Omit<Command<Auth.I[P], Auth.O[P]>, "execute">,
  effect?: (data: Auth.O[P]) => void
): Command<Auth.I[P], Auth.O[P]> {
  return {
    ...options,
    execute: async (_, input) => {
      const data = await postAuth<P>(path, input)
      if (effect) effect(data)
      return data
    },
  }
}
