import { CollabEditorToolbarComponent } from "../CollabEditor"
import { PlayMediaButton } from "../PlayMediaButton"
import { UndoRedo, ToolbarSpacer } from "../ProseMirrorWidgets"

export const PassagesToolbar: CollabEditorToolbarComponent = () => {
  return (
    <>
      <UndoRedo />
      <ToolbarSpacer />
      <PlayMediaButton />
    </>
  )
}
