import React from "react"
import clsx from "clsx"
import { cl } from "../../utils"

interface TabsProps {
  tabs: TabProps[]
  className?: string
  activeIndex: number
  onChange: (index: number) => void
}

interface TabProps {
  name: string
  render: () => React.ReactChild
}

export const Tabs: React.FC<TabsProps> = ({
  tabs,
  className,
  activeIndex,
  onChange,
}) => {
  return (
    <>
      <div
        className={clsx(
          "flex items-center bb b--black-20 overflow-x-scroll overflow-auto-ns",
          className
        )}
      >
        {tabs.map((tab, i) => (
          <div
            key={tab.name}
            onClick={() => onChange(i)}
            className={clsx(
              "f5 fw6  bw2 bb pa2 pointer mr3 nowrap",
              activeIndex === i
                ? "mid-gray b--mid-gray"
                : clsx(cl.a, "b--transparent")
            )}
          >
            {tab.name}
          </div>
        ))}
      </div>
      {tabs[activeIndex].render()}
    </>
  )
}
