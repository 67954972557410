import React from "react"
import clsx from "clsx"
import { Link as ReactRouterLink, LinkProps } from "react-router-dom"
import { cl, TabDataType, useProjectDispatch } from "../../utils"

export const RouterLink: React.FC<LinkProps> = (props) => (
  <ReactRouterLink {...props} className={clsx(cl.a, props.className)} />
)

type ProjectLinkProps = {
  id: string
  dataType: TabDataType
} & React.HTMLAttributes<HTMLSpanElement>

export const TabLink = React.forwardRef<HTMLSpanElement, ProjectLinkProps>(
  ({ id, dataType, children, ...props }, ref) => {
    const dispatch = useProjectDispatch()

    return (
      <span
        {...props}
        onClick={() => {
          dispatch({ type: "open-tab", id, dataType })
        }}
        className={clsx(cl.a, props.className)}
        ref={ref}
      >
        {children}
      </span>
    )
  }
)
