import { getEnv } from "lib"

export function isEmailVerificationError(error: Error) {
  return error.message.match(/email.*veri/i)
}

export function isTokenError(error: Error) {
  return error.message.match(/expire/i)
}

export const TOKEN_ERROR = "Your link has expired. Please request a new one."
export const GENERIC_ERROR = "Something went wrong. Please try again."

export function showTokenOrGenericErrorMessage(error: Error) {
  return isTokenError(error) ? TOKEN_ERROR : GENERIC_ERROR
}

export function logErrorIfNotProduction(error: Error) {
  if (getEnv() !== "production") console.error(error)
}
