import React from "react"

export type ConfirmContextValue = (state: ConfirmSpec) => void

//@ts-expect-error null ctx
export const ConfirmationContext = React.createContext<ConfirmContextValue>()

export function useRequestConfirmation() {
  return React.useContext(ConfirmationContext)
}

export interface ConfirmSpec<T extends SomeAction = SomeAction> {
  action: T
  message: React.ReactChild
}

type SomeAction = (...args: unknown[]) => Promise<unknown>
