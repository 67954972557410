import clsx from "clsx"
import React from "react"
import { cl } from "../../utils"
import { H2, Spinner, Alert } from "../base"
import { parseCSV } from "./parse-csv"
import { ImportType, ParsedData } from "./utils"
import { getEnv } from "lib"
import { ImportButton } from "./ImportButton"
import { DocPreview } from "../DocPreview"

interface PreviewerProps {
  files: File[]
  type: ImportType
}

export const Previewer: React.FC<PreviewerProps> = ({ type, files }) => {
  const [parsed, setParsed] = React.useState<ParsedData | null | Error>(null),
    [nameColumnIndex, setNameColumnIndex] = React.useState(0),
    [docIndex, setDocIndex] = React.useState(0)

  // reset when files change
  React.useEffect(() => setNameColumnIndex(0), [files])
  React.useEffect(() => {
    parseFiles(type, files, nameColumnIndex)
      .then((result) => setParsed(result))
      .catch((err) => {
        if (getEnv() !== "production") console.error(err)
        setParsed(err)
      })
  }, [type, files, nameColumnIndex])
  return (
    <>
      <H2 className="mb2 mt4">Preview &amp; Import Documents</H2>
      {!parsed ? (
        <Spinner />
      ) : parsed instanceof Error ? (
        <Alert className="pa3 mv3">There was a problem opening a file.</Alert>
      ) : (
        <>
          {type === "csv" ? (
            <Selector
              label="Select Name Column"
              names={parsed.columns || []}
              index={nameColumnIndex}
              onChange={setNameColumnIndex}
            />
          ) : null}
          <Selector
            label="Select a Document to Preview"
            names={parsed.docs.map((doc) => doc.name)}
            index={docIndex}
            onChange={setDocIndex}
          />

          <DocPreview doc={parsed.docs[docIndex].text} />

          <ImportButton docs={parsed.docs} />
        </>
      )}
    </>
  )
}

interface SelectorProps {
  label: string
  names: string[]
  index: number
  onChange: (index: number) => void
}

function Selector({ label, names, index, onChange }: SelectorProps) {
  return (
    <div className="mb3">
      <label className={clsx(cl.label, "db mb2")}>{label}</label>
      <select
        value={names[index]}
        className={clsx(cl.input, "db w-100")}
        onChange={(e) => onChange(e.target.selectedIndex)}
      >
        {names.map((name, i) => (
          <option key={i}>{name}</option>
        ))}
      </select>
    </div>
  )
}

async function parseFiles(
  type: ImportType,
  files: File[],
  nameColumnIndex: number
): Promise<ParsedData> {
  const { parseDocx } = await import("./parse-docx")
  switch (type) {
    case "docx":
      return {
        docs: await Promise.all(files.map((file) => parseDocx(file))),
        codes: [],
      }
    case "csv":
      return parseCSV(files[0], nameColumnIndex)
    default:
      throw new Error("invalid type")
  }
}
