import { Redirect, Route, Switch } from "react-router-dom"
import { ApolloProvider } from "./ApolloProvider"
import { DialogProvider } from "./DialogProvider"
import { Projects } from "./Projects"
import { MediaUploader } from "./MediaUploader"
import { UserNotifier } from "./UserNotifier"
import { Project } from "./Project"

export const AppProtected: React.FC = () => {
  return (
    <ApolloProvider>
      <UserNotifier />
      <MediaUploader>
        <DialogProvider>
          <Switch>
            <Route path="/projects" exact component={Projects} />
            <Route path="/projects/:projectId" component={Project} />
            <Redirect to="/projects" />
          </Switch>
        </DialogProvider>
      </MediaUploader>
    </ApolloProvider>
  )
}
