import React from "react"
import clsx from "clsx"
import { cl } from "../../utils"
import { Button, LeftRight } from "../base"
import { Action } from "./state"

interface SeriesChooserProps {
  xSeriesIndex: number
  ySeriesIndex: number
  hideEmpty: boolean
  dispatch: React.Dispatch<Action>
}

export const SeriesChooser: React.FC<SeriesChooserProps> = ({
  xSeriesIndex,
  ySeriesIndex,
  hideEmpty,
  dispatch,
}) => {
  return (
    <div className="flex flex-wrap items-end flex-row-ns mh2 mh0-ns mv4">
      <SeriesSelect
        index={ySeriesIndex}
        onChange={(y) => dispatch({ type: "set-series", y })}
        otherIndex={xSeriesIndex}
        label="Choose Rows"
        id="select-rows"
      />
      <Button
        icon={<LeftRight />}
        label="Swap rows and columns"
        tooltip
        text={null}
        disabled={xSeriesIndex === ySeriesIndex}
        onClick={() => dispatch({ type: "swap" })}
        className={clsx(cl.btnGhost, "mh2 mb1")}
      />
      <SeriesSelect
        index={xSeriesIndex}
        onChange={(x) => dispatch({ type: "set-series", x })}
        otherIndex={ySeriesIndex}
        label="Choose Columns"
        id="select-cols"
        className="mt3 mt0-ns"
      />
      <div className="h2 nb1 ml0 ml4-ns mt3 mt0-ns">
        <input
          type="checkbox"
          id="hide-empty"
          className="mr2"
          checked={hideEmpty}
          onChange={() => dispatch({ type: "toggle-hide-empty" })}
        />
        <label htmlFor="hide-empty" className="mid-gray f5">
          Hide empty rows and columns
        </label>
      </div>
    </div>
  )
}

interface SeriesSelectProps {
  index: number
  otherIndex: number
  label: string
  id: string
  onChange: (index: number) => void
  className?: string
}

const seriesOptions = ["Documents", "Document Tags", "Codes", "Code Tags"]

const SeriesSelect: React.FC<SeriesSelectProps> = ({
  index,
  otherIndex,
  onChange,
  className,
  label,
  id,
}) => (
  <div className={clsx(className, "flex flex-column")}>
    <label className={clsx(cl.label, "mb1")} htmlFor={id}>
      {label}
    </label>
    <select
      id={id}
      value={index}
      onChange={(e) => onChange(e.target.selectedIndex)}
      className={cl.input}
    >
      {seriesOptions.map((serie, i) => (
        <option
          key={i}
          value={i}
          // Disallow selecting docs for both selects
          disabled={otherIndex < 2 && i < 2}
        >
          {serie}
        </option>
      ))}
    </select>
  </div>
)
