import React from "react"

export function useCustomEventHandler<T = unknown>(
  name: string,
  handler: (detail: T) => void
) {
  React.useEffect(() => {
    const onEvent = (e: Event) => handler((e as CustomEvent).detail)
    window.addEventListener(name, onEvent)
    return () => window.removeEventListener(name, onEvent)
  }, [name, handler])
}
