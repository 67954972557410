import React from "react"
import {
  getFindState,
  findInDocument,
  findNextMatch,
  findPreviousMatch,
  deactivateFind,
} from "editor"
import { Input, useCommand, ArrowLeft, ArrowRight } from "../base"
import { ProseMirrorButton, useViewOrFail } from "./utils"

export const FindControls: React.FC = () => {
  const view = useViewOrFail(),
    nextMatch = useCommand(findNextMatch),
    closeFind = useCommand(deactivateFind),
    active = view ? getFindState(view.state).active : false,
    inputRef = React.useRef<HTMLInputElement | null>(null)

  React.useEffect(() => {
    if (active && inputRef.current) inputRef.current.focus()
  }, [active])

  const { query, index, findStringInNode } = getFindState(view.state),
    matches = findStringInNode(view.state.doc, query)

  return (
    <>
      <Input
        {...{ form: undefined, field: undefined }} // workaround for Formik types
        id="find-input"
        value={query}
        placeholder="Find in document..."
        aria-label="Find in document..."
        className="f5 mr1 pv1"
        onKeyDown={(e) => {
          if (e.key == "Enter") nextMatch()
          if (e.key == "Escape") closeFind()
        }}
        onChange={(e) => {
          findInDocument(e.currentTarget.value)(view.state, view.dispatch)
        }}
        ref={inputRef}
      />
      <div
        className="mr1 mid-gray dn flex-ns items-center justify-around"
        style={{ width: "5rem" }}
      >
        {index === null ? "—" : index + 1} / {matches.length || "—"}
      </div>
      <ProseMirrorButton
        execute={findPreviousMatch}
        icon={<ArrowLeft />}
        label="Previous Match"
        className="mr1"
      />
      <ProseMirrorButton
        execute={findNextMatch}
        icon={<ArrowRight />}
        label="Next Match"
      />
    </>
  )
}
