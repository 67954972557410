import React from "react"
import { randomId } from "../misc"

export type ToastType = "success" | "error" | "warn" | "info"

export type MessageText = React.ReactElement | React.ReactText

export interface ToastSpec {
  id: string
  message: MessageText
  type: ToastType
}

export interface ToastContextValue {
  toasts: ToastSpec[]
  addToast: (spec: ToastSpec) => void
  dismissToast: (id: string) => void
}

export const ToastContext =
  //@ts-expect-error null ctx
  React.createContext<ToastContextValue>(null)

export function useToast() {
  const { addToast, dismissToast } = React.useContext(ToastContext)
  return React.useMemo(
    () => ({
      addToast: (spec: Omit<ToastSpec, "id">) =>
        addToast({ ...spec, id: randomId() }),
      dismissToast,
    }),
    [addToast, dismissToast]
  )
}
