import React from "react"
import { cl, importDocs } from "../../utils"
import { CommandButton, Footer } from "../base"
import { ParsedDoc } from "./utils"

export const ImportButton: React.FC<{ docs: ParsedDoc[]; disabled?: boolean }> =
  ({ docs, disabled = false }) => (
    <Footer>
      <CommandButton
        label={`Import ${docs.length} Document(s)`}
        className={cl.btn}
        disabled={!docs.length || disabled}
        command={importDocs}
        args={{ docs }}
        closeDialog
      />
    </Footer>
  )
