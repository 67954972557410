import clsx from "clsx"
import React from "react"
import { Button, ButtonProps } from "../Button"
import {
  RovingTabIndexProvider,
  useRovingTabIndexChildProps,
} from "../RovingTabIndexProvider"

interface ToolbarProps {
  classNames?: ToolbarClassNames
  ariaControls?: string
  style?: React.CSSProperties
}

interface ToolbarContextValue {
  classNames: ToolbarClassNames
}

interface ToolbarClassNames {
  toolbar?: string
  button?: string
  divider?: string
}

//@ts-expect-error null context
const ToolbarContext = React.createContext<ToolbarContextValue>(null)

export const Toolbar: React.FC<ToolbarProps> = ({
  ariaControls,
  classNames = {},
  children,
  style,
}) => {
  return (
    <div
      role="toolbar"
      className={clsx("flex items-stretch", classNames.toolbar)}
      aria-controls={ariaControls}
      style={style}
    >
      <RovingTabIndexProvider>
        <ToolbarContext.Provider value={{ classNames }}>
          {children}
        </ToolbarContext.Provider>
      </RovingTabIndexProvider>
    </div>
  )
}

export interface ToolbarButtonProps extends ButtonProps {
  index?: number
}

export const ToolbarButton: React.FC<ToolbarButtonProps> = ({
  className,
  ...rest
}) => {
  const { classNames } = React.useContext(ToolbarContext),
    { onClick, onKeyDown, ...roveRest } = useRovingTabIndexChildProps()

  return (
    <Button
      {...rest}
      {...roveRest}
      className={clsx(classNames.button, className)}
      onClick={(e) => {
        onClick()
        if (rest.onClick) rest.onClick(e)
      }}
      onKeyDown={(e) => {
        onKeyDown(e)
        if (rest.onKeyDown) rest.onKeyDown(e)
      }}
    />
  )
}

export const ToolbarDivider: React.FC<{ className?: string }> = ({
  className,
}) => {
  const { classNames } = React.useContext(ToolbarContext)

  return <div className={clsx(classNames.divider, className)} />
}
