import React from "react"
import {
  Menu,
  MenuItem,
  MenuButton,
  MenuDivider,
  MenuGroup,
  FocusableItem,
  MenuAlign,
  MenuDirection,
} from "@szhsin/react-menu"
import { ascending } from "lib"
import { cl, Named, toggle } from "../../utils"
import { MenuCaret } from "./ActionMenu"
import clsx from "clsx"
import { X, CheckBox, Square } from "./Icons"

import "./ComboSelect.css"

export interface ComboSelectPositionProps {
  align?: MenuAlign
  direction?: MenuDirection
}

interface ComboSelectProps extends ComboSelectPositionProps {
  label: string
  options: Named[]
  selectedIds: string[]
  onChange: (ids: string[]) => void
  content?: React.ReactNode
  className?: string
  placeholder?: string
  extra?: () => React.ReactChild
  buttonClass?: string
  buttonActiveClass?: string
}

export const ITEM_CLASS = "flex items-center dark-gray ph3"
export const ICON_CLASS = "mr2 f4 mid-gray"

export const ComboSelect: React.FC<ComboSelectProps> = ({
  label,
  content = (
    <>
      {label} <MenuCaret />
    </>
  ),
  options,
  selectedIds,
  onChange,
  className,
  align,
  direction,
  placeholder = "Search...",
  extra,
  buttonClass = cl.btnLight,
  buttonActiveClass = cl.btnLightActive,
}) => {
  const [search, setSearch] = React.useState(""),
    visibleItems = options
      .filter((option) => {
        if (search) {
          return option.name.toLowerCase().includes(search.trim().toLowerCase())
        } else {
          return true
        }
      })
      .sort((a, b) => ascending(a.name.toLowerCase(), b.name.toLowerCase()))

  const anySelected = selectedIds.length > 0

  useSelectionValidation(options, selectedIds, onChange)

  return (
    <Menu
      onItemClick={(e) => (e.keepOpen = true)}
      className="combo-select f5"
      align={align}
      direction={direction}
      overflow="auto"
      boundingBoxPadding="10px"
      portal
      menuButton={
        <MenuButton
          aria-label={label}
          title={label}
          className={clsx(
            className,
            selectedIds.length ? buttonActiveClass : buttonClass
          )}
        >
          {content}
        </MenuButton>
      }
    >
      <FocusableItem className="pa0 dark-gray">
        {({ ref }) => (
          <input
            ref={ref}
            placeholder={placeholder}
            value={search}
            className="ph3 pv2 bn"
            onChange={(e) => setSearch(e.target.value)}
          />
        )}
      </FocusableItem>
      <MenuDivider />
      {extra && extra()}
      {extra && <MenuDivider />}
      <MenuItem
        type="checkbox"
        onClick={(e) => {
          e.stopPropagation = true
          e.keepOpen = true
          if (anySelected) {
            onChange([])
          } else {
            onChange(options.map((opt) => opt.id))
          }
        }}
        className={ITEM_CLASS}
      >
        {anySelected ? (
          <>
            <X className={ICON_CLASS} /> Clear Selection
          </>
        ) : (
          <>
            <Square className={ICON_CLASS} /> Select All
          </>
        )}
      </MenuItem>
      <MenuDivider />
      <MenuGroup takeOverflow>
        {visibleItems.map((item) => {
          const checked = selectedIds.includes(item.id),
            Icon = checked ? CheckBox : Square
          return (
            <MenuItem
              key={item.id}
              type="checkbox"
              checked={checked}
              onClick={(e) => {
                e.stopPropagation = true
                e.keepOpen = true
                onChange(toggle(selectedIds, item.id))
              }}
              className={ITEM_CLASS}
            >
              <Icon className={ICON_CLASS} /> {item.name}
            </MenuItem>
          )
        })}
      </MenuGroup>
      {!visibleItems.length && (
        <FocusableItem className={ITEM_CLASS.replace("dark", "mid")}>
          {({ ref }) => <span ref={ref}>No items to show.</span>}
        </FocusableItem>
      )}
    </Menu>
  )
}

function useSelectionValidation(
  items: Named[],
  ids: string[],
  onChange: (ids: string[]) => void
) {
  React.useEffect(() => {
    const optionIds = items.map((item) => item.id),
      validIds = ids.filter((id) => optionIds.includes(id))

    if (validIds.length != ids.length) {
      onChange(validIds)
    }
  }, [items, ids, onChange])
}
