import { Alert } from "./Alert"
import { Spinner } from "./Spinner"

type Message = React.ReactElement | React.ReactText

interface LoaderProps<T> {
  loading: boolean
  error?: Error
  message: Message | ((err: Error) => Message)
  data?: T
  children: (data: T) => React.ReactElement
}

export function Loader<T>({
  loading,
  error,
  message,
  data,
  children,
}: LoaderProps<T>) {
  if (loading) {
    return <Spinner />
  } else if (error) {
    const text = typeof message === "function" ? message(error) : message
    return <Alert>{text}</Alert>
  } else if (data) {
    return children(data)
  } else {
    return null
  }
}

export const ProgressBar: React.FC<{ progress: number; label: string }> = ({
  progress,
  label,
}) => {
  return (
    <div className="mv3">
      <div className="mid-gray fw6 f6 mb1">{label}</div>
      <div className="br2 overflow-hidden ba bw1 b--dark-gray">
        <div
          style={{ width: progress * 100 + "%" }}
          className="h1 ba b--white bg-dark-gray"
        />
      </div>
    </div>
  )
}
