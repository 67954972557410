import { csvParse } from "d3-dsv"
import { Node, schema } from "lib"
import { ParsedData, ParsedDoc } from "../utils"

export async function parseCSV(
  csvFile: File,
  nameColumnIndex: number
): Promise<ParsedData> {
  const csvString = await readFileAsString(csvFile)
  return parseCSVText(csvString, nameColumnIndex)
}

export async function readFileAsString(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = () => reject(reader.error)
    reader.readAsText(file)
  })
}

export function parseCSVText(
  csvString: string,
  nameColumnIndex: number
): ParsedData {
  const rows = csvParse(csvString),
    { columns } = rows,
    docs: ParsedDoc[] = []

  rows.forEach((row) => {
    const doc = schema.node(
      "doc",
      undefined,
      columns.reduce((content, colName) => {
        return [
          ...content,
          paragraph(colName, true),
          paragraph(row[colName] || "[blank]", false),
        ]
      }, [] as Node[])
    )
    const name = row[columns[nameColumnIndex]] || ""
    docs.push({ name, text: doc })
  })

  return {
    docs,
    codes: [],
    columns,
  }
}

function paragraph(text: string, bold: boolean) {
  return schema.node(
    "paragraph",
    undefined,
    schema.text(text, bold ? [schema.marks.strong.create()] : [])
  )
}
