import clsx from "clsx"
import React from "react"
import {
  cl,
  Named,
  useCodes,
  useDocs,
  useContextMenu,
  useProjectDispatch,
  useProjectState,
  TabDataType,
  TabState,
  usePrevious,
  useTabData,
} from "../../utils"
import { action, X } from "../base"
import { iconsByDataType, tabTitles } from "./TabWidgets"

export const Tabs: React.FC = () => {
  const { tabs } = useProjectState(),
    previousTabs = usePrevious(tabs),
    tabData = useTabData()

  React.useEffect(() => {
    const removedTabs = (previousTabs || []).filter((old) => {
      return !tabs.find(
        (tab) => tab.id === old.id && tab.dataType === old.dataType
      )
    })
    removedTabs.forEach((tab) => tabData.clear(tab.id, tab.dataType))
  }, [tabData, tabs, previousTabs])

  return (
    <div className="flex items-center bg-moon-gray overflow-hidden flex-shrink-0">
      {tabs.map((tab, i) => (
        <Tab key={`${tab.dataType}-${tab.id}`} {...tab} index={i} />
      ))}
    </div>
  )
}

const Tab: React.FC<TabState & { index: number }> = ({
  id,
  dataType,
  index,
}) => {
  const { codes } = useCodes(),
    { docs } = useDocs(),
    { activeTabIndex } = useProjectState(),
    dispatch = useProjectDispatch(),
    items: (Named & { color?: string })[] =
      dataType === TabDataType.Code
        ? codes
        : dataType === TabDataType.Doc
        ? docs
        : tabTitles,
    item = items.find((item) => item.id === id),
    active = index === activeTabIndex,
    name = item?.name || "(deleted)",
    onContextMenu = useContextMenu(),
    [draggedOver, setDraggedOver] = React.useState(false),
    Icon = iconsByDataType[dataType]

  return (
    <div
      data-testid="tab"
      aria-label={name}
      className={clsx(
        "f6 mid-gray flex items-center pl3 pr1 br bb b--moon-gray hide-child truncate pointer",
        draggedOver ? "bg-white" : active ? "bg-near-white" : "bg-light-gray"
      )}
      style={{ borderBottomColor: active ? "#f4f4f4" : undefined, minWidth: 0 }}
      onClick={() => {
        dispatch({ type: "select-tab", index })
      }}
      onContextMenu={(e) => {
        onContextMenu(e, [
          action("Close Tab", () => dispatch({ type: "close-tab", index })),
          action("Close Others", () =>
            dispatch({ type: "close-other-tabs", index })
          ),
          action("Close Tabs to Right", () =>
            dispatch({ type: "close-tabs-to-right", index })
          ),
          action("Close All Tabs", () => dispatch({ type: "close-all-tabs" })),
        ])
      }}
      draggable
      onDragStart={(e) => {
        e.dataTransfer.setData("text", index.toString())
      }}
      onDragOver={(e) => {
        const dragIndex = parseInt(e.dataTransfer.getData("text"))
        if (dragIndex !== index) {
          e.preventDefault()
          if (!draggedOver) setDraggedOver(true)
        }
      }}
      onDragLeave={(e) => {
        e.preventDefault()
        if (draggedOver) setDraggedOver(false)
      }}
      onDrop={(e) => {
        e.preventDefault()
        const dragIndex = parseInt(e.dataTransfer.getData("text"))
        if (draggedOver) setDraggedOver(false)
        dispatch({
          type: "sort-tabs",
          dragIndex,
          dropIndex: index,
        })
      }}
    >
      <button
        title={name}
        aria-label={name}
        className="bg-transparent pointer ph0 truncate mid-gray bn flex items-center"
      >
        {Icon && (
          <Icon className="mr1 flex-shrink-0" style={{ color: item?.color }} />
        )}
        <span className="pv2 truncate">{name}</span>
      </button>
      <button
        className={cl.btnGhost.replace("pa2", "pa1 ml1 child flex-shrink-0")}
        aria-label="Close tab"
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          dispatch({ type: "close-tab", index })
        }}
      >
        <X />
      </button>
    </div>
  )
}
