import { ApolloCache, gql } from "@apollo/client"
import {
  InviteCollaborator,
  InviteCollaboratorVariables,
  CancelInvite,
  CancelInviteVariables,
  RemoveCollaborator,
  RemoveCollaboratorVariables,
  AcceptInvite,
  AcceptInviteVariables,
  RejectInvite,
  RejectInviteVariables,
  LeaveProject,
  LeaveProjectVariables,
} from "../../types"
import { msg, Noun } from "../../msg"
import { fragments } from "../../graphql-fragments"
import { mutationCommand } from "../command"
import {
  addProjectItem,
  addProjectToCache,
  removeProjectFromCache,
  removeProjectItems,
} from "../modify-project"

// inviter commands

const INVITE_COLLABORATOR = gql`
  mutation InviteCollaborator($projectId: ID!, $email: String!) {
    inviteCollaborator(projectId: $projectId, email: $email) {
      ...InviteForOwner
    }
  }

  ${fragments.inviteForOwner}
`

export const inviteCollaborator = mutationCommand<
  InviteCollaboratorVariables,
  InviteCollaborator
>({
  messages: () => msg.send(Noun.Invite, 1),
  getMutationOptions: (_, { projectId, email }) => ({
    mutation: INVITE_COLLABORATOR,
    variables: { projectId, email },
    update: (cache, { data }) => {
      if (!data) return
      addProjectItem({
        projectId,
        field: "invites",
        cache,
        fragment: fragments.inviteForOwner,
        item: data.inviteCollaborator,
      })
    },
  }),
})

const CANCEL_INVITE = gql`
  mutation CancelInvite($id: ID!) {
    cancelInvite(id: $id)
  }
`

export const cancelInvite = mutationCommand<
  CancelInviteVariables,
  CancelInvite
>({
  messages: () => msg.cancel(Noun.Invite),
  getMutationOptions: ({ route }, input) => ({
    mutation: CANCEL_INVITE,
    variables: { id: input.id },
    update: (cache, { data }) => {
      if (!data) return
      removeProjectItems({
        projectId: route.projectId,
        field: "invites",
        cache,
        ids: [input.id],
      })
    },
  }),
})

const REMOVE_COLLABORATOR = gql`
  mutation RemoveCollaborator($projectId: ID!, $userId: ID!) {
    removeCollaborator(projectId: $projectId, userId: $userId)
  }
`

export const removeCollaborator = mutationCommand<
  { id: string; name: string },
  RemoveCollaborator,
  RemoveCollaboratorVariables
>({
  messages: (_, { name }) => msg.remove(Noun.Collaborator, name),
  confirm: true,
  getMutationOptions: ({ route }, input) => ({
    mutation: REMOVE_COLLABORATOR,
    variables: {
      projectId: route.projectId,
      userId: input.id,
    },
    update: (cache, { data }) => {
      if (!data) return
      removeProjectItems({
        projectId: route.projectId,
        field: "collaborators",
        cache,
        ids: [input.id],
      })
    },
  }),
})

// invitee commands

const ACCEPT_INVITE = gql`
  mutation AcceptInvite($id: ID!) {
    acceptInvite(id: $id) {
      ...ProjectPreview
    }
  }

  ${fragments.projectPreview}
`

export const acceptInvite = mutationCommand<
  AcceptInviteVariables,
  AcceptInvite
>({
  messages: () => msg.accept(Noun.Invite),
  getMutationOptions: (_, input) => ({
    mutation: ACCEPT_INVITE,
    variables: input,
    update: (cache, { data }) => {
      if (data) addProjectToCache(cache, data.acceptInvite)
      removeUserInvite(cache, input.id)
    },
  }),
})

const REJECT_INVITE = gql`
  mutation RejectInvite($id: ID!) {
    rejectInvite(id: $id)
  }
`
export const rejectInvite = mutationCommand<
  RejectInviteVariables,
  RejectInvite
>({
  messages: () => msg.reject(Noun.Invite),
  getMutationOptions: (_, input) => ({
    mutation: REJECT_INVITE,
    variables: input,
    update: (cache) => removeUserInvite(cache, input.id),
  }),
})

function removeUserInvite(cache: ApolloCache<unknown>, id: string) {
  return cache.modify({
    fields: {
      listInvites: (invites, { readField }) => {
        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        return invites.filter((item: any) => readField("id", item) !== id)
      },
    },
  })
}

const LEAVE_PROJECT = gql`
  mutation LeaveProject($id: ID!) {
    leaveProject(id: $id)
  }
`
export const leaveProject = mutationCommand<
  LeaveProjectVariables,
  LeaveProject
>({
  messages: () => ({
    success: "Left project.",
    error: "Oops! Something went wrong.",
    confirm: "If you leave this project, the owner will need to re-invite you.",
  }),
  getMutationOptions: (_, input) => ({
    mutation: LEAVE_PROJECT,
    variables: input,
    update: (cache) => removeProjectFromCache(cache, input.id),
  }),
  confirm: true,
  sideEffect: ({ history, route }, { id }) => {
    if (route.projectId === id) history.push("/projects")
  },
})
