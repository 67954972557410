export enum SeriesType {
  Docs = 0,
  DocTags = 1,
  Codes = 2,
  CodeTags = 3,
}

export interface MatrixState {
  x: SeriesState
  y: SeriesState
  focus: Position
  shouldFocusDomCell: boolean
  navigateFrom?: Position
  hideEmpty: boolean
}

interface SeriesState {
  series: SeriesType
  sort?: Sort
}

export interface Sort {
  id: string
  asc: boolean
}

interface Position {
  x: number
  y: number
}

export const initialState: MatrixState = {
  x: {
    series: SeriesType.Codes,
  },
  y: {
    series: SeriesType.Docs,
  },
  focus: { x: 0, y: 0 },
  shouldFocusDomCell: false,
  hideEmpty: false,
}

export type Action =
  | {
      type: "swap"
    }
  | {
      type: "set-series"
      x?: SeriesType
      y?: SeriesType
    }
  | {
      type: "sort"
      xId?: string
      yId?: string
    }
  | {
      type: "focus"
      x: number
      y: number
      focusDomCell?: boolean
    }
  | {
      type: "report-dom-cell-focused"
    }
  | {
      type: "navigate"
      x: number
      y: number
    }
  | {
      type: "toggle-hide-empty"
    }

export function matrixReducer(state: MatrixState, action: Action): MatrixState {
  switch (action.type) {
    case "swap": {
      const { x, y } = state
      return { ...state, x: y, y: x }
    }
    case "set-series": {
      const { x, y } = action
      return {
        ...state,
        x: x !== undefined ? { series: x } : state.x,
        y: y !== undefined ? { series: y } : state.y,
      }
    }
    case "sort": {
      const { xId, yId } = action
      return {
        ...state,
        x: getNewSeriesStateForSort(state.x, xId),
        y: getNewSeriesStateForSort(state.y, yId),
      }
    }
    case "focus":
      return {
        ...state,
        focus: { x: action.x, y: action.y },
        shouldFocusDomCell: action.focusDomCell || false,
      }
    case "report-dom-cell-focused":
      return { ...state, shouldFocusDomCell: false }
    case "navigate":
      return { ...state, navigateFrom: { x: action.x, y: action.y } }
    case "toggle-hide-empty":
      return { ...state, hideEmpty: !state.hideEmpty }
    default:
      return state
  }
}

function getNewSeriesStateForSort(
  state: SeriesState,
  id?: string
): SeriesState {
  if (!id) return state
  return {
    ...state,
    sort: getNewSort(id, state.sort),
  }
}

function getNewSort(id: string, existing?: Sort): Sort | undefined {
  if (!existing || id !== existing.id) {
    return { id, asc: false }
  } else if (existing.asc) {
    return undefined
  } else {
    return { id, asc: true }
  }
}
