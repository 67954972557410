import clsx from "clsx"
import { cl, useMediaContext } from "../utils"
import { Button, Play } from "./base"

export const PlayMediaButton: React.FC<{ className?: string }> = ({
  className,
}) => {
  const { playMedia, mediaAvailable } = useMediaContext()

  return (
    <Button
      className={clsx(cl.btnLight, className)}
      disabled={!mediaAvailable}
      label="Open media player"
      text={null}
      tooltip={mediaAvailable ? "Play audio" : "No audio available"}
      onClick={() => playMedia(0)}
      icon={<Play />}
    />
  )
}
