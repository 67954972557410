import clsx from "clsx"
import {
  cl,
  Named,
  useCodes,
  useDocs,
  withTabStateProvider,
  useTabState,
} from "../utils"
import {
  Button,
  DataLoadingError,
  PageTitle,
  Spinner,
  ContentPageContainer,
} from "./base"
import { CodeReport } from "./CodeReport"
import { useEdges } from "./EdgeProvider"
import { TaggablePicker } from "./TaggablePicker"

const TOOL_CLASS = "mb2 mb0-ns mr2"

export interface ExploreCodedTextState {
  activeDocId?: string
  docIds: string[]
  codeIds: string[]
  showIntersectionsOnly: boolean
}

export const ExploreCodedText = withTabStateProvider<
  unknown,
  ExploreCodedTextState
>(
  () => {
    const { data, error } = useEdges(),
      { codes } = useCodes(),
      { docs } = useDocs(),
      { state, setState } = useTabState<ExploreCodedTextState>(),
      noCodesSelected = state.codeIds.length === 0,
      showIntersectionsOnly = !noCodesSelected && state.showIntersectionsOnly

    if (!data) return <Spinner />
    if (error) return <DataLoadingError />

    return (
      <ContentPageContainer>
        <PageTitle>Explore Coded Text</PageTitle>
        <div className="flex flex-wrap flex-row-ns mv4 mh2 mh0-ns">
          <TaggablePicker
            items={docs}
            ids={state.docIds}
            onChange={(docIds) => setState({ ...state, docIds })}
            className={TOOL_CLASS}
            align="start"
            direction="right"
            label="Select Documents"
          />
          <TaggablePicker
            items={codes}
            ids={state.codeIds}
            onChange={(codeIds) => setState({ ...state, codeIds })}
            className={TOOL_CLASS}
            align="start"
            direction="right"
            label="Select Codes"
          />
          <Button
            label="Only show text with all codes"
            disabled={noCodesSelected}
            className={clsx(
              showIntersectionsOnly ? cl.btnLightActive : cl.btnLight,
              TOOL_CLASS
            )}
            tooltip={
              noCodesSelected ? "Select some codes to enable this" : false
            }
            onClick={() =>
              setState({
                ...state,
                showIntersectionsOnly: !showIntersectionsOnly,
              })
            }
          />
        </div>
        <CodeReport
          codeIds={filterIdsOrAllIds(state.codeIds, codes)}
          docIds={filterIdsOrAllIds(state.docIds, docs)}
          showIntersectionsOnly={showIntersectionsOnly}
          setDocId={(activeDocId) => setState({ ...state, activeDocId })}
          docId={state.activeDocId}
        />
      </ContentPageContainer>
    )
  },
  {
    docIds: [],
    codeIds: [],
    showIntersectionsOnly: false,
  }
)

function filterIdsOrAllIds(ids: string[], things: Named[]) {
  return ids.length ? ids : things.map((thing) => thing.id)
}
