import { cl, deleteDoc, useMediaUploader } from "../../utils"
import { Alert, CommandButton, ProgressBar, Footer } from "../base"
import { MediaJobStatus } from "../MediaJobStatus"

export const TranscriptLoading: React.FC<{ docId: string; name: string }> = ({
  docId,
  name,
}) => {
  const { uploads } = useMediaUploader(),
    upload = uploads.find((upload) => upload.id === docId)

  return (
    <>
      {upload ? (
        <>
          <Message>
            Your file is uploading. We'll let you know your transcript is ready.
          </Message>
          <ProgressBar
            label={`Uploading ${upload.file.name}`}
            progress={upload.progress}
          />
          <Footer>
            <CommandButton
              command={deleteDoc}
              args={{ id: docId, name }}
              label="Cancel"
              className={cl.btn}
            />
          </Footer>
        </>
      ) : (
        <MediaJobStatus
          docId={docId}
          message="We're transcribing your document. We'll let you know when it's ready."
        />
      )}
    </>
  )
}

const Message: React.FC = ({ children }) => (
  <Alert className="mv3 pa3" error={false}>
    {children}
  </Alert>
)
