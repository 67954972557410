import React from "react"

export interface MediaPlayerInternalContextValue {
  src?: string
  pos?: number
  error?: Error
  setPos: (pos?: number) => void
}

export const MediaPlayerInternalContext =
  //@ts-expect-error null ctx
  React.createContext<MediaPlayerInternalContextValue>(null)

export function useInternalMediaContext() {
  return React.useContext(MediaPlayerInternalContext)
}
