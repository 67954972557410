import React from "react"
import { sortRanges, isProduction } from "lib"
import {
  CodeStripe,
  CodeStripeProps,
  APPROX_LABEL_HEIGHT,
  LABEL_NUDGE_Y,
  StripeClickHandlerProps,
  MaybeClippedCodeRange,
} from "./CodeStripe"
import { DEFAULT_CODE_COLOR, useCodes } from "../../utils"
import clsx from "clsx"
import { ActionMenuItem } from "../base"

const verticalSpaceBetweenStripes = 3 // px
const stickyHeightReduction = LABEL_NUDGE_Y * 2

export interface CodeStripesProps {
  ranges: MaybeClippedCodeRange[]
  getStartNode: (pos: number) => HTMLElement | null
  getEndNode: (pos: number) => HTMLElement | null
  style?: React.CSSProperties
  onStripeClick: (props: StripeClickHandlerProps) => void
  getMenuItems: (props: StripeClickHandlerProps) => ActionMenuItem[]
  className?: string
  activeRangeKeys: string | null
}

export const CodeStripes = React.memo<CodeStripesProps>(
  ({
    ranges,
    getStartNode,
    getEndNode,
    style,
    onStripeClick,
    getMenuItems,
    className,
    activeRangeKeys,
  }) => {
    const { data } = useCodes()

    if (!data) return null

    const codes = data.getProject.codes

    const activeRangeIndexSet = new Set(
      parseActiveRangeKeys(activeRangeKeys || "")
    )

    const stripeLayout = new VerticalStackLayout(
        verticalSpaceBetweenStripes,
        "stripe"
      ),
      stickyLabelLayout = new VerticalStackLayout(
        verticalSpaceBetweenStripes,
        "label"
      )

    let lastLabelTop = -APPROX_LABEL_HEIGHT

    const positionedRanges: CodeStripeProps[] = ranges
      .slice()
      .sort(sortRanges)
      .map(({ from, to, codeId, ...rest }) => {
        const code = codes.find((code) => code.id === codeId),
          startNode = getStartNode(from),
          endNode = getEndNode(to),
          top = startNode ? startNode.offsetTop : 0,
          bottom = endNode ? endNode.offsetTop + endNode.offsetHeight : 0,
          height = bottom - top

        return {
          codeId,
          codeName: code?.name || "",
          codeColor: code?.color || DEFAULT_CODE_COLOR,
          from,
          to,
          top,
          height,
          ...rest,
        }
      })
      .filter((coding) => !!coding.codeName)
      .map(({ top, height, ...rest }) => {
        const colIndex = stripeLayout.addItem(top, height),
          lastLabelBottom = lastLabelTop + APPROX_LABEL_HEIGHT

        let labelTop = top

        if (labelTop < lastLabelBottom) {
          labelTop = lastLabelBottom
        }

        const labelOffset = labelTop - top,
          stickyHeight = height - (labelOffset + stickyHeightReduction),
          stickyIndex = stickyLabelLayout.addItem(
            labelTop,
            Math.max(0, stickyHeight)
          )

        lastLabelTop = labelTop

        return {
          ...rest,
          top,
          height,
          colIndex,
          stickyIndex,
          stickyHeight,
          labelTop,
          onStripeClick,
          active:
            activeRangeKeys !== null
              ? activeRangeIndexSet.has(getRangeKey(rest.codeId, rest.from))
              : true,
        } as CodeStripeProps
      })

    return (
      <div className={clsx("relative z-0", className)} style={style}>
        {positionedRanges.map((data) => (
          <CodeStripe
            key={getRangeKey(data.codeId, data.from)}
            {...data}
            getMenuItems={getMenuItems}
          />
        ))}
      </div>
    )
  }
)

class VerticalStackLayout {
  private itemPadding: number
  private layoutName?: string

  constructor(itemPadding = 0, layoutName?: string) {
    this.itemPadding = itemPadding
    this.layoutName = layoutName
  }

  private columns: number[] = []

  addItem(itemOffset: number, itemHeight: number) {
    if (itemOffset < 0 || itemHeight < 0) {
      if (!isProduction()) {
        console.warn({
          message: "item offset/height cannot be negative",
          layout: this.layoutName,
          itemOffset,
          itemHeight,
        })
      }
    }

    let colIndex = this.columns.findIndex(
      (colHeight) => colHeight + this.itemPadding < itemOffset
    )

    const itemBottom = itemOffset + itemHeight

    if (colIndex < 0) {
      colIndex = this.columns.length
      this.columns.push(itemBottom)
    } else {
      this.columns[colIndex] = itemBottom
    }

    return colIndex
  }
}

export function getRangeKey(codeId: string, from: number) {
  return `${codeId}:${from}`
}

export function serializeActiveRangeKeys(keys: string[]) {
  return keys.sort().join("-")
}

export function parseActiveRangeKeys(keys: string) {
  return keys.split("-").filter(Boolean)
}
