import React from "react"
import clsx from "clsx"
import { ascending } from "lib"
import { ExploreCodedTextState } from "../ExploreCodedText"
import {
  clamp,
  Named,
  sum,
  TabDataType,
  TabWidget,
  useCodes,
  useDocs,
  useOpenTab,
  useTabState,
  withTabStateProvider,
} from "../../utils"
import { DataLoadingError, PageTitle, Info, Spinner } from "../base"
import { useEdges } from "../EdgeProvider"
import { matrixReducer, initialState, SeriesType, MatrixState } from "./state"
import { EdgesIndex, getNavigationState, indexEdges } from "./utils"
import { SeriesChooser } from "./SeriesChooser"
import { useSortSeriesByOther } from "./sort"
import { Row, Cell, CELL_WIDTH, DataCell, HeaderCell } from "./Cells"

import "./RelationshipMatrix.css"

const CONTAINER_CLASS = "w-auto mh5-ns"

export const RelationshipMatrix = withTabStateProvider<unknown, MatrixState>(
  () => {
    const { overlapEdges: edges, refetch, ...edgeInfo } = useEdges(),
      { docs, tags: docTags, ...projectInfo } = useDocs(),
      { codes, tags: codeTags } = useCodes(),
      allSeries = React.useMemo(() => {
        return [docs, docTags, codes, codeTags].map(sortSeries)
      }, [docs, docTags, codes, codeTags]),
      { state: storedState, writeState } = useTabState<MatrixState>(),
      [state, dispatch] = React.useReducer(matrixReducer, storedState),
      [fullScreen, setFullScreen] = React.useState(false),
      edgeIndex = React.useMemo(
        () => indexEdges(docs, codes, edges),
        [docs, codes, edges]
      ),
      sizes = allSeries[state.y.series].reduce((sizes, { id: yId }) => {
        allSeries[state.x.series].forEach(({ id: xId }) => {
          if (state.x.series === state.y.series && xId === yId) return

          const xEdges = getEdgeSet(state.x.series, edgeIndex).get(xId) || [],
            yEdges = getEdgeSet(state.y.series, edgeIndex).get(yId) || []

          const size = sum(
            xEdges
              .filter((edge) => yEdges.includes(edge))
              .map((edge) => edge.size)
          )

          sizes.set(`${xId}-${yId}`, size)
        })
        return sizes
      }, new Map<string, number>()),
      maxSize = Math.max(0, ...Array.from(sizes.values())),
      maybeRemoveEmpty = (series: Named[], other: Named[], isX: boolean) => {
        if (!state.hideEmpty) return series
        return series.filter((i) => {
          return other.reduce((size, o) => {
            const key = isX ? `${i.id}-${o.id}` : `${o.id}-${i.id}`
            return size + (sizes.get(key) || 0)
          }, 0)
        })
      }

    let seriesX = useSortSeriesByOther(
        allSeries[state.x.series],
        state.y.sort,
        sizes,
        true
      ),
      seriesY = useSortSeriesByOther(
        allSeries[state.y.series],
        state.x.sort,
        sizes,
        false
      )

    seriesX = maybeRemoveEmpty(seriesX, seriesY, true)
    seriesY = maybeRemoveEmpty(seriesY, seriesX, false)

    const width = CELL_WIDTH * 2 + seriesX.length * CELL_WIDTH

    // reset position if it becomes invalid
    React.useEffect(() => {
      const { x, y } = state.focus
      if (x > seriesX.length || y > seriesY.length) {
        dispatch({
          type: "focus",
          x: clamp(x, 0, seriesX.length),
          y: clamp(y, 0, seriesY.length),
        })
      }
    }, [state.focus, seriesX.length, seriesY.length])

    // focus cells in response to user keyboarding
    React.useEffect(() => {
      if (state.shouldFocusDomCell) {
        const cell: HTMLButtonElement | null = document.querySelector(
          ".hm-cell[tabindex='0']"
        )
        if (cell) cell.focus()
        dispatch({ type: "report-dom-cell-focused" })
      }
    })

    // navigate if the user double clicks a cell
    const openTab = useOpenTab()

    // write state on change
    React.useEffect(() => {
      writeState(state)
    }, [state, writeState])

    React.useEffect(() => {
      if (!state.navigateFrom) return
      writeState({ ...state, navigateFrom: undefined })
      openTab<ExploreCodedTextState>(
        TabWidget.ExploreCodedText,
        TabDataType.Analysis,
        getNavigationState(state, seriesX, seriesY, docs, codes),
        0
      )
    })

    // fresh data on mount
    React.useEffect(() => {
      refetch()
    }, [refetch])

    const getDirections = (x: number, y: number) => {
      return getAllowedDirections(x, y, seriesX.length, seriesY.length)
    }

    const getFocus = (x: number, y: number) => {
      const xFocus = state.focus.x === x,
        yFocus = y === state.focus.y

      if (xFocus && yFocus) {
        return 2
      } else if ((xFocus && x) || (yFocus && y)) {
        return 1
      } else {
        return 0
      }
    }

    const header = <PageTitle>Relationship Matrix</PageTitle>

    const chooser = (
      <SeriesChooser
        xSeriesIndex={state.x.series}
        ySeriesIndex={state.y.series}
        hideEmpty={state.hideEmpty}
        dispatch={dispatch}
      />
    )

    if (edgeInfo.error || projectInfo.error) {
      return <DataLoadingError />
    } else if (
      (edgeInfo.loading || projectInfo.loading) &&
      !edges.length &&
      !docs.length
    ) {
      return <Spinner />
    } else if (!seriesX.length || !seriesY.length) {
      return (
        <div className={CONTAINER_CLASS}>
          {header}
          {chooser}
          <Info className="mw6-ns mv4">There's no data to show here yet.</Info>
        </div>
      )
    }

    const scaleOpacity =
      (state.x.series === SeriesType.Codes &&
        state.y.series === SeriesType.Codes) ||
      (state.x.series !== SeriesType.Codes &&
        state.y.series !== SeriesType.Codes)

    return (
      <div className={CONTAINER_CLASS}>
        {header}
        {chooser}
        <div
          className={clsx(
            "hm-container overflow-hidden bg-light-gray b--black-20",
            fullScreen
              ? "fixed top-0 left-0 w-100 vh-100 z-2"
              : "relative w-auto vh-100 vh-75-ns mv4 br2 ba z-1"
          )}
        >
          <div className={clsx("overflow-auto h-100 w-100")}>
            <Row width={width} sticky>
              <Cell
                x={0}
                y={0}
                dispatch={dispatch}
                {...getDirections(0, 0)}
                focus={getFocus(0, 0)}
                interactive
                onClick={() => setFullScreen(!fullScreen)}
              >
                {fullScreen ? "↘ Exit Fullscreen" : "↖ Fullscreen"}
              </Cell>
              {seriesX.map((item, xi) => (
                <HeaderCell
                  key={item.id}
                  x={xi + 1}
                  y={0}
                  xId={item.id}
                  dispatch={dispatch}
                  {...getDirections(xi + 1, 0)}
                  focus={getFocus(xi + 1, 0)}
                  name={item.name}
                  sort={state.x.sort}
                  interactive
                />
              ))}
            </Row>
            {seriesY.map((itemY, yi) => (
              <Row key={itemY.id} width={width}>
                <HeaderCell
                  key={itemY.id}
                  x={0}
                  y={yi + 1}
                  yId={itemY.id}
                  dispatch={dispatch}
                  {...getDirections(0, yi + 1)}
                  focus={getFocus(0, yi + 1)}
                  className="truncate"
                  name={itemY.name}
                  sort={state.y.sort}
                  interactive
                />

                {seriesX.map((itemX, xi) => {
                  const size = sizes.get(`${itemX.id}-${itemY.id}`) || 0
                  return (
                    <DataCell
                      key={itemX.id}
                      x={xi + 1}
                      y={yi + 1}
                      xId={itemX.id}
                      yId={itemY.id}
                      dispatch={dispatch}
                      {...getDirections(xi + 1, yi + 1)}
                      focus={getFocus(xi + 1, yi + 1)}
                      className="flex items-center justify-center"
                      interactive={size > 0}
                      value={size / maxSize}
                      color={itemY.color || itemX.color}
                      scaleOpacity={scaleOpacity}
                      tooltip={size > 0 ? "Click to read this text" : undefined}
                    />
                  )
                })}
              </Row>
            ))}
          </div>
        </div>
      </div>
    )
  },
  initialState
)

function getAllowedDirections(
  x: number,
  y: number,
  xMax: number,
  yMax: number
) {
  return {
    up: y > 0,
    down: y < yMax,
    left: x > 0,
    right: x < xMax,
  }
}

function getEdgeSet(seriesIndex: number, index: EdgesIndex) {
  switch (seriesIndex) {
    case 0:
      return index.docs
    case 2:
      return index.codes
    default:
      return index.tags
  }
}

function sortSeries(series: (Named & { color?: string })[]) {
  return [...series].sort((a, b) =>
    ascending(a.name.toLowerCase(), b.name.toLowerCase())
  )
}
