import { getEnv, RECAPTCHA_TOKEN_HEADER, Auth, ServerRoute } from "lib"

function getRecaptchaSiteKeyFromPageHead() {
  const src = [...document.querySelectorAll("script")]
    .map((script) => script.src)
    .find((src) => src.includes("recaptcha/api.js"))

  if (!src) throw new Error("recaptcha api key could not be found")

  return src.replace(/^.*render=/, "")
}

export async function postAuth<P extends Auth.PathAlias>(
  alias: P,
  input: Auth.I[P]
): Promise<Auth.O[P]> {
  const path = Auth.Path[alias],
    recaptchaToken = await getRecaptchaToken(path),
    url = getUrl(path),
    result = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        [RECAPTCHA_TOKEN_HEADER]: recaptchaToken,
      },
      body: JSON.stringify(input),
    }),
    data = await result.json()

  if (result.status >= 400) {
    throw new Error(data.message)
  } else {
    return data as Auth.O[P]
  }
}

declare global {
  interface Window {
    grecaptcha: {
      ready: (func: () => unknown) => unknown
      execute: (siteKey: string, options: { action: string }) => Promise<string>
    }
  }
}

function getUrl(path: Auth.Path) {
  return window.origin + ServerRoute.Auth + path
}

function getRecaptchaToken(url: Auth.Path): Promise<string> {
  if (getEnv() === "test") return Promise.resolve("")

  return new Promise((resolve, reject) => {
    const { grecaptcha } = window

    grecaptcha.ready(function () {
      grecaptcha
        .execute(getRecaptchaSiteKeyFromPageHead(), { action: url })
        .then(resolve)
        .catch(reject)
    })
  })
}
