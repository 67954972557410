import { useCallback } from "react"
import { addCoding, Command, getSelectionCodeIds, removeCoding } from "editor"
import { useProsemirror } from "../base"
import { CodePaletteProvider as Provider } from "../CodePalette"

export const CodePaletteProvider: React.FC = ({ children }) => {
  const { view } = useProsemirror()

  const info = view ? getSelectionCodeIds(view.state) : [],
    run = useCallback(
      (command: Command) => {
        if (view) command(view.state, view.dispatch)
      },
      [view]
    ),
    onAddCoding = useCallback((id: string) => run(addCoding(id)), [run]),
    onRemoveCoding = useCallback((id: string) => run(removeCoding(id)), [run]),
    onCodeCreated = useCallback((id: string) => run(addCoding(id)), [run])
  return (
    <Provider
      activeCodeIds={info}
      onAddCoding={onAddCoding}
      onRemoveCoding={onRemoveCoding}
      onCodeCreated={onCodeCreated}
      canCode={view ? !view.state.selection.empty : false}
    >
      {children}
    </Provider>
  )
}
