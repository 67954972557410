import { File, Tag, Tools, Search } from "../base"
import { DocList, DocTools } from "../SidebarDocList"
import { SidebarPanelProps } from "./Sidebar"
import { CodeList, CodeTools } from "../SidebarCodeList"
import { SidebarSearch } from "../SidebarSearch"
import { SidebarAnalysis } from "../SidebarAnalysis"
import { SidebarTab } from "../../utils"

export const panels: SidebarPanelProps[] = [
  {
    tab: SidebarTab.Doc,
    label: "Documents",
    Content: DocList,
    Icon: File,
    Tools: DocTools,
  },
  {
    tab: SidebarTab.Code,
    label: "Codes",
    Content: CodeList,
    Icon: Tag,
    Tools: CodeTools,
  },
  {
    tab: SidebarTab.Analysis,
    label: "Analysis",
    Content: SidebarAnalysis,
    Icon: Tools,
    Tools: () => null,
  },
  {
    tab: SidebarTab.Search,
    label: "Search",
    Content: SidebarSearch,
    Icon: Search,
    Tools: () => null,
  },
]
