import {
  ProjectDelete,
  ProjectShare,
  ProjectUpdate,
} from "../../components/dialogs"
import { action, ActionMenuItem, DIVIDER } from "../../components/base"
import { useDialog } from "./dialog-context"
import { leaveProject, useExec } from "../commands"

export function useProjectMenu(id: string, isOwner: boolean): ActionMenuItem[] {
  const { show } = useDialog(),
    exec = useExec()

  if (isOwner) {
    return [
      action("Edit Details", () => show(ProjectUpdate, { id })),
      action("Share", () => show(ProjectShare, { id })),
      DIVIDER,
      action("Delete", () => show(ProjectDelete, { id })),
    ]
  } else {
    return [action("Leave Project", () => exec(leaveProject, { id }))]
  }
}
