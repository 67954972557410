import React from "react"
import { CloseButton } from "./Button"
import clsx from "clsx"

interface DeletableCardProps {
  deleteLabel?: string
  onDelete: () => unknown | Promise<unknown>
  className?: string
  reset?: boolean
}

export const DeletableCard: React.FC<DeletableCardProps> = ({
  children,
  onDelete,
  deleteLabel = "Delete",
  className,
  reset = false,
}) => {
  const [loading, setLoading] = React.useState(false)
  return (
    <div
      className={clsx(
        "f5 bg-washed-yellow pa3 mv3 ba br1 b--gold flex items-center justify-between",
        className
      )}
    >
      <span>{children}</span>
      <CloseButton
        label={deleteLabel}
        className="self-start flex-shrink-0"
        loading={loading}
        onClick={async () => {
          setLoading(true)
          try {
            await onDelete()
            if (reset) setLoading(false)
          } catch (err) {
            setLoading(false)
          }
        }}
      />
    </div>
  )
}
