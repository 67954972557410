import clsx from "clsx"
import React from "react"
import { useContextMenu } from "../../../utils"
import { ActionMenuItem } from "../ActionMenu"
import { ListGridData, ListGridDispatch } from "./ListGridState"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface ListGridRowProps extends ContextMenuSpec<any> {
  id: string
  index: number
  dispatch: ListGridDispatch
  className?: string
  selected: boolean
  focused: boolean
  enableSelection: boolean
}

interface ContextMenuSpec<T extends ListGridData> {
  menuData: T
  menuUseMultiple?: boolean
  menuSingle?: (item: T) => ActionMenuItem[]
  menuMulti?: ActionMenuItem[]
}

export const ListGridRow = React.memo<
  React.PropsWithChildren<ListGridRowProps>
>(
  ({
    index,
    dispatch,
    className,
    children,
    enableSelection,
    selected,
    focused,
    menuData,
    menuUseMultiple,
    menuSingle,
    menuMulti,
  }) => {
    const onContextMenu = useContextMenu()
    return (
      <div
        className={clsx(
          "list-grid-row",
          selected ? "bg-lightest-blue" : "hover-bg-black-10",
          className
        )}
        data-focused={focused}
        onClick={({ ctrlKey, shiftKey }) => {
          if (!enableSelection) return
          dispatch({ type: "select", rowIndex: index, ctrlKey, shiftKey })
        }}
        onContextMenu={(e) => {
          if (!menuSingle || !menuMulti) return
          if (menuUseMultiple) {
            onContextMenu(e, menuMulti)
          } else {
            if (enableSelection && !selected)
              dispatch({ type: "select", rowIndex: index })
            onContextMenu(e, menuSingle(menuData))
          }
        }}
      >
        {children}
      </div>
    )
  }
)
