import React from "react"
import {
  Spinner,
  LoadingError,
  ChartContainer,
  ChartDataLabel,
  NoDataMessage,
} from "./base"
import {
  sum,
  useDocs,
  DEFAULT_CODE_COLOR,
  useCodes,
  toTextPercent,
  useTabState,
} from "../utils"
import { descending } from "lib"
import { useEdges } from "./EdgeProvider"
import { CodeTabState } from "./CodeTabs"

export const CodeUsageChart: React.FC<{ codeId: string }> = ({ codeId }) => {
  const { error, data, docEdges, refetch } = useEdges(),
    { docs } = useDocs(),
    color =
      useCodes().codes.find((d) => d.id === codeId)?.color || DEFAULT_CODE_COLOR

  // ensure fresh data
  React.useEffect(() => {
    refetch()
  }, [refetch])

  if (error) return <LoadingError />
  if (!data) return <Spinner />

  const docSizes = docEdges
    .filter((edge) => {
      return edge.codeId === codeId
    })
    .sort((a, b) => descending(sum(a.sizes), sum(b.sizes)))

  const maxSize = Math.max(0, ...docSizes.map((d) => sum(d.sizes)))

  if (!docSizes.length) return <NoDataMessage className="mt4" />

  return (
    <ChartContainer className="ph4">
      {docSizes.map(({ docId, sizes }) => (
        <DocChart
          key={docId}
          id={docId}
          sizes={sizes}
          maxSize={maxSize}
          name={docs.find((d) => d.id === docId)?.name || "Unknown"}
          color={color}
        />
      ))}
    </ChartContainer>
  )
}

const DocChart: React.FC<{
  id: string
  name: string
  sizes: number[]
  maxSize: number
  color: string
}> = ({ id, name, sizes, maxSize, color }) => {
  const { state, setState } = useTabState<CodeTabState>(),
    onClick = () => {
      setState({ ...state, tab: 0, codeReportDocId: id })
    }
  return (
    <div className="mv4 pointer dim" onClick={onClick}>
      <ChartDataLabel>{name}</ChartDataLabel>
      <div className="h1">
        {sizes.map((size, i) => (
          <div
            key={i}
            className="h1 pr1 dib"
            style={{ width: toTextPercent(size / maxSize) }}
          >
            <div style={{ backgroundColor: color }} className="h1 br1 w-100" />
          </div>
        ))}
      </div>
    </div>
  )
}
