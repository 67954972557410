import React from "react"
import { gql, useQuery } from "@apollo/client"
import { IconType } from "@react-icons/all-files/lib"
import clsx from "clsx"
import { descending } from "lib"
import {
  cl,
  fragments,
  getProjectLastAccess,
  ListProjects,
  sortByName,
  useAccessToken,
  useDialog,
  useProjectMenu,
  useAccountMenu,
  ACCOUNT_DATA,
  AccountData,
} from "../utils"
import {
  PageTitle,
  RouterLink,
  Button,
  Spinner,
  DataLoadingError,
  File,
  Tag,
  Person,
  Narrow,
  Gear,
  ActionMenu,
} from "./base"
import { ProjectCreate } from "./dialogs"
import { UserInvites } from "./UserInvites"

import "./Projects.css"

const LIST_PROJECTS = gql`
  query ListProjects {
    listProjects {
      ...ProjectPreview
    }
  }

  ${fragments.projectPreview}
`

type Row = ListProjects["listProjects"][0]

const RECENT_TIME = 1000 * 60 * 60 * 24 * 7

const HEADER_CLASS = "mid-gray ttu tracked f6 mb4 mt5"

export function Projects() {
  const { loading, error, data } = useQuery<ListProjects>(LIST_PROJECTS, {
      fetchPolicy: "cache-and-network",
    }),
    { show } = useDialog(),
    [search, setSearch] = React.useState(""),
    projects = (data?.listProjects || [])
      .map((project) => {
        return {
          ...project,
          recent: getProjectLastAccess(project.id) > Date.now() - RECENT_TIME,
        }
      })
      .filter((proj) => {
        if (!search) return true
        return proj.name.toLowerCase().includes(search.toLowerCase())
      })
      .sort(sortByName)
      .sort((a, b) => descending(a.recent, b.recent)),
    recent = projects.filter((p) => p.recent),
    older = projects.filter((p) => !p.recent)

  return (
    <div className="bg-near-white h-100 vh-100 overflow-auto">
      <div
        className="flex f3 items-center justify-between ph3 pt2"
        style={{ top: 0, position: "sticky", pointerEvents: "none" }}
      >
        <div className="b fw6 near-black" style={{ pointerEvents: "all" }}>
          Leaf
        </div>
        <div className="dark-gray" style={{ pointerEvents: "all" }}>
          <UserMenuButton />
        </div>
      </div>
      <Narrow>
        <PageTitle>Projects</PageTitle>
        <h1 className="near-black f1 fw2 mb4 mt5">Projects</h1>
        <div className="flex items-center">
          <input
            placeholder="Find project..."
            className={clsx(cl.input, "mr2 flex-grow-1")}
            value={search}
            onChange={(e) => setSearch(e.currentTarget.value)}
          />
          <Button
            label="Create Project"
            className={cl.btn}
            onClick={() => show(ProjectCreate, {})}
          />
        </div>

        <UserInvites />
        {loading && !projects.length && <Spinner />}
        {error && <DataLoadingError />}
        {recent.length ? (
          <>
            <div className={HEADER_CLASS}>Recently Opened</div>
            <>
              {recent.map((data) => (
                <ProjectCard key={data.id} {...data} />
              ))}
            </>
            {older.length ? <div className={HEADER_CLASS}>Older</div> : null}
          </>
        ) : null}
        <>
          {older.map((data) => (
            <ProjectCard key={data.id} {...data} />
          ))}
        </>
        {!loading && !projects.length && (
          <div className="mid-gray mv4">No projects found.</div>
        )}
      </Narrow>
    </div>
  )
}

const ProjectCard: React.FC<Row & { recent: boolean }> = ({
  id,
  name,
  owner,
  docCount,
  codeCount,
  collaborators,
}) => {
  const { userId } = useAccessToken(),
    menuItems = useProjectMenu(id, owner.id === userId.toString())
  return (
    <div
      className={clsx(
        "pa3 mv3 br2 bg-near-white hide-child relative ba b--black-20 bg-white project-list-item"
      )}
      style={{
        borderLeftWidth: "0.5rem",
      }}
    >
      <div>
        <RouterLink to={`/projects/${id}`} className="dark-gray">
          <h2 className="f4 fw6 mt0 mb3 mr3 lh-title dib underline-hover">
            {name || "Untitled Project"}
          </h2>
        </RouterLink>
      </div>
      <div className="flex items-center justify-between flex-grow-1">
        <div className="flex items-center mid-gray">
          <Stat icon={File} n={docCount} />
          <Stat icon={Tag} n={codeCount} />
          <Stat icon={Person} n={collaborators.length + 1} />
        </div>
        <div>
          <ActionMenu
            label="Actions"
            buttonProps={{
              className: clsx(cl.btnGhost, "child absolute"),
              styles: { bottom: "0.5rem", right: "0.5rem" },
            }}
            content={<Gear className="gray hover-mid-gray" />}
            items={menuItems}
          />
        </div>
      </div>
    </div>
  )
}

const Stat: React.FC<{ icon: IconType; n: number }> = ({ icon: Icon, n }) => {
  return (
    <div className="flex items-center tc w3 mid-gray f5">
      <Icon className="mr1" />
      {n}
    </div>
  )
}

const UserMenuButton: React.FC = () => {
  const { data } = useQuery<AccountData>(ACCOUNT_DATA),
    accountActions = useAccountMenu()

  return (
    <ActionMenu
      label="Account"
      content={
        <>
          <Person className="mr2" /> <>{data?.getUser.name}</>
        </>
      }
      buttonProps={{
        className: clsx(cl.btnGhost, "f5"),
      }}
      items={accountActions}
      menuProps={{ direction: "bottom", align: "end" }}
    />
  )
}
