import React from "react"
import { sortRanges, EdgeRange, computeEdgeRanges } from "lib"
import { useProjectId, GetEdges, GetEdgesVariables, groupify } from "../utils"
import { gql, QueryResult, useQuery } from "@apollo/client"

const GET_EDGES = gql`
  query GetEdges($projectId: ID!) {
    getCodeEdges(projectId: $projectId) {
      id
      edges {
        id
        docId
        codeId
        from
        to
      }
    }
  }
`

//@ts-expect-error null ctx
const EdgeContext = React.createContext<QueryResult<GetEdges>>(null)

export const EdgeProvider: React.FC = ({ children }) => {
  const projectId = useProjectId(),
    result = useQuery<GetEdges, GetEdgesVariables>(GET_EDGES, {
      variables: { projectId },
      fetchPolicy: "cache-and-network",
    })

  return <EdgeContext.Provider value={result}>{children}</EdgeContext.Provider>
}

export interface DocEdge {
  docId: string
  sizes: number[]
  codeId: string
}

export interface OverlapEdge extends EdgeRange {
  docId: string
  size: number
}

export function useEdges() {
  const result = React.useContext(EdgeContext)

  const { data } = result

  const edges = React.useMemo(() => {
    return data ? data.getCodeEdges.edges : []
  }, [data])

  const docEdges = React.useMemo(() => {
    if (data) {
      const docEdges: DocEdge[] = [],
        byDoc = groupify(
          data.getCodeEdges.edges.slice().sort(sortRanges),
          (e) => e.docId
        )
      Array.from(byDoc).forEach(([docId, edges]) => {
        const byCode = groupify(edges, (e) => e.codeId)
        Array.from(byCode).forEach(([codeId, edges]) => {
          docEdges.push({
            docId,
            codeId,
            sizes: edges.map((edge) => edge.to - edge.from),
          })
        })
      })
      return docEdges
    } else {
      return []
    }
  }, [data])

  const overlapEdges = React.useMemo(() => {
    if (!data) return []

    const overlapSegments: OverlapEdge[] = [],
      grouped = groupify(data.getCodeEdges.edges, (d) => d.docId)

    for (const [docId, docEdges] of grouped) {
      computeEdgeRanges(docEdges).forEach((segment) => {
        overlapSegments.push({
          docId,
          ...segment,
          size: segment.to - segment.from,
        })
      })
    }

    return overlapSegments.filter((edge) => edge.size > 0)
  }, [data])

  return React.useMemo(
    () => ({ ...result, edges, docEdges, overlapEdges }),
    [result, edges, docEdges, overlapEdges]
  )
}
