import { BrowserRouter } from "react-router-dom"
import { ConfirmationProvider } from "./ConfirmationProvider"
import { ToastProvider } from "./ToastProvider"
import { AuthProvider } from "./AuthProvider"
import { FocusOutlineStyles } from "./FocusOutlineManager"

export const App: React.FC = () => {
  return (
    <>
      <FocusOutlineStyles />
      <BrowserRouter>
        <ToastProvider>
          <ConfirmationProvider>
            <AuthProvider />
          </ConfirmationProvider>
        </ToastProvider>
      </BrowserRouter>
    </>
  )
}
