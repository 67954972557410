import clsx from "clsx"
import React from "react"
import "./Spinner.css"

const BAR_CLASS = `br1 w1 h-100`

export const Spinner: React.FC<{
  className?: string
  style?: React.CSSProperties
}> = ({ className = "", style }) => {
  return (
    <div
      className={clsx("mv3 flex w-100 justify-center", className)}
      aria-busy
      aria-live="polite"
      data-testid="spinner"
      style={style}
    >
      <div className="spinner h4 pa3 flex">
        <div className={`bar-1 ${BAR_CLASS} mr2 bg-red`} />
        <div className={`bar-2 ${BAR_CLASS} mr2 bg-yellow`} />
        <div className={`bar-3 ${BAR_CLASS} bg-blue`} />
      </div>
    </div>
  )
}
