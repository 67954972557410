import React from "react"
import { SomeObject } from "lib"
import {
  Formik,
  Form as FormikForm,
  Field as FormikField,
  ErrorMessage as FormikErrorMessage,
} from "formik"
import { ObjectSchema } from "yup"
import clsx from "clsx"
import { any, cl } from "../../utils"
import { Input } from "./Input"
import { Button } from "./Button"
import { Footer } from "./layout"

export * as yup from "yup"

export interface FormProps<T extends SomeObject> {
  children: React.ReactNode
  values: T
  schema: ObjectSchema
  submitText: string
  onSubmit: (data: T) => Promise<unknown>
  classNames?: FormClassNames
  style?: React.CSSProperties
  reset?: boolean
  resetBlank?: boolean
}

interface FormClassNames {
  form?: string
  button?: string
}

export function Form<T extends SomeObject>({
  children,
  values,
  schema,
  submitText,
  onSubmit,
  classNames = {},
  style,
  reset = false,
  resetBlank = false,
}: FormProps<T>) {
  return (
    <Formik
      initialValues={values}
      validationSchema={schema}
      onSubmit={async (values, helpers) => {
        try {
          await onSubmit(values)
        } catch (err) {
          return
        }
        if (resetBlank) {
          helpers.resetForm()
        } else if (reset) {
          helpers.resetForm({ values })
        }
      }}
    >
      {({ isSubmitting, isValid, dirty }) => (
        <FormikForm style={style} className={classNames.form}>
          {children}
          <Footer>
            <Button
              label={submitText}
              className={classNames.button}
              submit
              loading={isSubmitting}
              disabled={any(!isValid, !dirty)}
            />
          </Footer>
        </FormikForm>
      )}
    </Formik>
  )
}

interface FieldProps {
  name: string
  label: string
  className?: string
  labelClassName?: string
  controlClassName?: string
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: React.ForwardRefExoticComponent<any>
  type?: string
  placeholder?: string
  hideErrors?: boolean
}

export const Field: React.FC<FieldProps> = ({
  name,
  label,
  className,
  labelClassName,
  controlClassName,
  component = Input,
  placeholder,
  type,
  hideErrors,
}) => {
  return (
    <div className={clsx("relative flex flex-column mb4", className)}>
      <Label
        htmlFor={name}
        text={label}
        className={labelClassName}
        hideErrors={hideErrors}
      />

      <FormikField
        id={name}
        name={name}
        component={component}
        type={type}
        className={controlClassName}
        placeholder={placeholder}
      />
    </div>
  )
}

interface LabelProps {
  htmlFor: string
  text: string
  className?: string
  hideErrors?: boolean
}

export const Label: React.FC<LabelProps> = ({
  htmlFor,
  text,
  className,
  hideErrors,
}) => (
  <label className={clsx(cl.label, "mb1", className)} htmlFor={htmlFor}>
    {text}
    {hideErrors ? null : (
      <FormikErrorMessage name={htmlFor} component={InlineError} />
    )}
  </label>
)

const InlineError: React.FC = ({ children }) => (
  <div className="dark-red fw4 di">&nbsp;— {children}</div>
)
