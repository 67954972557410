import React from "react"
import { gql, useSubscription } from "@apollo/client"
import {
  useAccessToken,
  useToast,
  GetNotified,
  GetNotifiedVariables,
} from "../utils"

const GET_NOTIFIED = gql`
  subscription GetNotified($id: ID!) {
    subscribeToUserNotifications(id: $id) {
      id
      message
      error
    }
  }
`

export const UserNotifier: React.FC = () => {
  const { userId } = useAccessToken(),
    { addToast } = useToast(),
    { data } = useSubscription<GetNotified, GetNotifiedVariables>(
      GET_NOTIFIED,
      {
        variables: { id: userId.toString() },
      }
    )

  React.useEffect(() => {
    if (!data) return
    const { message, error } = data.subscribeToUserNotifications

    addToast({ type: error ? "error" : "success", message })
  }, [data, addToast])

  return null
}
