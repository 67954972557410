import { gql } from "@apollo/client"
import {
  CreateProject,
  CreateProjectVariables,
  UpdateProject,
  UpdateProjectVariables,
  DeleteProject,
  DeleteProjectVariables,
} from "../../types"
import { msg, Noun } from "../../msg"
import { fragments } from "../../graphql-fragments"
import { mutationCommand, toMutationOptions } from "../command"
import { addProjectToCache, removeProjectFromCache } from "../modify-project"

const CREATE_PROJECT = gql`
  mutation CreateProject($name: String!, $description: String) {
    createProject(name: $name, description: $description) {
      ...ProjectPreview
    }
  }

  ${fragments.projectPreview}
`

export const createProject = mutationCommand<
  CreateProjectVariables,
  CreateProject
>({
  messages: (_, { name }) => msg.create(Noun.Project, name),
  getMutationOptions: (_, input) => ({
    mutation: CREATE_PROJECT,
    variables: input,
    update: (cache, { data }) => {
      if (data) addProjectToCache(cache, data.createProject)
    },
  }),
  sideEffect: ({ history }, _, output) => {
    history.push(`/projects/${output.createProject.id}`)
  },
})

const UPDATE_PROJECT = gql`
  mutation UpdateProject($id: ID!, $data: UpdateProjectInput!) {
    updateProject(id: $id, data: $data) {
      id
      name
      description
    }
  }
`

export const updateProject = mutationCommand<
  UpdateProjectVariables,
  UpdateProject
>({
  messages: () => msg.save(Noun.Project),
  getMutationOptions: toMutationOptions(UPDATE_PROJECT),
})

const DELETE_PROJECT = gql`
  mutation DeleteProject($id: ID!) {
    deleteProject(id: $id)
  }
`

export const deleteProject = mutationCommand<
  DeleteProjectVariables,
  DeleteProject
>({
  messages: () => msg.delete(Noun.Project),
  getMutationOptions: (_, input) => ({
    mutation: DELETE_PROJECT,
    variables: input,
    update: (cache) => removeProjectFromCache(cache, input.id),
  }),
  sideEffect: ({ history, route }, { id }) => {
    if (route.projectId === id) history.push("/projects")
  },
})
