import { cl } from "../utils"

export const AuthFooter: React.FC = () => {
  // TODO use actual Iubenda links
  return (
    <div className={cl.help}>
      By continuing you agree to the Leaf{" "}
      <Link to="https://leafqda.com#legal">
        privacy policy and terms of service
      </Link>
      . This site is protected by reCAPTCHA and the Google{" "}
      <Link to="https://policies.google.com/privacy">Privacy Policy</Link> and{" "}
      <Link to="https://policies.google.com/terms">Terms of Service</Link>{" "}
      apply.
    </div>
  )
}

const Link: React.FC<{ to: string }> = ({ to, children }) => (
  <a href={to} className={cl.a} target="_blank" rel="noreferrer">
    {children}
  </a>
)
