import { undo, redo } from "editor"
import { Undo, Redo } from "../base"
import { ProseMirrorButton } from "./utils"

export const UndoRedo: React.FC = () => {
  return (
    <>
      <ProseMirrorButton execute={undo} icon={<Undo />} label="Undo (Ctrl+Z)" />
      <ProseMirrorButton
        execute={redo}
        icon={<Redo />}
        label="Redo (Ctrl+Y)"
        className="mr3"
      />
    </>
  )
}
