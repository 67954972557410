import React from "react"
import { cl, useDocs, pickManyById, TabDataType } from "../../utils"
import { DocPassages, PassageFilterUpdaterProps } from "./DocPassages"
import { Spinner, Alert, NoDataMessage, DataLoadingError } from "../base"
import { HoverLink, HOVER_DOC } from "../HoverLink"
import { ascending } from "lib"
import clsx from "clsx"
import { useEdges } from "../EdgeProvider"

export interface DocSelectorProps extends PassageFilterUpdaterProps {
  docIds: string[]
  docId?: string
  setDocId: (docId: string) => void
}

export const DocSelector: React.FC<DocSelectorProps> = ({
  codeIds,
  docIds,
  showIntersectionsOnly,
  docId = "",
  setDocId,
}) => {
  const { docs: allDocs, ...docInfo } = useDocs(),
    { overlapEdges, refetch: refetchEdges, ...edgeInfo } = useEdges(),
    { docs, sortedIds } = React.useMemo(() => {
      const edgeFilter = showIntersectionsOnly ? "every" : "some",
        matchingDocIds = overlapEdges
          .filter((edge) =>
            codeIds[edgeFilter]((id) => edge.codeIds.includes(id))
          )
          .map((edge) => edge.docId),
        matchingDocs = pickManyById(allDocs, matchingDocIds).filter((doc) =>
          docIds.includes(doc.id)
        )

      if (!matchingDocs.length) {
        return { docs: [], sortedIds: [] }
      }
      const docs = matchingDocs.sort((a, b) => {
        return ascending(a.name.toLowerCase(), b.name.toLowerCase())
      })

      return {
        docs,
        sortedIds: docs.map((doc) => doc.id),
      }
    }, [showIntersectionsOnly, allDocs, overlapEdges, codeIds, docIds]),
    index = sortedIds.indexOf(docId),
    scrollToRef = React.useRef<HTMLDivElement | null>(null),
    linkProps = {
      docs,
      docId,
      onSelect: setDocId,
      prev: sortedIds[index - 1],
      next: sortedIds[index + 1],
      scrollToRef,
    }

  React.useEffect(() => {
    if (!sortedIds.length) return
    if (!docId || !sortedIds.includes(docId)) {
      setDocId(sortedIds[0])
    }
  }, [docId, sortedIds, setDocId])

  // changes made on a given page should reflect in the edge
  // index on every doc load
  React.useEffect(() => {
    refetchEdges()
  }, [index, refetchEdges])

  if (docInfo.error || edgeInfo.error) {
    return <DataLoadingError className="mt4" />
  } else if (!docInfo.data || !edgeInfo.data) {
    return <Spinner />
  } else if (!sortedIds.length) {
    return <NoDataMessage className="mt4" />
  } else if (sortedIds.indexOf(docId) < 0) {
    return <Spinner />
  }

  const doc = docs[index]

  return (
    <div className="mb5">
      <MobilePadding>
        <div ref={scrollToRef}>
          <Alert className="pa3" error={false}>
            {sortedIds.length ? (
              <>
                Showing coded text from <DocLink {...doc} /> (document{" "}
                <b>{index + 1}</b> of <b>{sortedIds.length}</b>)
              </>
            ) : (
              <>No matching documents were found.</>
            )}
          </Alert>
        </div>
        <NextAndPreviousLinks {...linkProps} />
        <div className="bb b--black-20 mt4" />
      </MobilePadding>

      <DocPassages
        key={docId}
        docId={docId}
        codeIds={codeIds}
        showIntersectionsOnly={showIntersectionsOnly}
      />
      <MobilePadding>
        <NextAndPreviousLinks {...linkProps} shouldScroll />
      </MobilePadding>
    </div>
  )
}

const MobilePadding: React.FC = ({ children }) => (
  <div className="ph2 ph0-ns">{children}</div>
)

const DocLink: React.FC<{ name: string; id: string }> = ({ id, name }) => (
  <HoverLink
    id={id}
    name={name}
    offsetX={10}
    className="fw6"
    dataType={TabDataType.Doc}
    query={HOVER_DOC}
  />
)

const NextAndPreviousLinks: React.FC<{
  docs: { id: string; name: string }[]
  docId: string | null
  next: string | null
  prev: string | null
  onSelect: (id: string) => void
  scrollToRef: React.RefObject<HTMLDivElement | null>
  shouldScroll?: boolean
}> = ({
  next,
  prev,
  docs,
  docId,
  onSelect,
  scrollToRef,
  shouldScroll = false,
}) => {
  const onClick = (id: string) => {
    onSelect(id)
    if (scrollToRef.current && shouldScroll) {
      scrollToRef.current.scrollIntoView()
    }
  }
  return (
    <div className="f5 mv3 flex flex-column flex-row-ns items-start items-center-ns justify-between-ns">
      <div className="mb3 mb0-ns">
        <button {...getLinkProps(prev, onClick)}>Previous Document</button>
        <span className="mh2 mid-gray">|</span>
        <button {...getLinkProps(next, onClick)}>Next Document</button>
      </div>
      <select
        value=""
        className={clsx(cl.input, "w5")}
        onChange={(e) => {
          onClick(e.currentTarget.value)
        }}
      >
        <option>Go to...</option>
        {docs.map((doc) => (
          <option key={doc.id} value={doc.id} disabled={doc.id === docId}>
            {doc.name}
          </option>
        ))}
      </select>
    </div>
  )
}

function getLinkProps(id: string | null, onClick: (id: string) => void) {
  const disabled = !id
  return {
    className: clsx(cl.a, "bn bg-transparent pa0"),
    style: disabled
      ? ({
          color: "gray",
          pointerEvents: "none",
          textDecoration: "none",
        } as React.CSSProperties)
      : {},
    ["aria-disabled"]: disabled,
    onClick(e: React.MouseEvent) {
      if (id) {
        onClick(id)
      } else {
        e.preventDefault()
        e.stopPropagation()
      }
    },
  }
}
