import { Node, schema, Transcript, TranscriptItem } from "lib"

export interface Speaker {
  label: string
}

interface TextBlock {
  time: number
  speaker: Speaker
  text: (string | number)[]
}

type Tuple = [string, string]

const SECONDS_BETWEEN_TIMESTAMPS = 30

export function transcriptToDoc(
  transcript: Transcript,
  speakers: Speaker[]
): Node {
  let currentTextBlock: TextBlock | null = null,
    prevSpeaker: Speaker | null = null,
    lastTimeStamp = 0

  const { items, speaker_labels } = transcript,
    speakerTimes = speaker_labels.segments.reduce((pairs, seg) => {
      const segPairs = seg.items.map(
        (item) => [item.start_time, item.speaker_label] as Tuple
      )
      return [...pairs, ...segPairs]
    }, [] as Tuple[]),
    speakerMap = new Map<string, string>(speakerTimes),
    textBlocks: TextBlock[] = []

  items.forEach((item, i) => {
    const speakerLabel = speakerMap.get(item.start_time) || "",
      speakerIndex = getSpeakerIndexFromLabel(speakerLabel),
      speaker = speakers[speakerIndex] || prevSpeaker, // punctuation
      time = parseFloat(item.start_time),
      text = item.alternatives[0].content,
      next = items[i + 1],
      nextIsPunctuation = next ? itemIsPunctuated(next) : false,
      newSpeaker = speaker !== prevSpeaker,
      displayText = nextIsPunctuation ? text : text + " "

    if (!currentTextBlock || newSpeaker) {
      prevSpeaker = speaker
      if (currentTextBlock) textBlocks.push(currentTextBlock)
      currentTextBlock = {
        time,
        speaker,
        text: [displayText],
      }
      lastTimeStamp = time
    } else if (currentTextBlock) {
      if (time - lastTimeStamp > SECONDS_BETWEEN_TIMESTAMPS) {
        currentTextBlock.text.push(time)
        lastTimeStamp = time
      }
      const lastIndex = currentTextBlock.text.length - 1,
        lastItem = currentTextBlock.text[lastIndex]
      if (typeof lastItem == "string") {
        currentTextBlock.text[lastIndex] = lastItem + displayText
      } else {
        currentTextBlock.text.push(displayText)
      }
    }
  })

  if (currentTextBlock && textBlocks.indexOf(currentTextBlock) < 0)
    textBlocks.push(currentTextBlock)

  return textBlocksToDoc(textBlocks)
}

function getSpeakerIndexFromLabel(speakerLabel: string) {
  return parseInt(speakerLabel.replace("spk_", ""))
}

function itemIsPunctuated(text: TranscriptItem) {
  if (text.type === "punctuation") return true
  if (text.alternatives[0].content.endsWith(",")) return true
  return false
}

function textBlocksToDoc(blocks: TextBlock[]) {
  return schema.node(
    "doc",
    undefined,
    blocks.map(({ time, speaker, text }) => {
      const label = speaker.label
      return schema.node("paragraph", undefined, [
        schema.node("timestamp", { seconds: time }),
        schema.text(` ${label.trim()}: `, [schema.marks.strong.create()]),
        ...text.reduce((nodes, text) => {
          if (typeof text === "string") {
            return nodes.concat(schema.text(text))
          } else {
            return nodes.concat(
              schema.node("timestamp", { seconds: text }),
              schema.text(" ")
            )
          }
        }, [] as Node[]),
      ])
    }, [] as Node[])
  )
}
