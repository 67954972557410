import React from "react"
import { cl, resendVerify } from "../utils"
import { P, H1PageTitle, CenteredPage, CommandButton, Footer } from "./base"

export const SignUpNextSteps: React.FC<{ email: string }> = ({ email }) => (
  <CenteredPage>
    <H1PageTitle>Check Your Email</H1PageTitle>
    <P>
      You're almost there! We've sent you a link you can use to finish setting
      up your account.
    </P>
    <Footer>
      <CommandButton
        label="Resend Email"
        command={resendVerify}
        args={{ email }}
        className={cl.btn}
      />
    </Footer>
  </CenteredPage>
)
